import { Box, Button, Card, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { CssTextField } from "../../styles/textFields";
import {
  addPotentialCustomer,
  checkClinicsAvailability,
} from "../../services/login";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};
const validationSchema = yup.object({
  name: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
    .required("Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  pincode: yup
    .string()
    .matches(/^[0-9]{6}$/, "Pincode must be 6 digits")
    .required("Pincode is required"),
});

const BasicInfo = ({
  selected,
  setSelected,
  pincode,
  setPincode,
  name,
  setName,
  email,
  setEmail,
}) => {
  const [open, setOpen] = React.useState(false);
  const [number, setNumber] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const success = () => {
    toast.success("Sucessfully Submitted", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const [bool, setBool] = React.useState();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setBool();
  }, [pincode]);

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      pincode: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (bool === true) {
        setSelected("Verification");
      } else {
        toast.error("Please check your pincode availability");
      }
    },
  });

  async function checkPincode() {
    try {
      const { data } = await checkClinicsAvailability(pincode);
      console.log("data: ", data);
      setBool(data);
      if (data === false) {
        handleOpen();
      } else {
        toast.success("Pincode is available");
      }
    } catch {
      toast.error("Failed to check pincode availability");
    }
  }

  async function potentialClientsInfo() {
    try {
      if (name && number && email) {
        const { data } = await addPotentialCustomer({
          name: name,
          mobileNumber: number,
          emailId: email,
          pincode: pincode,
        });
        console.log("data: ", data);
        if (data === "Sucessfully Added.") {
          handleClose();
          success();
        } else {
          error();
        }
      } else {
        toast.info("Enter Name and Number");
      }
    } catch {
      toast.error("Error submitting the details");
    }
  }

  return (
    <>
      <Card sx={{ minHeight: "300px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Enter Name
              </Typography>
              <CssTextField
                fullWidth
                size="small"
                placeholder="Enter Name"
                variant="outlined"
                type="text"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setFieldValue("name", e.target.value);
                }}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Enter Email
              </Typography>
              <CssTextField
                fullWidth
                size="small"
                placeholder="Enter Email"
                variant="outlined"
                type="text"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setFieldValue("email", e.target.value);
                }}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Enter Pincode
              </Typography>
              <CssTextField
                size="small"
                fullWidth
                placeholder="Enter Pincode"
                variant="outlined"
                type="number"
                name="pincode"
                onChange={(e) => {
                  setPincode(e.target.value);
                  setFieldValue("pincode", e.target.value);
                }}
                value={pincode}
                onBlur={handleBlur}
                error={touched.pincode && Boolean(errors.pincode)}
                helperText={touched.pincode && errors.pincode}
              />
              <div style={{ color: bool === true ? "green" : "red" }}>
                {bool === true && "Available"}
                {bool === false && "Not Available"}
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Button
                variant="contained"
                sx={{
                  width: "15em",
                  backgroundColor: "#2d256c!important",
                  fontSize: "12px",
                  padding: "8px 15px",
                  marginLeft: "10px",
                  marginTop: "35px",
                }}
                onClick={() => {
                  pincode && checkPincode();
                }}
              >
                Check
              </Button>
            </Grid>
          </Grid>

          <br />
          <br />
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "15em",
              backgroundColor: "#2d256c!important",
              fontSize: "12px",
              padding: "8px 15px",
              // marginLeft: "10px",
            }}
          >
            Next
          </Button>
        </form>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            We're not available in this area yet, but we'll let you know once
            this pin code joins the Tron network.
          </Typography>
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Name
          </Typography>
          <CssTextField
            fullWidth
            size="small"
            placeholder="Enter Name"
            variant="outlined"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Mobile Number
          </Typography>
          <CssTextField
            fullWidth
            size="small"
            placeholder="Enter Number"
            variant="outlined"
            type="number"
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />

          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#2d256c!important",
              fontSize: "12px",
              padding: "8px 15px",
              // marginLeft: "10px",
              mt: "20px",
            }}
            fullWidth
            onClick={potentialClientsInfo}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default BasicInfo;
