import {
  Box,
  Card,
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { billingAndSettlementStatusForClinic } from "../../services/PlansAndProcedure";
import moment from "moment";
import { Permissions } from "../Context/PermissionsContext";
import Loader from "../Loader";

const SettlementStatus = () => {
  const [data, setData] = useState();
  const { loading, setLoading } = useContext(Permissions);
  const { id } = useParams();
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const { data } = await billingAndSettlementStatusForClinic(id);
      if (data) {
        setData(data);
      }
      setLoading(false);
    };
    getData();
  }, []);

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  const formatRupeesWithCommas = (number) => {
    if (typeof number === "undefined" || number === null) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const steps = [
    {
      label: "Submitted",
    },
    {
      label: "Approved",
    },
    {
      label: "Settled",
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <>
      <Typography
        variant="h5"
        sx={{ alignItems: "center", mb: "10px", fontSize: "17px" }}
      >
        Settlement Status
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Card
              sx={{
                padding: 3,
                boxShadow: "none",
                border: "0.5px solid #D9D9D9",
              }}
            >
              <div
                style={{
                  border: "2px solid #ECEBEC",
                  borderRadius: "12px 12px 0 0",
                }}
              >
                <div
                  style={{
                    padding: "8px",
                    backgroundColor: "#2D256C",
                    borderRadius: "12px 12px 0 0",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "#fff", fontWeight: "bold", fontSize: "14px" }}
                  >
                    {id}
                  </Typography>
                </div>
                <Grid container spacing={2} sx={{ padding: "8px" }}>
                  <Grid item xs={4}>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "#D83F87",
                        fontWeight: "bold",
                        fontSize: "10px",
                      }}
                    >
                      Customer Name
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        alignItems: "center",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {data?.customerName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "#D83F87",
                        fontWeight: "bold",
                        fontSize: "10px",
                      }}
                    >
                      Appointment Date
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        alignItems: "center",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {formattedDate(data?.appontmentDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "#D83F87",
                        fontWeight: "bold",
                        fontSize: "10px",
                      }}
                    >
                      Mode Of Payment
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        alignItems: "center",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {data?.paymentMode}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <div style={{ padding: "20px" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {" "}
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        Consultation Charges
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        {formatRupeesWithCommas(data?.consultationCharges)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {" "}
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        Treatment Charges
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        {formatRupeesWithCommas(
                          data?.appointmentTreatmentCharges
                            .map((item) => parseInt(item.charges))
                            .reduce((acc, curr) => acc + curr)
                        )}
                      </Typography>
                    </Grid>
                    {data?.appointmentTreatmentCharges.map((item, i) => (
                      <React.Fragment key={i}>
                        <Grid pl={4} item xs={6}>
                          {" "}
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: "bold", color: "#444444" }}
                          >
                            {i + 1}. {item?.nameOfTreatment}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: "bold", color: "#444444" }}
                          >
                            {formatRupeesWithCommas(item?.charges)}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}

                    <Grid item xs={6}>
                      {" "}
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        Plan Discount
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        {formatRupeesWithCommas(data?.planDiscount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {" "}
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        Coupon Discount
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        {formatRupeesWithCommas(data?.couponDiscount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      {" "}
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: "bold",
                          color: "#D83F87",
                          fontSize: "14px",
                        }}
                      >
                        Total Charges
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: "bold",
                          color: "#D83F87",
                          fontSize: "14px",
                        }}
                      >
                        {formatRupeesWithCommas(data?.couponDiscount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      {" "}
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: "bold",
                          color: "#D83F87",
                          fontSize: "14px",
                        }}
                      >
                        Amount Paid By Customer After Discount
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: "bold",
                          color: "#D83F87",
                          fontSize: "14px",
                        }}
                      >
                        {formatRupeesWithCommas(
                          data?.totalPayableAfterDiscount
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      {" "}
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: "bold",
                          color: "#D83F87",
                          fontSize: "14px",
                        }}
                      >
                        Total Amount To Be Received By Doctor
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: "bold",
                          color: "#D83F87",
                          fontSize: "14px",
                        }}
                      >
                        {formatRupeesWithCommas(data?.amountReceivedByDoctor)}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              sx={{
                padding: 3,
                boxShadow: "none",
                border: "0.5px solid #D9D9D9",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Settlement Status
              </Typography>
              <Divider />

              <Box sx={{ maxWidth: 400, fontSize: "14px" }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SettlementStatus;
