import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import "../../styles/AppointmentRequests.scss";
import {
  PostAcceptOrRejectAppointment,
  getAppointmentRequestData,
} from "../../services/clinicOnboarding";
import { toast } from "react-toastify";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import moment from "moment";
import Loader from "../Loader";

const AppointmentRequests = () => {
  const [appointmentReqData, setAppointmentReqData] = useState([]);
  const token = JSON.parse(localStorage.getItem("token"));
  const [acceptReject, setAcceptReject] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const uniqueId = token.uniqueId;
  const [open, setOpen] = useState(false);
  const [rejectopen, setRejectOpen] = useState(false);
  const [itemId, setItemid] = useState("");
  const { permissionsData, loading, setLoading } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let AcceptAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Accept Appointment"
  )?.permission;
  let CancelAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Cancel Appointment"
  )?.permission;

  const success = () => {
    toast.success("Sucessfully Accepted the Appointment", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.success("Sucessfully Cancelled the Appointment", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleClickOpen = (id) => {
    console.log("item id", id);
    setOpen(true);
    setItemid(id);
  };

  const handleRejectdialogOpen = (id) => {
    console.log("rejectid", id);
    setRejectOpen(true);
    setItemid(id);
  };

  const handleClose = () => {
    setOpen(false);
    setAcceptReject(false);
  };

  const handleRejectDialogClose = () => {
    setRejectOpen(false);
    setAcceptReject(false);
  };

  const getAppointmentData = async () => {
    setLoading(true);
    try {
      const response = await getAppointmentRequestData(uniqueId);
      console.log(response.data);
      const data = response.data;
      let sortedData = data.sort((a, b) => {
        // Sort by date (descending)
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
        // Sort by time (ascending)
        const timeA = a.time.split(" - ")[0];
        const timeB = b.time.split(" - ")[0];
        return (
          new Date(`1970/01/01 ${timeB}`) - new Date(`1970/01/01 ${timeA}`)
        );
      });
      let newdata = sortedData.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });
      console.log("newdata response with time..", newdata);
      if (data) {
        setAppointmentReqData(data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleAccept = async (id) => {
    const Acceptdata = {
      id: id,
      response: "Accepted",
      updatedBy: token.username,
    };
    // console.log("accept data.....",Acceptdata)
    try {
      setIsAccepted(true);
      let res = await PostAcceptOrRejectAppointment(Acceptdata);
      if (res) {
        setAcceptReject(true);
        console.log("Accepted data post success..");
        success();
        setIsAccepted(false);
      }
    } catch (error) {
      console.log(error.message);
      setIsAccepted(false);
    }
  };

  const handleReject = async (id) => {
    const Rejectdata = {
      id: id,
      response: "Rejected",
      updatedBy: token.username,
    };
    // console.log("Rejected data.........",Rejectdata)
    try {
      await PostAcceptOrRejectAppointment(Rejectdata);
      setAcceptReject(true);
      console.log("Rejected data post success..");
      error();
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    getAppointmentData();
  }, [acceptReject]);

  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  return (
    <div>
      <Box id="appointReq">
        <Typography
          variant="h5"
          sx={{ marginBottom: "10px", fontSize: "17px" }}
        >
          Appointment Requests
        </Typography>
        <Card className="thecardbox" variant="outlined">
          {appointmentReqData &&
            appointmentReqData.map((el, id) => {
              return (
                <Box className="maintablebox">
                  <Box className="tablecontentbox1">
                    <Typography variant="caption" className="idhead">
                      Appointment ID
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        color: "#2D2828",
                        margin: "0px",
                        fontSize: "12px",
                      }}
                    >
                      {el.appointmentId}
                    </Typography>
                    {/* <Typography variant="h6" className="request">
                      {el.request}
                    </Typography> */}
                  </Box>
                  {/* <Box className="divider"></Box> */}
                  <Divider orientation="vertical" flexItem />
                  <Box className="tablecontentbox2">
                    <Box className="subbox1">
                      <Typography variant="h6" className="name">
                        {el.patientName}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PhoneIcon style={{ fontSize: "12px" }} />
                        <Typography variant="caption" sx={{ fontSize: "12px" }}>
                          {el.patientNumber == null ? (
                            <>No number</>
                          ) : (
                            el.patientNumber
                          )}
                        </Typography>
                      </Box>
                      <Box className="specialitybox">
                        <Typography
                          sx={{
                            fontSize: "10px",
                            backgroundColor: "#FFEFF9",
                            padding: "5px",
                            color: "gray",
                            width: "100%",
                          }}
                        >
                          {el.specialty}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="subbox2">
                      <Box className="datebox">
                        <Typography>Appointment Date</Typography>
                        <Typography variant="h6">
                          {formattedDate(el.date)}
                        </Typography>
                      </Box>
                      <Box className="timebox">
                        <Typography>Appointment Time</Typography>
                        <Typography variant="h6">{el.time}</Typography>
                      </Box>
                    </Box>
                    <Box className="subbox3">
                      <Typography>Remarks</Typography>
                      <Typography variant="h6">{el.remark}</Typography>
                    </Box>
                  </Box>
                  {/* <Box className="divider"></Box> */}
                  <Divider orientation="vertical" flexItem />
                  <Box className="tablecontentbox3">
                    <Box>
                      {AcceptAppointmentPermission && (
                        <Button
                          sx={{
                            backgroundColor: "#00984F",
                            textAlign: "left",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            borderRadius: "3px",
                            padding: "8px 15px",
                            display: "flex",
                            justifyContent: "space-around",
                            "&:hover": { backgroundColor: "#00984F!important" },
                          }}
                          size="small"
                          onClick={() => {
                            handleClickOpen(el.id);
                            console.log("accept", el.id);
                          }}
                        >
                          <CalendarMonthIcon sx={{ fontSize: "0.9rem" }} />{" "}
                          Accept
                        </Button>
                      )}
                      <br />
                      {CancelAppointmentPermission && (
                        <Button
                          sx={{
                            backgroundColor: "#3E2732",
                            textAlign: "left",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            padding: "8px 15px",
                            display: "flex",
                            borderRadius: "3px",
                            justifyContent: "space-around",
                            "&:hover": { backgroundColor: "#3E2732!important" },
                          }}
                          size="small"
                          onClick={() => {
                            handleRejectdialogOpen(el.id);
                            console.log("reject id........ ", el.id);
                          }}
                        >
                          {" "}
                          <CancelPresentationIcon sx={{ fontSize: "0.9rem" }} />
                          Cancel
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Card>
      </Box>
      {/* ----------------------Dialog box for Accept--------------- */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to Accept ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={isAccepted}
            sx={{
              backgroundColor: isAccepted ? "#898989" : "#D83F87",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#D83F87!important" },
            }}
            onClick={() => {
              console.log("elemtnt", itemId);
              handleAccept(itemId);
              handleClose();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            onClick={handleClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* -------------------------Dialog box for Reject---------------------- */}
      <Dialog
        open={rejectopen}
        onClose={handleRejectDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to Reject ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              handleReject(itemId);
              handleRejectDialogClose();
            }}
            sx={{
              backgroundColor: "#D83F87",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#D83F87!important" },
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            onClick={handleRejectDialogClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* ----------------------------------Table for mobile screen--------------------------- */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
        }}
        className="mobilescreencard"
      >
        {appointmentReqData.map((el, id) => {
          return (
            <>
              <Card
                sx={{
                  padding: "0px 0%!important",
                  marginBottom: "10px",
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px",
                }}
                className="mobilecontentcard"
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography className="appointmentid" variant="h6">
                      {el.appointmentId}
                    </Typography>
                    <Typography className="dateheading" variant="caption">
                      Date
                    </Typography>
                    <Typography className="date" variant="subtitle1">
                      {formattedDate(el.date)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="speciality">
                      {el.specialty}
                    </Typography>
                    <Typography variant="caption" className="timeheading">
                      Time
                    </Typography>
                    <Typography variant="subtitle1" className="time">
                      {el.time}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{ backgroundColor: "#F5F5F5!important" }}
                    item
                    xs={4}
                  >
                    {AcceptAppointmentPermission && (
                      <Button
                        sx={{
                          backgroundColor: "#00984F",
                          textAlign: "left",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "10px",
                          display: "flex",
                          borderRadius: "3px",
                          justifyContent: "space-around",
                          "&:hover": { backgroundColor: "#00984F!important" },
                        }}
                        className="acceptbtn"
                        size="small"
                        onClick={() => {
                          handleClickOpen(el.id);
                          console.log("accept", el.id);
                        }}
                      >
                        <CalendarMonthIcon sx={{ fontSize: "0.9rem" }} /> Accept
                      </Button>
                    )}

                    {CancelAppointmentPermission && (
                      <Button
                        sx={{
                          backgroundColor: "#3E2732",
                          textAlign: "left",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "10px",
                          display: "flex",
                          borderRadius: "3px",
                          justifyContent: "space-around",
                          "&:hover": { backgroundColor: "#3E2732!important" },
                        }}
                        className="cancelbtn"
                        size="small"
                        onClick={() => {
                          handleRejectdialogOpen(el.id);
                          console.log("reject id........ ", el.id);
                        }}
                      >
                        {" "}
                        <CancelPresentationIcon sx={{ fontSize: "0.9rem" }} />
                        Cancel
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </>
          );
        })}
      </Card>

      {/* --------------------------------Clinic Footer------------------------ */}
      <Box className="clinicfooter" sx={{ textAlign: "right" }}>
        <Typography>Want to reschedule an appointment,</Typography>
        <Box>
          <Typography>
            connect with us at
            <a href={`tel:8040091997`}> +91 8040091997</a> |
            <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
            <a href={`mailto:support@tron.health`}> support@tron.health.</a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default AppointmentRequests;
