import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import dayjs from "dayjs";
import { UpcomingCustomerLoginHealthiansOrder } from "../../../services/HealthiansApi";
import { useNavigate } from "react-router-dom";

const UpcomingTab = () => {
  const [upcomingTests, setUpcomingTests] = useState([]);
  const token = JSON.parse(localStorage.getItem("token"));
  const userNumber = token?.username;
  const navigate = useNavigate("");

  const getUpcomingOrders = async () => {
    try {
      const res = await UpcomingCustomerLoginHealthiansOrder(userNumber);
      console.log("upcomingdata", res.data);
      setUpcomingTests(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getUpcomingOrders();
  }, []);
  return (
    <div className="testUpcoming">
      {upcomingTests.map((el, id) => {
        return (
          <Card className="list" key={el.id}>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item sm={3} xs={3} md={2} className="firstGridItem">
                <Typography variant="caption" className="idhead">
                  Customer Name
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D2828",
                    margin: "0px",
                    fontSize: "12px",
                  }}
                >
                  {el.billing_cust_name}
                </Typography>
                <Box className="emailBox">
                  <Typography variant="caption" className="idhead">
                    Email
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#2D2828",
                      margin: "0px",
                      fontSize: "12px",
                    }}
                  >
                    {el.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={6} xs={6} md={8} className="secondGridItem">
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item sm={6} xs={6} md={6} className="subItem1">
                    <Typography
                      className="idhead"
                      sx={{
                        fontSize: "10px",
                        color: "#d83f87",
                        fontWeight: "bold",
                      }}
                    >
                      Mobile No
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PhoneIcon style={{ fontSize: "12px" }} />{" "}
                      <Typography
                        variant="caption"
                        sx={{ fontSize: "12px", color: "rgba(68, 68, 68, 1)" }}
                      >
                        {el.mobile == null ? <>No number</> : el.mobile}
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      className="locationBox"
                    >
                      <LocationOnIcon style={{ fontSize: "12px" }} />
                      <Typography
                        variant="caption"
                        sx={{ fontSize: "12px", color: "rgba(68, 68, 68, 1)" }}
                      >
                        {el.address}, {el.landmark}, {el.zipcode}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={6} md={6} className="subItem2">
                    <Box className="datebox">
                      <Typography>Collection Date</Typography>
                      <Typography variant="h6">
                        {dayjs(el.test_date).format("DD-MM-YYYY")}
                      </Typography>
                    </Box>
                    <Box className="bookingId">
                      <Typography>Booking id</Typography>
                      <Typography variant="h6">
                        {el.vendor_booking_id}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={3} xs={3} md={2} className="thridGridItem">
                <Box className="btnsBox">
                  <Button
                    sx={{
                      backgroundColor: "#d83f87",
                      textAlign: "left",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "12px",
                      borderRadius: "3px",
                      // padding: "5px 10px",
                      "&:hover": { backgroundColor: "#d83f87!important" },
                    }}
                    size="small"
                    onClick={() =>
                      navigate(`/user/RescheduleOrder/${el.vendor_booking_id}`)
                    }
                  >
                    <CalendarMonthIcon sx={{ fontSize: "0.9rem" }} /> Reschedule
                  </Button>

                  {/* <Button
                    sx={{
                      backgroundColor: "#3E2732",
                      textAlign: "left",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "12px",
                      // padding: "5px 10px",
                      display: "flex",
                      borderRadius: "3px",
                      "&:hover": { backgroundColor: "#3E2732!important" },
                    }}
                    size="small"
                  >
                    {" "}
                    <CancelPresentationIcon sx={{ fontSize: "0.9rem" }} />
                    Cancel
                  </Button> */}
                </Box>
              </Grid>
            </Grid>
          </Card>
        );
      })}
    </div>
  );
};

export default UpcomingTab;
