import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from "react-router-dom";
import {
  createHealthiansOrderBookingWithAuthkey,
  getHealthiansOrderBookingByMerchantTransactionId,
  RetryPaymentForHealthiansOrder,
  setTriggerStatusCompleted,
} from "../../../services/HealthiansApi";
import { Permissions } from "../../Context/PermissionsContext";
import { toast } from "react-toastify";

const TestOrderStatus = () => {
  const [orderStatus, setOrderStatus] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState({});
  const [bookingId, setBookingId] = useState(false);
  const { id } = useParams();
  const { healthiansToken } = useContext(Permissions);
  const navigate = useNavigate("");
  console.log("Healthians Token:", healthiansToken);

  const getHealthianBookingOrder = async () => {
    try {
      const res = await getHealthiansOrderBookingByMerchantTransactionId(id);
      if (!res || !res.data) {
        console.error("Failed to fetch healthians order data.");
        return;
      }
      console.log("healthianorder", res.data);
      setOrderStatus(res.data);
      const parsedPaymentStatus = JSON.parse(res.data?.paymentStatus);
      setPaymentStatus(parsedPaymentStatus);
      const { trigger_status, paymentStatus } = res.data;

      if (
        (trigger_status === "PENDING" || trigger_status === "Pending") &&
        parsedPaymentStatus.success
      ) {
        const { paymentStatus, date, trigger_status, ...modifiedData } =
          res.data; // Remove paymentStatus
        if (!healthiansToken) {
          console.error(
            "Missing healthiansToken. Cannot proceed with API call."
          );
          return;
        }
        modifiedData.authKey = healthiansToken;
        // modifiedData.altmobile = "";
        // modifiedData.altemail = "";
        console.log("modifieddata", modifiedData);
        try {
          const createOrder = await createHealthiansOrderBookingWithAuthkey(
            modifiedData
          );
          const bookingId = createOrder.data.booking_id;
          console.log("bookingId", bookingId);

          if (
            createOrder &&
            (trigger_status === "PENDING" || trigger_status === "Pending") &&
            bookingId
          ) {
            try {
              await setTriggerStatusCompleted(id, bookingId);
              console.log("Trigger status updated to completed.");
              setBookingId(true);
            } catch (error) {
              console.error("Error updating trigger status:", error.message);
            }
          }
        } catch (error) {
          console.error("Error creating order:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching healthians order:", error.message);
    }
  };

  const retryPayment = async () => {
    try {
      const res = await RetryPaymentForHealthiansOrder(id);
      if (res?.data?.success && res?.data?.code === "PAYMENT_INITIATED") {
        toast.success("Order Created Successfully");
        const redirectUrl = res.data.data.instrumentResponse?.redirectInfo?.url;

        if (redirectUrl) {
          window.open(redirectUrl, "_self");
          return;
        } else {
          toast.error("Payment URL not found");
        }
      } else {
        toast.error(res?.data?.message || "Payment initiation failed");
      }
    } catch (error) {
      console.error(error.message);
      toast.error("An error occurred while creating the order");
    }
  };

  useEffect(() => {
    if (healthiansToken) {
      getHealthianBookingOrder();
    }
  }, [healthiansToken, bookingId]);
  return (
    <div>
      <Typography variant="h5">Test Order Status</Typography>
      <Card>
        {paymentStatus.success ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: 600,
            }}
          >
            <VerifiedIcon sx={{ color: "green", fontSize: "40px" }} />
            Payment Confirmed
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CancelIcon sx={{ color: "red", fontSize: "40px" }} />
            Payment Cancelled
          </div>
        )}
        <div>
          <Typography
            sx={{
              fontSize: "12px",
              color: paymentStatus.success
                ? orderStatus.booking_id
                  ? "green"
                  : "orange"
                : "red",
            }}
          >
            {paymentStatus.success
              ? orderStatus.booking_id
                ? `Booking ID Generated: ${orderStatus.booking_id}`
                : "Booking ID not generated. Our team will contact you shortly."
              : "Booking ID not generated."}
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={8}>
            <Card sx={{ mt: "10px" }}>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={6} md={6}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#2d256c",
                    }}
                  >
                    Customer Name
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={6} md={6}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "14px",
                      color: "#898989",
                    }}
                  >
                    {orderStatus?.billing_cust_name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item sm={6} xs={6} md={6}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#2d256c",
                    }}
                  >
                    Mobile Number
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={6} md={6}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "14px",
                      color: "#898989",
                    }}
                  >
                    {orderStatus?.mobile}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item sm={6} xs={6} md={6}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#2d256c",
                    }}
                  >
                    Email
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={6} md={6}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "14px",
                      color: "#898989",
                    }}
                  >
                    {orderStatus?.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={6} md={6}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#2d256c",
                    }}
                  >
                    Address
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={6} md={6}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "14px",
                      color: "#898989",
                    }}
                  >
                    {orderStatus?.address}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12} md={12}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#3E2732",
                    }}
                  >
                    Selected members for test
                  </Typography>

                  {orderStatus?.customer?.map((el, id) => {
                    return (
                      <Grid container key={el.customer_id} spacing={1}>
                        <Grid item sm={6} xs={6} md={6}>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "600",
                              fontSize: "14px",
                              color: "#2d256c",
                            }}
                          >
                            Customer Name
                          </Typography>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "600",
                              fontSize: "14px",
                              color: "#2d256c",
                            }}
                          >
                            Age
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={6} md={6}>
                          <Typography
                            variant="h3"
                            sx={{
                              fontSize: "14px",
                              color: "#898989",
                            }}
                          >
                            {el.customer_name}
                          </Typography>
                          <Typography
                            variant="h3"
                            sx={{
                              fontSize: "14px",
                              color: "#898989",
                            }}
                          >
                            {el.age}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              {paymentStatus.success && (
                <Box
                  sx={{ display: "flex", justifyContent: "center", mt: "10px" }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#2d256c",
                      textAlign: "left",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "12px",
                      padding: "8px 15px",
                      borderRadius: "3px",
                      "&:hover": { backgroundColor: "#2d256c!important" },
                    }}
                    size="small"
                    onClick={() => navigate("/user/testOrderHistory/upcoming")}
                  >
                    View All Test Bookings
                  </Button>
                </Box>
              )}
            </Card>
          </Grid>
          <Grid item sm={12} xs={12} md={4}>
            <Card sx={{ mt: "10px" }}>
              <Typography sx={{ fontWeight: 600, color: "#2d256c" }}>
                Payment Details
              </Typography>
              <Box sx={{ mt: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#2d256c",
                      }}
                    >
                      Payment Mode
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6} md={6}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "14px",
                        color: "#898989",
                      }}
                    >
                      {paymentStatus?.data?.paymentInstrument?.type || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#2d256c",
                      }}
                    >
                      Payment Status
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6} md={6}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "14px",
                        color: "#898989",
                      }}
                    >
                      {paymentStatus?.data?.responseCode || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: "10px" }} />
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#d83f87",
                      }}
                    >
                      Total Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#4f4f4f",
                      }}
                    >
                      ₹ {orderStatus?.discounted_price}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
            {!paymentStatus.success && (
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "10px" }}
              >
                <Button
                  sx={{
                    backgroundColor: "#d83f87",
                    textAlign: "left",
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    padding: "8px 15px",
                    borderRadius: "3px",
                    "&:hover": { backgroundColor: "#d83f87!important" },
                  }}
                  size="small"
                  onClick={() => retryPayment()}
                  fullWidth
                >
                  Retry
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Card>
      {/* ---------------------------customer footer-------------------- */}

      <Box className="customerfooter" sx={{ textAlign: "right" }}>
        <Typography>Finding issue on your appointment,</Typography>
        <Box>
          <Typography>
            connect with us at
            <a href={`tel:8040091997`}> +91 8040091997</a> |
            <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
            <a href={`mailto:support@tron.health`}> support@tron.health.</a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default TestOrderStatus;
