import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/UpcomingTableCustomer.scss";
import {
  getPastAppointmentDetails,
  getPreviousAppointments,
} from "../../services/clinicOnboarding";
import moment from "moment";
import { Permissions } from "../Context/PermissionsContext";
import Loader from "../Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const CustomerPastDetails = () => {
  const [appointmentStatus, setAppointmentStatus] = useState(false);
  const [pastAppointmentInfo, setPastAppointmentInfo] = useState({});
  const [previousAppointments, setPreviousAppointments] = useState({});
  const [prevIndividualData, setPrevIndividualData] = useState({});
  const clinicUniqueId = pastAppointmentInfo?.bookAppointment?.clinicUniqueId;
  const [open, setOpen] = useState(false);
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const { loading, setLoading } = useContext(Permissions);
  const paymentstatus =
    pastAppointmentInfo?.appointmentBillingRequest?.paymentStatus;
  const paymentData = paymentstatus ? JSON.parse(paymentstatus) : "";
  const referenceId = paymentData
    ? paymentData?.data?.merchantTransactionId
    : null;
  const paymentInstrument = paymentData
    ? paymentData?.data?.paymentInstrument?.type
    : null;
  // console.log("paymentdata",paymentstatus);
  // console.log(paymentInstrument);
  // console.log("clinicid", clinicUniqueId);

  function splitComplaintsArrays(complaints) {
    const length = complaints?.length;
    const midIndex = Math.ceil(length / 2);
    const leftSideComplaints = complaints?.slice(0, midIndex);
    const rightSideComplaints = complaints?.slice(midIndex);
    return [leftSideComplaints, rightSideComplaints];
  }

  const [leftSideComplaints, rightSideComplaints] = splitComplaintsArrays(
    pastAppointmentInfo?.appointmentBillingRequest?.complaints
  );
  //   console.log("leftsidecomplaints", leftSideComplaints);
  //   console.log("rightsidecomplaints", rightSideComplaints);

  function splitProcedureArrays(procedures) {
    const length = procedures?.length;
    const midIndex = Math.ceil(length / 2);
    const leftSideProcedures = procedures?.slice(0, midIndex);
    const rightSideProcedures = procedures?.slice(midIndex);
    return [leftSideProcedures, rightSideProcedures];
  }

  const [leftSideProcedures, rightSideProcedures] = splitProcedureArrays(
    pastAppointmentInfo?.appointmentBillingRequest?.appointmentTreatmentCharges.filter(
      (el) => el.nameOfTreatment
    )
  );

  // console.log("leftsideprocedures", leftSideProcedures);
  // console.log("rightsideprocedures", rightSideProcedures);

  const handleOpen = (el) => {
    console.log("previous", el);
    setOpen(true);
    setPrevIndividualData(el);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function modifiedTime(timing) {
    if (!timing) {
      return "";
    }
    const parts = timing.split(" - ");
    const startTime = parts[0];
    const endTime = parts[1];

    // Converting start time to 12-hour format
    const start = new Date(`2000-01-01T${startTime}`);
    const startHours = start.getHours();
    const startMinutes = start.getMinutes();
    const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
      .toString()
      .padStart(2, "0")}`;

    // Converting end time to 12-hour format
    const end = new Date(`2000-01-01T${endTime}`);
    const endHours = end.getHours();
    const endMinutes = end.getMinutes();
    const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
      .toString()
      .padStart(2, "0")}`;

    // Determine AM/PM
    const amPm = startHours < 12 ? "AM" : "PM";

    return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
  }

  const getPreviousAppointmentsData = async () => {
    try {
      const res = await getPreviousAppointments(clinicUniqueId, appointmentId);
      if (res && res.data) {
        setPreviousAppointments(res.data);
        setAppointmentStatus(true);
      } else {
        setAppointmentStatus(false);
      }
      // console.log("prev appointments", res.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  const formatRupeesWithCommas = (number) => {
    if (typeof number === "undefined" || number === null) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const getAppointmentDetails = async () => {
      try {
        setLoading(true);
        let res = await getPastAppointmentDetails(appointmentId);
        let data = res.data;
        // console.log("appointment data", data);
        setPastAppointmentInfo(data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    if (appointmentId) {
      getAppointmentDetails();
    }
    if (clinicUniqueId) {
      getPreviousAppointmentsData();
    }
  }, [clinicUniqueId, appointmentId]);

  return (
    <div>
      <Box>
        <Typography sx={{ mb: "10px" }} className="apoointinfo" variant="h5">
          Past Appointment Details
        </Typography>
        {loading ? (
          <Loader />
        ) : (
          <Card className="customerpastappointmentmaincard">
            <Grid container spacing={2}>
              <Grid item md={8} xs={12} sm={12}>
                <Box>
                  <Card className="appointmentcard">
                    <>
                      <Box>
                        <Box className="headbox">
                          <Typography variant="h5">Appointment ID</Typography>
                          <Typography variant="h4">{appointmentId}</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Stack direction="row" className="datetime">
                          <Box className="date">
                            <Typography variant="h5">
                              Appointment Date
                            </Typography>
                            <Typography variant="h4">
                              {formattedDate(
                                pastAppointmentInfo?.bookAppointment?.date
                              )}
                            </Typography>
                          </Box>
                          <Box className="time">
                            <Typography variant="h5">
                              Appointment Time
                            </Typography>
                            <Typography variant="h4">
                              {modifiedTime(
                                pastAppointmentInfo?.bookAppointment?.time
                              )}
                            </Typography>
                          </Box>
                        </Stack>
                        <Box className="specialitybox">
                          <Typography
                            sx={{
                              fontSize: "10px",
                              padding: "5px 10px",
                              color: "gray",
                              textAlign: "center",
                            }}
                          >
                            {pastAppointmentInfo?.bookAppointment?.specialty}
                          </Typography>
                        </Box>
                        <Box className="remarksbox">
                          <Typography variant="h5">Remarks</Typography>
                          <Typography>
                            {pastAppointmentInfo?.bookAppointment?.remark}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  </Card>
                </Box>
                <Card className="complaintstreatmentscard">
                  <Typography variant="h5" sx={{ fontSize: "14px" }}>
                    Complaints
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      {leftSideComplaints?.map((el, id) => {
                        return (
                          <>
                            <Typography
                              className="procedures1"
                              alignItems="center"
                            >
                              <FiberManualRecordIcon
                                sx={{ color: "#D83F87" }}
                                fontSize="10px"
                              />
                              {el}
                            </Typography>
                          </>
                        );
                      })}
                    </Grid>
                    <Grid item md={6}>
                      {rightSideComplaints?.map((el, id) => {
                        return (
                          <>
                            <Typography
                              className="procedures1"
                              alignItems="center"
                            >
                              <FiberManualRecordIcon
                                sx={{ color: "#D83F87" }}
                                fontSize="10px"
                              />
                              {el}
                            </Typography>
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>

                  <Typography
                    variant="h5"
                    sx={{ fontSize: "14px", mt: "10px" }}
                  >
                    Treatments
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      {leftSideProcedures?.map((el, id) => {
                        return (
                          <>
                            <Typography
                              className="procedures1"
                              alignItems="center"
                            >
                              <FiberManualRecordIcon
                                sx={{ color: "#D83F87" }}
                                fontSize="10px"
                              />
                              {el.nameOfTreatment}
                            </Typography>
                          </>
                        );
                      })}
                    </Grid>
                    <Grid item md={6}>
                      {rightSideProcedures?.map((el, id) => {
                        return (
                          <>
                            <Typography
                              className="procedures1"
                              alignItems="center"
                            >
                              <FiberManualRecordIcon
                                sx={{ color: "#D83F87" }}
                                fontSize="10px"
                              />
                              {el.nameOfTreatment}
                            </Typography>
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Card>

                <Card className="paymentcard">
                  <Box className="paymentdetailsdesc">
                    <Typography variant="h5">Payment Details</Typography>
                  </Box>
                  <Box className="paymentdetailsbox">
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={6} sm={6}>
                          <Typography
                            variant="body2"
                            sx={{ color: "#4f4f4f", fontWeight: "bold" }}
                          >
                            Reference ID
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                          <Typography className="value" variant="body2">
                            {referenceId}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={6} sm={6}>
                          <Typography
                            variant="body2"
                            sx={{ color: "#4f4f4f", fontWeight: "bold" }}
                          >
                            Date of Payment Mode
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                          <Typography className="value" variant="body2">
                            {formattedDate(
                              pastAppointmentInfo?.appointmentBillingRequest?.merchantTransactionDatetime?.split(
                                " "
                              )[0]
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={6} sm={6}>
                          <Typography
                            variant="body2"
                            sx={{ color: "#4f4f4f", fontWeight: "bold" }}
                          >
                            Payment Mode
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                          <Typography className="value" variant="body2">
                            {paymentInstrument}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* <Grid sx={{}} container spacing={1}>
                      <Grid item md={6} xs={6} sm={6}>
                        <Typography
                          variant="body2"
                          sx={{ color: "#4f4f4f", fontWeight: "bold" }}
                        >
                          Reference ID
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#4f4f4f", fontWeight: "bold" }}
                        >
                          Date of Payment Mode
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#4f4f4f", fontWeight: "bold" }}
                        >
                          Payment Mode
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={6} sm={6}>
                        <Typography className="value" variant="body2">
                          {referenceId}
                        </Typography>
                        <Typography className="value" variant="body2">
                          12 January, 2023
                        </Typography>
                        <Typography className="value" variant="body2">
                          {paymentInstrument}
                        </Typography>
                      </Grid>
                    </Grid> */}
                    </Box>
                    <Divider sx={{ m: "10px 0px" }} />
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography
                          id="sideheading"
                          variant="body2"
                          fontWeight="bold"
                        >
                          Consultation Charges
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          id="amount"
                          variant="body2"
                          fontWeight="bold"
                        >
                          {formatRupeesWithCommas(
                            pastAppointmentInfo?.appointmentBillingRequest
                              ?.consultationCharges
                          ) + " ₹"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" spacing={1}>
                      <Grid item>
                        <Typography
                          id="sideheading"
                          variant="body2"
                          fontWeight="bold"
                        >
                          Treatment Charges
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          id="amount"
                          variant="body2"
                          fontWeight="bold"
                        >
                          {formatRupeesWithCommas(
                            pastAppointmentInfo?.appointmentBillingRequest?.appointmentTreatmentCharges
                              .filter((el) => el.nameOfTreatment)
                              .filter((item) => item.charges > 0)
                              .map((item) => item.charges)
                              .reduce(
                                (acc, curr) => parseInt(acc) + parseInt(curr)
                              )
                          ) + " ₹"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box>
                      {pastAppointmentInfo?.appointmentBillingRequest?.appointmentTreatmentCharges
                        .filter((el) => el.nameOfTreatment)
                        .map((el, id) => {
                          return (
                            <>
                              <Grid
                                sx={{ pl: "15px" }}
                                key={el.id}
                                container
                                spacing={2}
                              >
                                <Grid item md={6} xs={6} sm={6}>
                                  <Typography id="treatments" variant="body2">
                                    {id + 1}.{el.nameOfTreatment}
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                  <Typography id="value" variant="body2">
                                    {formatRupeesWithCommas(el.charges)} ₹
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          );
                        })}
                    </Box>
                    <Grid container justifyContent="space-between" spacing={2}>
                      <Grid item>
                        <Typography
                          id="sideheading"
                          variant="body2"
                          fontWeight="bold"
                        >
                          Plan Discount
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          id="amount"
                          variant="body2"
                          fontWeight="bold"
                        >
                          {formatRupeesWithCommas(
                            pastAppointmentInfo?.appointmentBillingRequest
                              ?.planDiscount
                          )}{" "}
                          ₹
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" spacing={2}>
                      <Grid item>
                        <Typography
                          id="sideheading"
                          variant="body2"
                          fontWeight="bold"
                        >
                          Coupon Discount
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          id="amount"
                          variant="body2"
                          fontWeight="bold"
                        >
                          {pastAppointmentInfo?.appointmentBillingRequest
                            ?.couponDiscount
                            ? formatRupeesWithCommas(
                                pastAppointmentInfo?.appointmentBillingRequest
                                  ?.couponDiscount
                              )
                            : 0}{" "}
                          ₹
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                      className="totalchargesgrid"
                      justifyContent="space-between"
                      container
                      spacing={2}
                    >
                      <Grid item>
                        <Typography variant="h5" fontWeight="bold">
                          Total Charges
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontWeight="bold">
                          {formatRupeesWithCommas(
                            pastAppointmentInfo?.appointmentBillingRequest
                              ?.totalTreatmentCost
                          )}{" "}
                          ₹
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                      className="amountpaidgrid"
                      justifyContent="space-between"
                      container
                      spacing={2}
                    >
                      <Grid item>
                        <Typography variant="h5" fontWeight="bold">
                          Amount Paid By Customer After Discount
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontWeight="bold">
                          {formatRupeesWithCommas(
                            pastAppointmentInfo?.appointmentBillingRequest
                              ?.totalPayableAfterDiscount
                          )}{" "}
                          ₹
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Grid>

              {/* ---------------------------right card---------------------- */}

              <Grid item md={4}>
                <Card className="rightcard">
                  <Box className="rightheadbox">
                    <Box className="subbox1">
                      <Typography variant="h5">
                        {pastAppointmentInfo?.clinicName}
                      </Typography>
                      <Typography variant="h4">
                        <LocationOnIcon style={{ fontSize: "12px" }} />{" "}
                        {pastAppointmentInfo?.clinicAddress}
                      </Typography>
                      {/* <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        paddingLeft: "10px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#D83F87",
                          fontSize: "8px",
                          fontWeight: "bold",
                        }}
                      >
                        View Location
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#D83F87",
                          fontSize: "8px",
                          fontWeight: "bold",
                        }}
                      >
                        View Clinic Details
                      </Typography>
                    </Box> */}
                    </Box>
                  </Box>
                  <Box className="rightbodybox">
                    <Typography variant="h4">Previous Appointments</Typography>
                    {appointmentStatus ? (
                      <>
                        {previousAppointments?.perviousAppointmentResponse
                          ?.filter(
                            (appointment) =>
                              appointment.appointmentStatus === "Completed"
                          )
                          .map((el, id) => {
                            return (
                              <Card
                                className="appointmentscard"
                                // onClick={() =>
                                //   navigate(
                                //     `/user/customerpastappointmentinformation/${el.appointmentId}`
                                //   )
                                // }
                              >
                                <Box className="cardhead">
                                  <Typography variant="h5">
                                    {el.appointmentId}
                                  </Typography>
                                  <VisibilityIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => handleOpen(el)}
                                  />
                                </Box>
                                <Box className="cardbodybox">
                                  <Box className="datebox">
                                    <CalendarMonthIcon />
                                    <Typography variant="h4">
                                      {formattedDate(el.createdAt)}
                                    </Typography>
                                  </Box>
                                  {/* <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  mt: "10px",
                                  color: "#2D256C",
                                }}
                                variant="h4"
                              >
                                {el.clinicname}
                              </Typography> */}
                                  <Box className="specialitybox">
                                    <Typography
                                      sx={{
                                        fontSize: "10px",
                                        backgroundColor: "#FFEFF9",
                                        padding: "5px",
                                        color: "gray",
                                        textAlign: "center",
                                      }}
                                    >
                                      {el.speciality}
                                    </Typography>
                                  </Box>
                                  {/* <Box className="analysisbox">
                                <Typography>
                                  <b>Doctor’s Analysis </b>
                                  {el.doctoranalysis}
                                </Typography>
                              </Box> */}
                                </Box>
                              </Card>
                            );
                          })}
                      </>
                    ) : (
                      <Box>
                        <Typography sx={{ fontSize: "12px", mt: "10px" }}>
                          No history found
                        </Typography>{" "}
                      </Box>
                    )}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Card>
        )}
        {/* ----------------------------Modal----------------------------- */}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {[
              {
                title: "Customer Name :",
                value: previousAppointments?.customerName,
              },
              {
                title: "Customer Number :",
                value: previousAppointments?.customerNo,
              },
              {
                title: "Appointment Status :",
                value: prevIndividualData?.appointmentStatus,
              },
              { title: "Speciality :", value: prevIndividualData?.speciality },
            ].map((ele, id) => (
              <Grid container spacing={1} alignItems="center" mt="3px">
                <Grid item md={6} sm={6}>
                  <Typography variant="h5" fontSize="14px">
                    {ele.title}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={6}>
                  <Typography variant="h4" fontSize="14px">
                    {ele.value}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Modal>
      </Box>
    </div>
  );
};

export default CustomerPastDetails;
