import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  OutlinedInput,
  TablePagination,
  Typography,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import "./TestBookingTab.scss";
import { CompletedHealthiansOrder } from "../../../services/HealthiansApi";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const TestOrderHistory = () => {
  const [orderHistory, setOrderHistory] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };
  const filteredData = orderHistory.filter((item) => {
    const matchesSearch =
      item.billing_cust_name
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      (item.mobile && item.mobile.includes(searchValue));
    const matchesDate =
      selectedDate === null ||
      dayjs(item.test_date).isSame(dayjs(selectedDate), "day");
    return matchesSearch && matchesDate;
  });

  // Adjust page if it exceeds the number of pages
  const maxPages = Math.ceil(filteredData.length / rowsPerPage);
  if (page >= maxPages && maxPages > 0) {
    setPage(maxPages - 1);
  }
  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleReset = () => {
    setSearchValue("");
    setSelectedDate(null);
    getCompletedOrders();
    setPage(0);
  };

  const getCompletedOrders = async () => {
    try {
      const res = await CompletedHealthiansOrder();
      const sortedOrders = res.data.sort((a, b) => {
        const dateA = dayjs(a.test_date);
        const dateB = dayjs(b.test_date);
        return dateB.diff(dateA);
      });
      setOrderHistory(sortedOrders);
    } catch (error) {
      console.log(error.message);
    }
  };
  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  useEffect(() => {
    getCompletedOrders();
  }, []);
  return (
    <div className="testOrderHistory">
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item sm={5} xs={5} md={5}>
          <OutlinedInput
            id="outlined-search-packages"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: " rgba(216, 63, 135, 1)" }} />
              </InputAdornment>
            }
            size="small"
            placeholder="Search By Name or Number"
            sx={{ background: "#FFFFFF" }}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            fullWidth
          />
        </Grid>
        <Grid item sm={5} xs={5} md={5}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              inputFormat="dd/MM/yyyy"
              value={selectedDate}
              onChange={(newDate) => setSelectedDate(newDate)}
              renderInput={(params) => (
                <OutlinedInput
                  {...params}
                  size="small"
                  sx={{ backgroundColor: "#FFFFFF" }}
                  placeholder="Select Date"
                  endAdornment={
                    <InputAdornment position="end">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  }
                  fullWidth
                />
              )}
              orientation={"portrait"}
              onYearChange={onYearChangeHandler}
              variant="outlined"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={2} xs={2} md={2}>
          <Button
            fullWidth
            sx={{
              backgroundColor: "#d83f87",
              textAlign: "left",
              color: "#FFFFFF",
              textTransform: "capitalize",
              fontSize: "12px",
              borderRadius: "3px",
              padding: "8px 15px",
              "&:hover": { backgroundColor: "#d83f87!important" },
            }}
            onClick={handleReset}
            size="small"
          >
            <RestartAltIcon sx={{ fontSize: "0.9rem" }} /> Reset
          </Button>
        </Grid>
      </Grid>
      <Card className="orderHistoryTabMainCard">
        {paginatedData.map((el, id) => {
          return (
            <Card className="list" key={el.id}>
              <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item sm={3} xs={3} md={2} className="firstGridItem">
                  <Typography variant="caption" className="idhead">
                    Customer Name
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#2D2828",
                      margin: "0px",
                      fontSize: "12px",
                    }}
                  >
                    {el.billing_cust_name}
                  </Typography>
                  <Box className="emailBox">
                    <Typography variant="caption" className="idhead">
                      Email
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2D2828",
                        margin: "0px",
                        fontSize: "12px",
                      }}
                    >
                      {el.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={6} md={8} className="secondGridItem">
                  <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item sm={6} xs={6} md={6} className="subItem1">
                      <Typography
                        className="idhead"
                        sx={{
                          fontSize: "10px",
                          color: "#d83f87",
                          fontWeight: "bold",
                        }}
                      >
                        Mobile No
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PhoneIcon style={{ fontSize: "12px" }} />{" "}
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: "12px",
                            color: "rgba(68, 68, 68, 1)",
                          }}
                        >
                          {el.mobile == null ? <>No number</> : el.mobile}
                        </Typography>
                      </Box>

                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="locationBox"
                      >
                        <LocationOnIcon style={{ fontSize: "12px" }} />
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: "12px",
                            color: "rgba(68, 68, 68, 1)",
                          }}
                        >
                          {el.address}, {el.landmark}, {el.zipcode}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={6} xs={6} md={6} className="subItem2">
                      <Box className="datebox">
                        <Typography>Collection Date</Typography>
                        <Typography variant="h6">
                          {dayjs(el.test_date).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>
                      <Box className="bookingId">
                        <Typography>Booking id</Typography>
                        <Typography variant="h6">
                          {el.vendor_booking_id}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={3} xs={3} md={2} className="thridGridItem">
                  <Box className="btnsBox">
                    <Button
                      sx={{
                        backgroundColor: "#d83f87",
                        textAlign: "left",
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        fontSize: "12px",
                        borderRadius: "3px",
                        // padding: "8px 15px",
                        "&:hover": { backgroundColor: "#d83f87!important" },
                      }}
                      size="small"
                      onClick={() =>
                        navigate(`/user/healthianOrder/${el.vendor_booking_id}`)
                      }
                    >
                      <CalendarMonthIcon sx={{ fontSize: "0.9rem" }} /> Open
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          );
        })}
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 15, 25]}
          count={filteredData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  );
};

export default TestOrderHistory;
