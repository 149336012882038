import React, { useEffect, useState } from "react";
import "./Packages.scss";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import * as yup from "yup";
import { useFormik } from "formik";
import PackageList from "./PackageList";
import { HealthiansPackageProducts } from "../../services/HealthiansApi";
import { useNavigate } from "react-router-dom";

const Packages = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const open = Boolean(openMenu);
  const [packageLists, setPackagesLists] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate("");
  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  // Formik and Yup validation setup
  const formik = useFormik({
    initialValues: {
      sortBy: "RU", // Default to "Recently Uploaded"
      categories: [],
    },
    validationSchema: yup.object({
      categories: yup.array().required("Required"),
    }),
    onSubmit: (values) => {
      console.log("Form submitted:", values);
      const sortedValues = {
        ...values,
        categories: values.categories.sort((a, b) => a.localeCompare(b)),
      };
      // Here you can send values to your API
      fetchProducts(sortedValues);
      // Example: fetch data based on sortBy and categories
      handleClose();
    },
  });

  const fetchProducts = async (filters) => {
    console.log(filters);
    try {
      const res = await HealthiansPackageProducts(filters); // POST API call
      const sortedPackages = (res.data || []).sort((a, b) => {
        if (a.our_status === "Active" && b.our_status !== "Active") {
          return -1;
        }
        if (a.our_status !== "Active" && b.our_status === "Active") {
          return 1;
        }
        return 0;
      });
      setPackagesLists(sortedPackages);
    } catch (error) {
      console.error("Error fetching packages:", error.message);
    }
  };

  const handleCategoryChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      formik.setFieldValue("categories", [...formik.values.categories, value]);
    } else {
      formik.setFieldValue(
        "categories",
        formik.values.categories.filter((category) => category !== value)
      );
    }
  };

  const handleSortByChange = (event) => {
    formik.setFieldValue("sortBy", event.target.value);
    console.log(event.target.value);
  };

  useEffect(() => {
    fetchProducts(formik.values);
  }, [formik.values.sortBy]);

  return (
    <div className="packagesMainDiv">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" gutterBottom>
          Packages
        </Typography>

        <Button
          sx={{
            backgroundColor: "#D83F87",
            textAlign: "left",
            color: "#FFFFFF",
            textTransform: "capitalize",
            fontSize: "12px",
            display: "flex",
            justifyContent: "space-around",
            gap: "5px",
            "&:hover": {
              backgroundColor: "#D83F87!important",
            },
          }}
          variant="outlined"
          onClick={() => navigate("/user/testBookingTab/upcoming")}
        >
          <PersonAddAltIcon /> Test Bookings
        </Button>
      </div>

      <Card>
        <Grid container spacing={2}>
          <Grid item sm={8} xs={6} md={8}>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <SearchIcon />
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "searchPackages",
                }}
                size="small"
                placeholder="Search Packages"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item sm={2} xs={3} md={2}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Search
            </Button>
          </Grid>
          <Grid item sm={2} xs={3} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              id="basic-button"
              aria-controls={openMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClick={handleClick}
            >
              <TuneIcon /> Filter
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={openMenu}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <FormControl sx={{ padding: "10px" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Sort By
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={formik.values.sortBy}
                  onChange={handleSortByChange}
                >
                  <FormControlLabel
                    value="RU"
                    control={<Radio />}
                    label="Recently Uploaded"
                  />
                  <FormControlLabel
                    value="RM"
                    control={<Radio />}
                    label="Recently Modified"
                  />
                </RadioGroup>
              </FormControl>
              <form onSubmit={formik.handleSubmit}>
                <FormControl sx={{ padding: "10px" }}>
                  <FormLabel id="demo-checkbox-group-label">
                    Speciality
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="Dental"
                        checked={formik.values.categories.includes("Dental")}
                        onChange={handleCategoryChange}
                      />
                    }
                    label="Dental"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="ENT"
                        checked={formik.values.categories.includes("ENT")}
                        onChange={handleCategoryChange}
                      />
                    }
                    label="ENT"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="Gastroenterology"
                        checked={formik.values.categories.includes(
                          "Gastroenterology"
                        )}
                        onChange={handleCategoryChange}
                      />
                    }
                    label="Gastroenterology"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="General"
                        checked={formik.values.categories.includes("General")}
                        onChange={handleCategoryChange}
                      />
                    }
                    label="General"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="Gynecology"
                        checked={formik.values.categories.includes(
                          "Gynecology"
                        )}
                        onChange={handleCategoryChange}
                      />
                    }
                    label="Gynecology"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="Ophthalmology"
                        checked={formik.values.categories.includes(
                          "Ophthalmology"
                        )}
                        onChange={handleCategoryChange}
                      />
                    }
                    label="Ophthalmology"
                  />

                  {formik.errors.categories && (
                    <Typography color="error" sx={{ fontSize: "12px" }}>
                      {formik.errors.categories}
                    </Typography>
                  )}
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    width: "90%",
                    display: "flex",
                    margin: "auto",
                  }}
                >
                  Apply
                </Button>
              </form>
            </Menu>
          </Grid>
        </Grid>

        {/* ******************************List****************************** */}

        <PackageList
          searchQuery={searchQuery}
          packageLists={packageLists}
          setSearchQuery={setSearchQuery}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          refreshPackageList={() => fetchProducts(formik.values)}
        />
      </Card>
    </div>
  );
};

export default Packages;
