import * as React from "react";
import {
  Typography,
  Card,
  Paper,
  IconButton,
  InputAdornment,
  Grid,
  Button,
  Modal,
  Autocomplete,
} from "@mui/material";
import { Search, Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { visuallyHidden } from "@mui/utils";
import {
  activateOrDeactivate,
  getClinicDataDateRange,
  getClinicDataDateRangeParticular,
  getClinicList,
  getClinicStaff,
} from "../../services/clinicOnboarding";
import { CssTextField } from "../../styles/textFields";
import { StyledTableBagCell, StyledTableBagRow } from "../../styles/table";
import XLSX from "xlsx";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DatePicker } from "@mui/x-date-pickers";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useState } from "react";
import { getStaffList } from "../../services/staffOnboarding";
import { toast } from "react-toastify";
import moment from "moment";
import VerifiedIcon from "@mui/icons-material/Verified";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { manuallyVerifyTheClinic } from "../../services/customerOnboarding";
import { Permissions } from "../Context/PermissionsContext";
import { useContext } from "react";
import Loader from "../Loader";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "clinicName",
    numeric: true,
    disablePadding: false,
    label: "Clinic Name",
  },
  {
    id: "clinicType",
    numeric: true,
    disablePadding: false,
    label: "Clinic Type",
  },
  {
    id: "category",
    numeric: true,
    disablePadding: false,
    label: "Category ",
  },
  {
    id: "ownerName",
    numeric: true,
    disablePadding: false,
    label: "Owner's Name",
  },
  {
    id: "city",
    numeric: true,
    disablePadding: false,
    label: "City",
  },

  {
    id: "state",
    numeric: true,
    disablePadding: false,
    label: "State",
  },

  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Enrolled On",
  },
  {
    id: "live",
    numeric: true,
    disablePadding: false,
    label: "Live Status",
  },
  {
    id: "",
    // numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableBagRow>
        {headCells.map((headCell, index) => (
          <StyledTableBagCell
            key={index}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ "&:hover": { color: "#d9d9d9" } }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableBagCell>
        ))}
      </StyledTableBagRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ClientList() {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("orderDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [rowData, setRowData] = React.useState([]);
  const [dateTo, setDateTo] = React.useState("");
  const [dateFrom, setDateFrom] = React.useState("");
  const [staff, setStaff] = React.useState("");
  const [idVal, setIdVal] = React.useState("");
  const [deactivate, setDeactivate] = React.useState(false);
  const { permissionsData, loading, setLoading } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "clinic"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  let AddPermission = clinicPermissions?.find(
    (item) => item.action === "Add"
  )?.permission;
  let ListPermission = clinicPermissions?.find(
    (item) => item.action === "List"
  )?.permission;
  let ViewPermission = clinicPermissions?.find(
    (item) => item.action === "View"
  )?.permission;
  // let AddPermission=false
  console.log("addpermission", ListPermission);

  const tokens = localStorage.getItem("token");
  const role = JSON.parse(tokens)?.role;
  const username = JSON.parse(tokens)?.username;
  const designation = JSON.parse(tokens)?.designation;

  function formattedDate(d) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${year}-${month}-${day}`;
  }

  async function handleDateRange() {
    if (!dateTo || !dateFrom) {
      return;
    } else {
      const from = formattedDate(dateFrom);
      // console.log('from: ', from);
      const to = formattedDate(dateTo);
      const { data } = await getClinicDataDateRange(from, to);
      console.log("data: ", data);
      if (data) {
        setRowData(data);
        setOpen(!open);
      }
    }
  }
  async function handleDateRangeParticular() {
    if (!dateTo || !dateFrom) {
      return;
    } else {
      const from = formattedDate(dateFrom);
      // console.log('from: ', from);
      const to = formattedDate(dateTo);
      const { data } = await getClinicDataDateRangeParticular(from, to);
      console.log("data: ", data);
      if (data) {
        setRowData(data);
        setOpen(!open);
      }
    }
  }

  async function handleStaffChange(val) {
    const { data } = await getClinicStaff(val);
    console.log("data: ", data);
    setRowData(data);
  }

  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  React.useEffect(() => {
    // const tokens = localStorage.getItem("token");

    //     if (token.stationCode) {
    //     getAllPacketStation();
    //   } else {
    //     getAllPacketDeliveryHub();
    //   }
    const getClinicLists = async () => {
      setLoading(true);
      const { data } = await getClinicList();
      console.log("data: ", data);
      // const sortedData = data.sort((a, b) => a.createdAt - b.createdAt);
      if (data) {
        setRowData(data);
        setLoading(false);
      }
    };
    const getClinicListsParticular = async () => {
      setLoading(true);
      const { data } = await getClinicStaff(username);
      console.log("data: ", data);
      // const sortedData = data.sort((a, b) => a.createdAt - b.createdAt);
      if (data) {
        setRowData(data);
        setLoading(false);
      }
    };
    role === "Admin" ||
    designation === "Customer Support Executive" ||
    role === "Staff"
      ? getClinicLists()
      : getClinicListsParticular();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  };

  const rows = rowData.filter(
    (item) =>
      item.clinicName
        .toString()
        .toLowerCase()
        .includes(search.toString().toLowerCase()) ||
      item.category.toString().toLowerCase().includes(search.toLowerCase()) ||
      item.clinicType.toString().toLowerCase().includes(search.toLowerCase()) ||
      item.ownerName.toString().toLowerCase().includes(search.toLowerCase()) ||
      item.city.toString().toLowerCase().includes(search.toLowerCase()) ||
      item.createdBy.toString().toLowerCase().includes(search.toLowerCase()) ||
      item.live.toString().toLowerCase().includes(search.toLowerCase()) ||
      item.state.toString().toLowerCase().includes(search.toLowerCase())
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(rowData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "branches");
    //Buffer
    let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "ClinicList.xlsx");
  };

  const [open, setOpen] = React.useState(false);
  const [staffData, setStaffData] = useState();
  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  React.useEffect(() => {
    const getStaffLists = async () => {
      const { data } = await getStaffList();
      console.log("data: ", data);
      // const sortedData = data.sort((a, b) => a.createdAt - b.createdAt);
      const sortedData = data;
      setStaffData(sortedData);
    };
    getStaffLists();
  }, []);

  const deactivateOrActivate = async () => {
    const val = {
      id: idVal,
      updatedBy: username,
    };
    const getClinicLists = async () => {
      const { data } = await getClinicList();
      console.log("data: ", data);
      // const sortedData = data.sort((a, b) => a.createdAt - b.createdAt);
      setRowData(data);
    };
    const getClinicListsParticular = async () => {
      const { data } = await getClinicStaff(username);
      console.log("data: ", data);
      // const sortedData = data.sort((a, b) => a.createdAt - b.createdAt);
      setRowData(data);
    };
    const { data } = await activateOrDeactivate(val);
    console.log("data: ", data);
    if (data === "Deactivated" || data === "Activated") {
      setDeactivate(false);

      success();

      role === "Admin" ? getClinicLists() : getClinicListsParticular();
    } else error();
  };

  return (
    <>
      <Box>
        <div className="flexAlagAlag">
          <Grid container spacing={1}>
            <Grid item md={5} xs={12} sm={12}>
              <div>
                <Typography
                  variant="h5"
                  sx={{ alignItems: "center", fontSize: "17px" }}
                >
                  Clinic List
                </Typography>
              </div>
            </Grid>
            <Grid item md={7} xs={12} sm={12}>
              <Grid container spacing={1}>
                <Grid item md={4} sm={6} xs={6}>
                  <CssTextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Search..."
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.currentTarget.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ marginRight: "0px" }}
                        >
                          <IconButton
                            sx={{
                              backgroundColor: "#F7F7FC",
                              borderRadius: "8px 0px 0px 8px",
                            }}
                            edge="start"
                          >
                            <Search sx={{ backgroundColor: "#F7F7FC" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {role === "Admin" ||
                designation === "Team Lead" ||
                role === "Staff" ? (
                  <Grid item md={4} sm={6} xs={6}>
                    <Autocomplete
                      disableClearable
                      options={staffData}
                      getOptionLabel={(option) => option?.displayName}
                      onChange={(event, value) => {
                        console.log("value: ", value);
                        // setStaff(value)
                        handleStaffChange(value.displayName);
                      }}
                      // value={
                      // formik_staffRegistration.values[item.value]
                      //   ? formik_staffRegistration.values[item.value]
                      //   : null
                      // }
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          fullWidth
                          placeholder="Staff"
                          variant="outlined"
                          type="text"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item md={1} xs={1.5}>
                  <Box
                    sx={{
                      backgroundColor: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      padding: "4px",
                      borderRadius: "3px",
                    }}
                  >
                    <CalendarMonthIcon
                      onClick={() => {
                        setOpen(true);
                      }}
                      sx={{ cursor: "pointer", fontSize: "2rem" }}
                    />
                  </Box>

                  <Modal open={open} onClose={() => setOpen(false)}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: width > "786px" ? "600" : "15em",
                        borderRadius: "8px",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <Typography
                            mt={1}
                            mb={1}
                            variant="subtitle2"
                            fontWeight="bold"
                          >
                            Date Range From
                          </Typography>
                          <DatePicker
                            name={dateFrom}
                            onChange={(newValue) => {
                              setDateFrom(newValue);
                              // handleChange("practiceSince", index, newValue);
                            }}
                            value={dateFrom}
                            maxDate={new Date()}
                            renderInput={(params) => (
                              <CssTextField
                                // error={errors && errors[index]?.practiceSince}
                                // helperText={
                                //   errors &&
                                //   errors[index]?.practiceSince &&
                                //   "This Field is required"
                                // }
                                focused
                                {...params}
                                placeholder="Date Range From"
                                size="small"
                                // name={item.value}
                                fullWidth
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <Typography
                            mt={1}
                            mb={1}
                            variant="subtitle2"
                            fontWeight="bold"
                          >
                            Date Range To
                          </Typography>
                          <DatePicker
                            name={dateTo}
                            onChange={(newValue) => {
                              setDateTo(newValue);
                              // handleChange("practiceSince", index, newValue);
                            }}
                            value={dateTo}
                            minDate={dateFrom}
                            maxDate={new Date()}
                            renderInput={(params) => (
                              <CssTextField
                                // error={errors && errors[index]?.practiceSince}
                                // helperText={
                                //   errors &&
                                //   errors[index]?.practiceSince &&
                                //   "This Field is required"
                                // }
                                focused
                                {...params}
                                placeholder="Date Range To"
                                size="small"
                                name={dateTo}
                                value={dateTo}
                                fullWidth
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Button
                        variant="contained"
                        sx={{ ml: width > "786px" ? "86%" : "70%", mt: 2 }}
                        onClick={() => {
                          role === "Admin"
                            ? handleDateRange()
                            : handleDateRangeParticular();
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Modal>
                </Grid>
                <Grid item md={1} sm={1} xs={1.5}>
                  <Box
                    sx={{
                      backgroundColor: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      padding: "4px",
                      borderRadius: "3px",
                    }}
                  >
                    <InsertDriveFileIcon
                      sx={{ cursor: "pointer", fontSize: "2rem" }}
                      onClick={() => {
                        downloadExcel();
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item md={2} xs={4} sm={2}>
                  {AddPermission && (
                    <Button
                      sx={{
                        borderRadius: "3px",
                        padding: "8px 15px",
                        fontSize: "12px",
                        backgroundColor: "#2d256c!important",
                        "&:hover": { backgroundColor: "#2d256c!important" },
                      }}
                      variant="contained"
                    >
                      <Link to={"/user/clinicOnboard"}>Add New</Link>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              mt: "10px",
              backgroundColor: "#f7f7fc",
            }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 1100 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                {ListPermission && (
                  <TableBody>
                    {/* {console.log("listpermision 716",rows)} */}
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.name);
                        console.log("isItemSelected: ", isItemSelected);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <StyledTableBagRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            // role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row?.id}
                            sx={{
                              backgroundColor:
                                row?.status === false ? "#e7e7e7" : "#fff",
                              color: row?.status === false ? "#A2A2A2" : "#000",
                            }}
                            // selected={isItemSelected}
                          >
                            <StyledTableBagCell align="left" padding="normal">
                              {row?.clinicAgreement !== "yes" ? (
                                <UnpublishedIcon sx={{ color: "red" }} />
                              ) : (
                                <VerifiedIcon sx={{ color: "green" }} />
                              )}
                            </StyledTableBagCell>
                            <StyledTableBagCell
                              component="th"
                              id={labelId}
                              scope="row"
                              align="left"
                              padding="normal"
                              sx={{ textDecoration: "underline" }}
                            >
                              <Link to={`/user/clinicOnboard/${row.uniqueId}`}>
                                <span
                                  style={{ color: "#2D256C", fontSize: "14px" }}
                                >
                                  {row?.clinicName}
                                </span>
                              </Link>
                            </StyledTableBagCell>
                            <StyledTableBagCell
                              style={{ fontSize: "14px" }}
                              align="left"
                              padding="normal"
                            >
                              {row?.clinicType}
                            </StyledTableBagCell>
                            <StyledTableBagCell
                              style={{ fontSize: "14px" }}
                              align="left"
                              padding="normal"
                            >
                              {row.category}
                            </StyledTableBagCell>
                            <StyledTableBagCell
                              style={{ fontSize: "14px" }}
                              align="left"
                              padding="normal"
                            >
                              {row.ownerName}
                            </StyledTableBagCell>
                            <StyledTableBagCell
                              style={{ fontSize: "14px" }}
                              align="left"
                              padding="normal"
                            >
                              {row.city}
                            </StyledTableBagCell>

                            <StyledTableBagCell
                              style={{ fontSize: "14px" }}
                              align="left"
                              padding="normal"
                            >
                              {row.state}
                            </StyledTableBagCell>
                            <StyledTableBagCell
                              style={{ fontSize: "14px" }}
                              align="left"
                              padding="normal"
                            >
                              {moment(row?.createdAt).format("Do MMMM YYYY")}
                            </StyledTableBagCell>
                            <StyledTableBagCell
                              style={{ fontSize: "14px" }}
                              align="left"
                              padding="normal"
                            >
                              {row?.live ? "Live" : "Not Live"}
                            </StyledTableBagCell>
                            <StyledTableBagCell>
                              {" "}
                              {/* <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" /> */}
                              {/* <Link
                              className="link-style"
                              to={`viewDA/${row.id}`}
                            > */}
                              {/* </Link> */}
                              <div>
                                {ViewPermission && (
                                  <Link
                                    to={`/user/clinicOnboard/${row.uniqueId}`}
                                  >
                                    <RemoveRedEyeIcon
                                      sx={{ color: "gray", mr: 2 }}
                                    />
                                  </Link>
                                )}
                                <RemoveCircleOutlineIcon
                                  sx={{ color: "gray" }}
                                  onClick={() => {
                                    // deactivateOrActivate(row.id);
                                    setIdVal(row.id);
                                    setDeactivate(true);
                                  }}
                                />
                              </div>
                            </StyledTableBagCell>
                          </StyledTableBagRow>
                        );
                      })}{" "}
                    {emptyRows > 0 && (
                      <StyledTableBagRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <StyledTableBagCell colSpan={6} />
                      </StyledTableBagRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              sx={{ backgroundColor: "#F7F7FC" }}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
        <Modal
          open={deactivate}
          onClose={() => setDeactivate(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography variant="body">Confirm To Deactivate? </Typography>
            <Box sx={{ minWidth: 120 }}></Box>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                onClick={() => {
                  setDeactivate(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  deactivateOrActivate();
                }}
              >
                Confirm
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
