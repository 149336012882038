import http from "./httpServices";
import config from "../config.json";
const tokens = localStorage.getItem("token");
const token = JSON.parse(tokens)?.token;
const uniqueId = JSON.parse(tokens)?.uniqueId;

const head = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
    Authorization: `Bearer ${token}`,
  },
};

export function getHealthiansAccessToken() {
  return http.get(config.apiUrl + "getHealthiansAccessToken", head);
}
export function HealthiansPackageProducts(values) {
  return http.post(config.apiUrl + "getHealthiansProducts", values, head);
}

export function UpdateTheHealthiansPackage(values) {
  return http.post(config.apiUrl + "updateHealthiansProduct", values, head);
}

// customer login api's

export function CheckingOfServiceAvilabilityByLatlong(values) {
  return http.post(
    config.apiUrl + "checkServiceabilityByLocation",
    values,
    head
  );
}

export function getFamilyMembersForThisCustomer() {
  return http.get(
    config.apiUrl + `getFamilyMembersOfCustomer/${uniqueId}`,
    head
  );
}

export function getPartnerHealthianPackages(values) {
  return http.post(config.apiUrl + "getPartnerProducts", values, head);
}

export function getB2CAddresses() {
  return http.get(config.apiUrl + `getB2CCustomerAddress/${uniqueId}`, head);
}

export function postB2cCustomerAddresses(values) {
  return http.post(config.apiUrl + "postB2CCustomerAddress", values, head);
}

export function PostSlotsByLocation(values) {
  return http.post(config.apiUrl + "getSlotsByLocation", values, head);
}

export function getPaymentLinkForHealthianOrder(billingBody) {
  return http.post(
    config.apiUrl + "getPaymentLinkForHealthiansOrder",
    billingBody,
    head
  );
}

export function FreezeSlot(body) {
  return http.post(config.apiUrl + "freezeSlot_v1", body, head);
}

export function getHealthiansOrderBookingByMerchantTransactionId(id) {
  return http.get(
    config.apiUrl + `getHealthiansOrderBookingByMerchantTransactionId/${id}`,
    head
  );
}

export function RetryPaymentForHealthiansOrder(id) {
  return http.get(
    config.apiUrl + `retryPaymentLinkForHealthiansOrder/${id}`,
    head
  );
}

export function createHealthiansOrderBookingWithAuthkey(body) {
  return http.post(
    config.apiUrl + "createHealthiansOrderBooking_v3",
    body,
    head
  );
}

export function DeleteAddress(id) {
  return http.delete(
    config.apiUrl + `deleteB2CCustomerAddressDetails/${id}`,
    head
  );
}

export function UpcomingCustomerLoginHealthiansOrder(number) {
  return http.get(
    config.apiUrl + `getUpcommingHealthiansCustomerOrderBooking/${number}`,
    head
  );
}

export function UpcomingHealthiansOrder() {
  return http.get(config.apiUrl + `getUpcommingHealthiansOrderBooking`, head);
}

export function CompletedCustomerLoginHealthiansOrder(number) {
  return http.get(
    config.apiUrl + `getCompletedHealthiansCustomerOrderBooking/${number}`,
    head
  );
}

export function CompletedHealthiansOrder() {
  return http.get(config.apiUrl + `getCompletedHealthiansOrderBooking`, head);
}

export function setTriggerStatusCompleted(id, bookingId) {
  return http.get(
    config.apiUrl + `setTriggerStatusCompleted/${id}/${bookingId}`,
    head
  );
}

export function getHealthianOrderDetails(id) {
  return http.get(config.apiUrl + `getHealthiansOrderBooking/${id}`, head);
}

export function RescheduleBooking(body) {
  return http.post(
    config.apiUrl + "rescheduleBookingByCustomer_v1",
    body,
    head
  );
}
