import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Card, Tab, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Upcoming from "./Upcoming";
import TestOrderHistory from "./TestOrderHistory";

const TestBookingTab = () => {
  const { tabValue } = useParams();
  const [currentTab, setCurrentTab] = useState(tabValue || "upcoming");
  const navigate = useNavigate("");
  const [date, setDate] = useState(dayjs().format("DD-MM-YYYY"));

  useEffect(() => {
    if (tabValue && tabValue !== currentTab) {
      setCurrentTab(tabValue);
    }
  }, [tabValue, currentTab]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    navigate(`/user/testBookingTab/${newValue}`);
    if (newValue === "upcoming") {
      setDate(new Date());
    }
  };

  return (
    <div>
      <div className="testBookingTabMainDiv">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" gutterBottom className="heading">
            Test Booking
          </Typography>
        </Box>
        <div>
          <TabContext value={currentTab}>
            <Box>
              <TabList
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
                aria-label="Test order history tabs"
              >
                <Tab
                  label="Upcoming"
                  value="upcoming"
                  sx={
                    currentTab === "upcoming"
                      ? {
                          backgroundColor: "#2D256C",
                          textTransform: "none",
                          borderRadius: "5px 0px 0px 5px",
                          color: "white",
                          minHeight: "40px",
                          fontSize: "12px",
                          fontWeight: 600,
                          padding: "5px 30px",
                        }
                      : {
                          backgroundColor: "white",
                          textTransform: "none",
                          border: "0.5px solid #D9D9D9",
                          borderRadius: "5px 0px 0px 5px",
                          color: "black",
                          minHeight: "40px",
                          fontSize: "12px",
                          fontWeight: 600,
                          padding: "5px 30px",
                        }
                  }
                />
                <Tab
                  label="Order History"
                  value="orderHistory"
                  sx={
                    currentTab === "orderHistory"
                      ? {
                          backgroundColor: "#2D256C",
                          textTransform: "none",
                          borderRadius: "0px 5px 5px 0px",
                          color: "white",
                          minHeight: "40px",
                          fontSize: "12px",
                          fontWeight: 600,
                          padding: "5px 30px",
                        }
                      : {
                          backgroundColor: "white",
                          textTransform: "none",
                          border: "0.5px solid #D9D9D9",
                          borderRadius: "0px 5px 5px 0px",
                          color: "black",
                          minHeight: "40px",
                          fontSize: "12px",
                          fontWeight: 600,
                          padding: "5px 30px",
                        }
                  }
                />
              </TabList>
            </Box>
            <TabPanel value="upcoming">
              <Card className="upcomingTabMainCard">
                <Upcoming />
              </Card>
            </TabPanel>
            <TabPanel value="orderHistory">
              <TestOrderHistory />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
};

export default TestBookingTab;
