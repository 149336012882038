import { Button, Card, Checkbox, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

const HealthInfo = ({ familyInfo, health, setHealth, setSelected }) => {
  console.log("health: ", health);
  const [select, setSelect] = useState(familyInfo?.[0]?.name);
  const [ind, setInd] = useState(0);
  const [initialHealthState, setInitialHealthState] = useState([]);
  // console.log("ind: ", ind);

  const error = () => {
    toast.error("Please answer all health questions", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // const val = health.map((item) =>
  //   item.b2cCustomerQuestionDetails.filter((item) => !item.response)
  // );
  // console.log("val: ", val);
  // const proceed = val.filter((item) => item.length);
  // console.log("proceed: ", proceed);

  const questions = [
    { question: "Are you diabetic?", response: "" },
    { question: "Do you suffer from high BP?", response: "" },
    { question: "Do you have any missing teeth?", response: "" },
    { question: "Are you hearing impaired?", response: "" },
    {
      question: "Have you been hospitalised in the last 6 months?",
      response: "",
    },
    { question: "Do you have any disability?", response: "" },
    { question: "Do you wear glasses?", response: "" },
    { question: "Do you consume alcohol/tobacco?", response: "" },
    { question: "Any other pre-existing medical condition?", response: "" },
  ];

  const handleNext = () => {
    const incompleteResponses = health.some((member) =>
      member.b2cCustomerQuestionDetails.some((question) => !question.response)
    );

    if (incompleteResponses) {
      error();
    } else {
      setSelected("Payment");
    }
  };

  const handleBack = () => {
    setHealth(initialHealthState); // Reset to initial state
    setSelected("Family Info");
  };
  useEffect(() => {
    // if (
    //   familyInfo?.b2cCustomerMemberDetailsRequest?.[0]
    //     ?.b2cCustomerQuestionDetails?.[0]?.response === "Yes" ||
    //   familyInfo?.b2cCustomerMemberDetailsRequest?.[0]
    //     ?.b2cCustomerQuestionDetails?.[0]?.response === "No"
    // ) {
    //   return;
    // }
    if (familyInfo?.length && health.length === 0) {
      const vals = familyInfo.map((item) => {
        return {
          name: item?.name,
          relation: item?.relation,
          dob: item?.dob,
          b2cCustomerQuestionDetails: [...questions],
          gender: item?.gender,
        };
      });
      // console.log("vals: ", vals);
      setHealth(vals);
      setInitialHealthState(vals);
    }
  }, [familyInfo, health, setHealth]);

  return (
    <Card>
      {health.map((item, index) => (
        <React.Fragment key={index}>
          <span
            style={{
              backgroundColor: select === item?.name ? "#2D256C" : "#fff",
              color: select === item?.name ? "#fff" : "#2D256C",
              border: "2px solid #2D256C",
              borderRadius: "12px",
              cursor: "pointer",
              margin: "5px",
              padding: "5px 30px 5px 30px",
              fontSize: "20px",
              fontWeight: "600",
            }}
            onClick={() => {
              setSelect(item?.name);
              setInd(index);
            }}
          >
            {item?.name}
          </span>
        </React.Fragment>
      ))}
      <Grid container spacing={2} sx={{ padding: "20px" }}>
        {health?.[ind]?.b2cCustomerQuestionDetails?.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={6}>
              <Typography mt={1} mb={1} variant="h6" fontWeight="bold">
                {item?.question}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Checkbox
                checked={item.response === "Yes"}
                onChange={() => {
                  const value = JSON.parse(JSON.stringify(health));
                  value[ind].b2cCustomerQuestionDetails[index].response = "Yes";
                  // console.log(
                  //   "value: ",
                  //   value[ind]?.b2cCustomerQuestionDetails[index].response,
                  //   index
                  // );
                  setHealth(value);
                }}
                sx={{
                  "&.Mui-checked": {
                    color: "#d83f87",
                  },
                }}
              />
              Yes
            </Grid>
            <Grid item xs={3}>
              <Checkbox
                checked={item.response === "No"}
                onChange={() => {
                  const value = JSON.parse(JSON.stringify(health));
                  value[ind].b2cCustomerQuestionDetails[index].response = "No";
                  console.log("value: ", value);
                  setHealth(value);
                }}
                sx={{
                  "&.Mui-checked": {
                    color: "#d83f87",
                  },
                }}
              />
              No
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <br />
      <br />
      <div style={{ display: "flex", alignContent: "space-between" }}>
        <Button
          variant="contained"
          sx={{
            width: "15em",
            backgroundColor: "#2d256c!important",
            fontSize: "12px",
            padding: "8px 15px",
            marginLeft: "10px",
          }}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          type="submit"
          sx={{
            width: "15em",
            backgroundColor: "#2d256c!important",
            fontSize: "12px",
            padding: "8px 15px",
            marginLeft: "10px",
          }}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </Card>
  );
};

export default HealthInfo;
