import { Button, Card, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CssTextField } from "../../styles/textFields";
import { generateOtp, verifyOtp } from "../../services/messages";
import { useState } from "react";
import {
  generateOtpToBuyPlan,
  getB2CCustomerDetailsForNumber,
} from "../../services/login";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";

const Verification = ({
  selected,
  setSelected,
  number,
  setNumber,
  oTP,
  setOTP,
  setHealth,
  setFamilyInfo,
}) => {
  const [otpSent, setOTPSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otpVerified, setOTPVerified] = useState(false);

  const error = () => {
    toast.error("This Number is already enrolled", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const validationSchema = yup.object({
    number: yup
      .string()
      .required("Mobile number is required")
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
    otp: yup
      .string()
      .required("OTP is required")
      .matches(/^\d{4,6}$/, "OTP must be 4-6 digits"),
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      number: "",
      otp: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!otpVerified || otpVerified.type !== "success") {
        toast.error("Please verify the OTP before proceeding");
        return;
      }
      setSelected("Plans");
    },
  });

  async function checkOTP() {
    try {
      const { data } = await verifyOtp(values.otp, values.number);
      if (data?.type === "success") {
        setOTPVerified(data);
      }
    } catch (error) {
      toast.error("Failed to verify OTP", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  async function sendOTP() {
    setIsSubmitting(true);
    const { data } = await generateOtpToBuyPlan(number);
    if (data) {
      setOTPSent(data.type);
      toast.success("OTP Sent Sucessfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Some Error Occoured, Please try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    const { data: val } = await getB2CCustomerDetailsForNumber(number);
    console.log("val: ", val.response);
    if (val?.response) {
      setHealth(val?.b2cCustomerMemberDetailsRequest);
      setFamilyInfo(val?.b2cCustomerMemberDetailsRequest);
    }
  }

  useEffect(() => {
    setIsSubmitting(false);
    setOTPSent(false);
    setOTPVerified(false);
  }, [number]);

  return (
    <Card sx={{ minHeight: "300px" }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            {" "}
            <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
              Enter Mobile Number
            </Typography>
            <div>
              <CssTextField
                fullWidth
                value={number}
                name="number"
                onChange={(e) => {
                  setNumber(e.target.value);
                  setFieldValue("number", e.target.value);
                }}
                onBlur={handleBlur}
                error={touched.number && Boolean(errors.number)}
                helperText={touched.number && errors.number}
                size="small"
                placeholder="Enter Mobile Number"
                variant="outlined"
                type="number"
              />
            </div>
            <div>
              <Typography variant="caption" sx={{ color: "green" }}>
                {otpSent === "success" && "OTP Sent Sucessfully"}
              </Typography>{" "}
            </div>
          </Grid>

          <Grid item xs={12} lg={3}>
            {" "}
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              sx={{
                width: "15em",
                backgroundColor: isSubmitting ? "#898989" : "#2d256c!important",
                fontSize: "12px",
                padding: "8px 15px",
                marginLeft: "10px",
                marginTop: "36px",
              }}
              onClick={sendOTP}
            >
              Send OTP
            </Button>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
              Enter OTP
            </Typography>
            <CssTextField
              fullWidth
              value={oTP}
              name="otp"
              onChange={(e) => {
                setOTP(e.target.value);
                setFieldValue("otp", e.target.value);
              }}
              size="small"
              placeholder="Enter OTP"
              onBlur={handleBlur}
              variant="outlined"
              type="number"
              error={touched.otp && Boolean(errors.otp)}
              helperText={touched.otp && errors.otp}
            />
            <div>
              <div>
                <Typography
                  variant="caption"
                  sx={{
                    color: otpVerified.type === "success" ? "green" : "red",
                  }}
                >
                  {otpVerified.message}
                </Typography>{" "}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Button
              variant="contained"
              sx={{
                width: "15em",
                backgroundColor: "#2d256c!important",
                fontSize: "12px",
                padding: "8px 15px",
                marginLeft: "10px",
                marginTop: "36px",
              }}
              onClick={checkOTP}
            >
              Check OTP
            </Button>
          </Grid>
        </Grid>

        <br />
        <br />
        <Button
          variant="contained"
          type="submit"
          sx={{
            width: "15em",
            backgroundColor: "#2d256c!important",
            fontSize: "12px",
            padding: "8px 15px",
            marginLeft: "10px",
          }}
        >
          Next
        </Button>
      </form>
    </Card>
  );
};

export default Verification;
