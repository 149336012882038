import React, { useContext } from "react";
import AddHomeIcon from "@mui/icons-material/AddHome";
import InventoryIcon from "@mui/icons-material/Inventory";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import "../styles/layout.scss";
import { Permissions } from "./Context/PermissionsContext";
import staffLogo from "../assets/staff.svg";
import appointments from "../assets/appointment.svg";
import customerListingLogo from "../assets/customer listing.svg";
import onBoardingReports from "../assets/onboarding report.svg";
import Plans from "../assets/Plans.svg";
import Billing from "../assets/Billing.svg";
import ClinicDetails from "../assets/Clinic.svg";
import AppointmentReq from "../assets/Appoint req.svg";
import pendingPayments from "../assets/pending payments.svg";
import plansAndCoupons from "../assets/plans and coupon.svg";
import myAppointments from "../assets/user calender.svg";
import BookYourTest from "../assets/bookTest.svg";
import BiotechIcon from "@mui/icons-material/Biotech";

const Leftbar = () => {
  const tokens = localStorage.getItem("token");
  const role = JSON.parse(tokens)?.role;
  const designation = JSON.parse(tokens)?.designation;
  const { permissionsData } = useContext(Permissions);

  const hasPermission = (module, action) => {
    const moduleData = permissionsData?.find((item) => item.module === module);
    if (!moduleData || !moduleData.permissions) return false;
    // console.log("moduleData", moduleData);
    return action
      ? moduleData.permissions.some(
          (perm) => perm.action === action && perm.permission
        )
      : moduleData.permissions.some((perm) => perm.permission);
  };

  // console.log("appointment", hasPermission("appointment"));

  const procedurePermission = hasPermission("procedure");
  const plansPermission = hasPermission("plans");
  const couponPermission = hasPermission("coupons");
  const menuItems = [
    {
      to:
        role === "Customer" ? "appointments/today" : "clinicappointments/today",
      icon: (
        <img
          src={myAppointments}
          alt="My Appointments"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "My Appointments",
      visible:
        hasPermission("appointment") &&
        (role === "Customer" || role === "Clinic"),
    },

    {
      to: "/user/bookYourTest",
      icon: (
        <img
          src={BookYourTest}
          alt="Book Your Test"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "Book Your Test",
      visible: hasPermission("appointment") && role === "Customer",
    },
    {
      to: "clinicList",
      icon: <AddHomeIcon sx={{ width: "13%", color: "#D83F87", mr: 1 }} />,
      label: "Clinic OnBoarding",
      visible:
        hasPermission("clinic") && role !== "Customer" && role !== "Clinic",
    },
    {
      to:
        designation === "Customer Support Executive"
          ? "cseAppointmentBooking"
          : designation === "Customer"
          ? "/user/newappointments"
          : "",
      icon: (
        <img
          src={appointments}
          alt="New Appointment"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "New Appointment",
      visible:
        hasPermission("appointment", "New Appointment") &&
        (designation === "Customer Support Executive" || role === "Customer"),
    },
    {
      to:
        role === "Customer"
          ? "/user/customerappointmentsRequest"
          : role === "Clinic"
          ? "/user/appointmentrequests"
          : "",
      icon: (
        <img
          src={AppointmentReq}
          alt="Appointment Requests"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label:
        role === "Clinic" ? "Appointment Requests" : "Requested Appointments",
      visible:
        hasPermission("appointment", "Request Appointment") &&
        (role === "Customer" || role === "Clinic"),
    },
    {
      to: "/user/testOrderHistory/upcoming",
      icon: <BiotechIcon sx={{ width: "13%", color: "#D83F87", mr: 1 }} />,
      label: "Test History",
      visible: role === "Customer",
    },
    {
      to: "/user/pendingPayment",
      icon: (
        <img
          src={pendingPayments}
          alt="Pending payments"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "Pending Payments",
      visible: role === "Customer",
    },
    {
      to: "/user/customerPlansAndCoupons",
      icon: (
        <img
          src={plansAndCoupons}
          alt="plans And coupons"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "My Plans & Coupons",
      visible: role === "Customer",
    },
    {
      to: "clinicsclinicview",
      icon: (
        <img
          src={ClinicDetails}
          alt="Clinic Details"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "Clinic Details",
      visible: hasPermission("clinic", "Clinic Details") && role === "Clinic",
    },

    {
      to: "onboardingReport",
      icon: (
        <img
          src={onBoardingReports}
          alt="Onboarding Reports"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "Onboarding Reports",
      visible: hasPermission("report"),
    },
    {
      to: "staffList",
      icon: (
        <img
          src={staffLogo}
          alt="Staff"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "Staff",
      visible: hasPermission("staff"),
    },
    {
      to: "packages",
      icon: <InventoryIcon sx={{ width: "13%", color: "#D83F87", mr: 1 }} />,
      label: "Packages",
      visible: role === "Admin",
    },
    {
      to: "cseAppointments",
      icon: (
        <img
          src={myAppointments}
          alt="Appointments"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "Appointments",
      visible:
        hasPermission("appointment") &&
        designation === "Customer Support Executive",
    },
    {
      to: "customerlisting",
      icon: (
        <img
          src={customerListingLogo}
          alt="Customer Listing"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "Customer Listing",
      visible: hasPermission("customer"),
    },
    {
      to: "retailPlanscustomerlisting",
      icon: (
        <img
          src={customerListingLogo}
          alt="Customer Listing"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "Retail Plans Customer List",
      visible: hasPermission("customer"),
    },
    {
      to: "/user/billingAndSettlementTable",
      icon: (
        <img
          src={Billing}
          alt="Billing and Settlement"
          style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
        />
      ),
      label: "Billing and Settlement",
      visible:
        hasPermission("billing") && (role === "Admin" || role === "Clinic"),
    },
  ];

  return (
    <>
      <div className="leftbar">
        {menuItems.map(
          ({ to, icon, label, visible }) =>
            visible && (
              <Link to={to} key={label}>
                <Box className="menu-items" sx={{ mt: "10px" }}>
                  {icon}
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    sx={{ color: "#2D256C" }}
                    fontSize="0.89rem"
                  >
                    {label}
                  </Typography>
                </Box>
              </Link>
            )
        )}

        {/* Plans & Procedure Accordion */}
        {procedurePermission && (
          <Accordion sx={{ border: "none", boxShadow: "none" }}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={<ExpandMoreIcon />}
              sx={{ padding: "0px", margin: "0px!important" }}
            >
              <img
                src={Plans}
                style={{ width: `13%`, color: "#D83F87", marginRight: 8 }}
                alt="planslogo"
              />
              <Typography
                sx={{
                  color: "#2D256C",
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                Plans & Procedure
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px 0px 0px 10px" }}>
              {procedurePermission && (
                <Link to="plansAndProcedure">
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <FiberManualRecordIcon
                      sx={{ height: `30%`, color: "#D83F87", fontSize: "1rem" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#2D256C",
                        fontWeight: 600,
                      }}
                    >
                      Speciality & Procedures
                    </Typography>
                  </Box>
                </Link>
              )}

              {plansPermission &&
                (role === "Admin" ||
                  designation === "Customer Support Executive") && (
                  <Link to="plansAndPricing">
                    <Box sx={{ display: "flex", gap: "5px", mt: "10px" }}>
                      <FiberManualRecordIcon
                        sx={{
                          height: `30%`,
                          color: "#D83F87",
                          fontSize: "1rem",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#2D256C",
                          fontWeight: 600,
                        }}
                      >
                        B2B Plans & Pricing
                      </Typography>
                    </Box>
                  </Link>
                )}

              {plansPermission &&
                (role === "Admin" ||
                  designation === "Customer Support Executive") && (
                  <Link to="retailPlansAndPricing">
                    <Box sx={{ display: "flex", gap: "5px", mt: "10px" }}>
                      <FiberManualRecordIcon
                        sx={{
                          height: `30%`,
                          color: "#D83F87",
                          fontSize: "1rem",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#2D256C",
                          fontWeight: 600,
                        }}
                      >
                        Retail Plans & Pricing
                      </Typography>
                    </Box>
                  </Link>
                )}

              {couponPermission &&
                (role === "Admin" ||
                  designation === "Customer Support Executive") && (
                  <Link to="coupons">
                    <Box sx={{ display: "flex", gap: "5px", mt: "10px" }}>
                      <FiberManualRecordIcon
                        sx={{
                          height: `30%`,
                          color: "#D83F87",
                          fontSize: "1rem",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#2D256C",
                          fontWeight: 600,
                        }}
                      >
                        Coupons
                      </Typography>
                    </Box>
                  </Link>
                )}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </>
  );
};

export default Leftbar;
