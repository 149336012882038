import {
  Autocomplete,
  Box,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Appbar from "./Appbar";
import login from "../assets/Herooo.png";
import logo from "../images/TronLogColor.png";
import { CssTextField } from "../styles/textFields";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  checkCustomersExsistance,
  generateLoginOtp,
  loginTron,
  verifyLoginOtp,
} from "./../services/login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Login = () => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));

  const success = () => {
    toast.success("Login Sucessfull, Please wait", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const successSent = () => {
    toast.success("OTP Sent, Please wait", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const errorNE = () => {
    toast.error(
      "Your mobile number isn't registered with us. Please check the number or consider purchasing a valid plan. Click `Buy Plan` to buy a plan.",
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
  };

  const [show, setShow] = useState(false);
  const initialValues = { username: "", password: "" };
  const validationSchema = yup.object({
    username: yup.string().required("This Field Is Required"),
    password: yup.string().required("This Field Is Required"),
  });

  const [value, setValue] = useState("Customer");
  const [number, setNumber] = useState();
  const [otp, setOtp] = useState();

  async function sendOTP() {
    const { data } = await checkCustomersExsistance(number);
    console.log("data: ", data);
    if (data) {
      const { data } = await generateLoginOtp(number);
      console.log("data: ", data);
      if (data.type === "success") {
        successSent();
      }
    } else {
      errorNE();
    }
  }

  async function verify() {
    const { data } = await verifyLoginOtp(number, otp);
    // console.log("data: ", data);
    // const data = {
    //   uniqueId: "TRCUSTOMER016",
    //   role: "Customer",
    //   token:
    //     "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ2ZWxtdXJ1Z2FuQHRvd25vLmluIiwiZXhwIjoxNzM0MTE1MzMwLCJpYXQiOjE3MzQwNjEzMzB9.HNQVTeyDSr7Gz7p-PWFwZIbanU2IwyKzCIiGYDgkeeDAeVI5Bj2j0_2WpMq4I3uOQsIqsyKe1E7AfbTcrvOgxA",
    //   username: "9948558343",
    //   designation: "Customer",
    //   email: null,
    // };
    if (data.token) {
      localStorage.setItem("token", JSON.stringify(data));
      window.location.href = "/user/appointments/today";
      success();
    } else {
      error();
    }
  }

  const submitValues = async (values) => {
    try {
      const { data } = await loginTron(values);
      console.log("data: ", data);
      if (data) {
        localStorage.setItem("token", JSON.stringify(data));

        if (JSON.parse(localStorage.getItem("token")) || []) {
          if (JSON.parse(localStorage.getItem("token")).role === "Admin") {
            window.location.href = "/user/clinicList";
            success();
          }
          if (JSON.parse(localStorage.getItem("token")).role === "Staff") {
            window.location.href = "/user/clinicList";
            success();
          }
          if (JSON.parse(localStorage.getItem("token")).role === "Customer") {
            window.location.href = "/user/appointments/today";
            success();
          }
          if (JSON.parse(localStorage.getItem("token")).role === "Clinic") {
            window.location.href = "/user/clinicappointments/today";
            success();
          }
        }
      }
    } catch (er) {
      error();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitValues(values);
    },
  });

  const handleClickShowPassword = () => {
    console.log("Here1");
    setShow(!show);
  };

  return (
    <Box className="logincontent">
      {token ? <Appbar /> : <Navbar />}
      <div className="login loginmainbody">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={0} md={7} sm={7}>
            <div className="login-left">
              <img src={login} alt="login"></img>
            </div>
          </Grid>
          <Grid item xs={12} md={5} sm={12}>
            <Card className="login-right">
              <img src={logo} alt="logo"></img>
              <Box className="formbox">
                <Typography variant="h4" className="text">
                  Login to tron.health
                </Typography>
                <Autocomplete
                  value={value}
                  disableClearable
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  options={["Employee", "Clinic", "Customer"]}
                  className="loginoptions"
                  renderInput={(params) => (
                    <CssTextField {...params} label="Login As" size="small" />
                  )}
                />
                {(value === "Employee" || value === "Clinic") && (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="subtext">Email</div>
                    <CssTextField
                      placeholder="Email"
                      size="small"
                      name="username"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      fullWidth
                      error={
                        formik.touched.username &&
                        Boolean(formik.errors.username)
                      }
                      helperText={
                        formik.touched.username && formik.errors.username
                      }
                    ></CssTextField>
                    <div className="subtext">Password</div>
                    <CssTextField
                      placeholder="Password"
                      size="small"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      type={show ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword()}
                              edge="end"
                            >
                              {!show ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    ></CssTextField>

                    <button type="submit" className="button">
                      Submit
                    </button>
                  </form>
                )}

                {value === "Customer" && (
                  <>
                    <div className="subtext">Mobile Number</div>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={8} sm={8}>
                        <CssTextField
                          placeholder="Mobile Number"
                          size="small"
                          type="number"
                          value={number}
                          onChange={(e) => {
                            setNumber(e.target.value);
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={4} sm={4}>
                        <button
                          onClick={() => {
                            sendOTP();
                          }}
                          className="otpbutton"
                        >
                          Send OTP
                        </button>
                      </Grid>
                    </Grid>

                    <div className="subtext">Enter OTP</div>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} sm={12}>
                        <CssTextField
                          placeholder="Enter OTP"
                          size="small"
                          type="number"
                          value={otp}
                          onChange={(e) => {
                            setOtp(e.target.value);
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <button
                          onClick={() => {
                            verify();
                          }}
                          className="button"
                        >
                          Login
                        </button>
                      </Grid>

                      {/* <Grid item xs={12} md={12} sm={12}>
                        <button
                          onClick={() => {
                            navigate("/enrollInPlan");
                          }}
                          className="button"
                        >
                      Buy A Plan                        </button>
                      </Grid> */}
                      <Grid item xs={12} md={12} sm={12}>
                        <Box>
                          <Typography>
                            Don't have a Plan?{" "}
                            <b
                              style={{ cursor: "pointer", color: "#2d256c" }}
                              onClick={() => {
                                navigate("/enrollInPlan");
                              }}
                            >
                              Buy Plan
                            </b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </Box>
  );
};

export default Login;
