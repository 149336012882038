import { Autocomplete, Button, Card, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { CssTextField } from "../../styles/textFields";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Compressor from "compressorjs";
import { useParams } from "react-router-dom";




const DoctorDetails = ({ values, changeValues, step, setStep,edit,setOpen,setImage }) => {

  const {id}=useParams()

  const compressFile=(value, index, e)=>{
    const file = e.target.files[0];
  
    if (!file) {
      return;
    }
  
    new Compressor(file, {
      quality: 0.4,
  
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        console.log(">>>>>>>",result);
        var reader = new FileReader();

        reader.readAsDataURL(result);
        reader.onloadend = function () {
        var base64String = reader.result;
        console.log('Base64 String - ',  JSON.stringify(base64String.substr(base64String.indexOf(', ') + 1)) );
      
        // Simply Print the Base64 Encoded String,
        // without additional data: Attributes.
      //   console.log('Base64 String without Tags- ', 
      //  base64String.substr(base64String.indexOf(', ') + 1));
      const data = [...values];
      data[index][value] = base64String;
      changeValues(data);
            }
      },
      error(err) {
        console.log(err.message);
      }
  })}
  const handleChange = (value, index, e) => {
    
    const data = [...values];
    data[index][value] = e;
    changeValues(data);
  };

  const [errors, setErrors] = useState([]);

  const handleNext = () => {
    const val = values.map((item) => {
      const err = {specialisationAndDegree:[]};
      const check = (title, val) => {
        if (!val.trim()) err[title] = "This is a Required Field";
      };
      check("name", item.name);
      check("accreditation", item.accreditation);
      check("experience", item.experience);
      // check("workingTime", item.workingTime);
      // check("givingDiscount", item.givingDiscount);
      if (!item.practiceSince) {
        err["practiceSince"] = "This Field is Required";
      }
      if (!item.image) {
        err["image"] = "This Field is Required";
      }
      item.specialisationAndDegree.map((val,i)=>{
        (!val.degree.trim())?
          err["specialisationAndDegree"][i]=true:
          err["specialisationAndDegree"][i]=false;
        
        return null
      })
      return err;
    });
    setErrors(val);
    

    const data=JSON.parse(JSON.stringify(val))
    const vals=data.map(item=>item.specialisationAndDegree)
    const value=vals.flat(1) //!Flattening the array
    
     for(let i=0; i<data.length;i++){

      delete data[i].specialisationAndDegree;
    }

    
    const foundErr=data.map(item=>{
      
      console.log(">>>>>>",JSON.stringify(item)==="{}");
      return JSON.stringify(item)==="{}"
    })
    console.log('foundErr: ',  foundErr.filter(elemetn=>elemetn===false).length);

    const found=value.filter(element=>element===true)
    console.log("???",Object.fromEntries(data))
    if (
       foundErr.filter(elemetn=>elemetn===false).length===0&&found.length===0
       ) {
          setStep(step + 1);
        }
        console.log('data: ', data);
  };
console.log(errors);
  return (
    <>
      {values.map((item, index) => (
        <Card sx={{ marginBottom: 2 }} key={index}>
          {values.length >= 2 && (
            <div className="leftsideicons">
              <DeleteSweepIcon
                sx={{
                  height: 30,
                  width: 30,
                  cursor: "pointer",
                }}
                onClick={() => {
                  const data = [...values];
                  const error = [...errors];
                  error.splice(index, 1);
                  data.splice(index, 1);
                  changeValues(data);
                  setErrors(error);
                }}
              />
            </div>
          )}
          <Grid container spacing={2}>
            <React.Fragment>
              <Grid item md={6} xs={12}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  Doctor's Name
                </Typography>
                <CssTextField
                  error={errors && errors[index]?.name}
                  
                  disabled={(id)&&(!edit)?true:false}
                  helperText={
                    errors && errors[index]?.name && "This Field is required"
                  }
                  size="small"
                  fullWidth
                  placeholder="Enter Doctor's Name"
                  variant="outlined"
                  type="text"
                  onChange={(e) => {
                    handleChange("name", index, e.currentTarget.value);
                  }}
                  value={item.name}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  Accreditation (If Any)
                </Typography>
                <Autocomplete
                  disableClearable
                  
                  disabled={(id)&&(!edit)?true:false}
                  options={["NABH", "NABL", "Others"]}
                  name="accreditation"
                  //   onChange={(event, value) => {
                  //     formik.setFieldValue(item.value, value);
                  //   }}
                  onChange={(event, newInputValue) => {
                    handleChange("accreditation", index, newInputValue);
                  }}
                  value={item.accreditation}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <CssTextField
                      error={errors && errors[index]?.accreditation}
                      helperText={
                        errors &&
                        errors[index]?.accreditation &&
                        "This Field is required"
                      }
                      {...params}
                      fullWidth
                      placeholder="Choose Accreditation"
                      variant="outlined"
                      type="text"
                    />
                  )}
                />
              </Grid>
              {item.specialisationAndDegree.map((vals, i) => (
                <React.Fragment key={i}>
                  <Grid item md={4} xs={12}>
                    <Typography
                      mt={1}
                      mb={1}
                      variant="subtitle2"
                      fontWeight="bold"
                    >
                      Doctor's Degree
                    </Typography>
                    <Autocomplete
                    
                    disabled={(id)&&(!edit)?true:false}
                      disableClearable
                      options={[
                        "MBBS",
                        "MD",
                        "Diploma",
                        "MS",
                        "BDS",
                        "MDS",
                        "BSc",
                        "MSc",
                        "MCH",
                        "DM",
                        "Fellowship",
                      ]}
                      name="degree"
                      onChange={(event, newInputValue) => {
                        const data = [...values];
                        data[index].specialisationAndDegree[i].degree = newInputValue;
                        changeValues(data);
                      }}
                      value={item.specialisationAndDegree[i].degree}
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <CssTextField
                          error={errors&&errors[index]?.specialisationAndDegree?.[i]}
                          helperText={errors&&errors[index]?.specialisationAndDegree?.[i] && "This Field is required"}

                          {...params}
                          fullWidth
                          placeholder="Doctor's Speicialisation"
                          variant="outlined"
                          type="text"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} xs={10}>
                    <Typography
                      mt={1}
                      mb={1}
                      variant="subtitle2"
                      fontWeight="bold"
                    >
                      Doctor's Specialisation
                    </Typography>
                    <Autocomplete
                    
                    disabled={(id)&&(!edit)?true:false}
                      disableClearable
                      options={[
                        "ENT",
                        "Ophthalmology",
                        "Gynecology",
                        "Gastroenterology",
                        "Nutrition",
                        "Other",
                      ]}
                      name="specialisation"
                      onChange={(event, newInputValue) => {
                        const data = [...values];
                        data[index].specialisationAndDegree[i].specialisation = newInputValue;
                        changeValues(data);
                      }}
                      value={item.specialisationAndDegree[i].specialisation}
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <CssTextField
                          //      error={errors&&errors[index]?.specialisation}
                          // helperText={errors&&errors[index]?.specialisation && "This Field is required"}

                          {...params}
                          fullWidth
                          placeholder="Doctor's Specialisation"
                          variant="outlined"
                          type="text"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} xs={10}>
                    <Typography
                      mt={1}
                      mb={1}
                      variant="subtitle2"
                      fontWeight="bold"
                    >
                     If Others, 
                    </Typography>
                    <CssTextField
                  size="small"
                  fullWidth
                  disabled={item.specialisationAndDegree[i].specialisation==="Other"?false:true}
                  
                  placeholder="Others"
                  variant="outlined"
                  type="text"
                  onChange={(e) => {
                    const data = [...values];
                    data[index].specialisationAndDegree[i].other = e.currentTarget.value;
                    changeValues(data);
                  }}
                  value={item.specialisationAndDegree[i].other}
                />
                  </Grid>
                  <Grid item md={0.5} xs={1}>
                    {item.specialisationAndDegree.length > 1 && (
                      <DeleteSweepIcon
                        className="icons"
                        onClick={() => {
                          const val = [...values];
                          val[index].specialisationAndDegree.splice(i, 1);
                          changeValues(val);
                        }}
                      />
                    )}
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item md={0.5} xs={1}>
                <div>
                {id && !edit ? <></>: <AddCircleIcon
                    className="icons"
                    onClick={() => {
                      const vals = [...values];
                      vals[index].specialisationAndDegree.push({
                        specialisation: "",
                        degree: "",
                        other: "",
                      });
                      changeValues(vals);
                    }}
                  />}
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  Years Of Experience
                </Typography>
                <CssTextField
                  error={errors && errors[index]?.experience}
                  
                  disabled={(id)&&(!edit)?true:false}
                  helperText={
                    errors &&
                    errors[index]?.experience &&
                    "This Field is required"
                  }
                  size="small"
                  fullWidth
                  placeholder="Enter Doctor's Name"
                  variant="outlined"
                  type="text"
                  onChange={(e) => {
                    handleChange("experience", index, e.currentTarget.value);
                  }}
                  value={item.experience}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  Practicing Since
                </Typography>
                <DatePicker
                  views={["year"]}
                  
                  disabled={(id)&&(!edit)?true:false}
                  name={item.value}
                  onChange={(newValue) => {
                    handleChange("practiceSince", index, newValue);
                  }}
           
                  value={item.practiceSince}
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <CssTextField
                      error={errors && errors[index]?.practiceSince}
                      helperText={
                        errors &&
                        errors[index]?.practiceSince &&
                        "This Field is required"
                      }
                      focused
                      {...params}
                      placeholder="Practicing Since"
                      size="small"
                      name={item.value}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
             
              <Grid item xs={12} md={6}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  Doctor's Image
                </Typography>
               { (id)&&(!edit)?
               <Button 
               Button variant="contained"
               sx={{color:"#fff","&:hover":{backgroundColor:"#2d256c!important"}, fontSize:"12px",padding:"8px 15px"}}
               onClick={()=>{


                 console.log('item.image: ', item.image);
                setOpen(true)
                setImage(values[index].image)
               }}>View Image</Button>:<Stack direction="row">
                  <CssTextField
                    error={errors && errors[index]?.image}
                    
                    disabled={(id)&&(!edit)?true:false}
                    helperText={
                      errors && errors[index]?.image && "This Field is required"
                    }
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Upload Image"
                    type="text"
                    value={item.image}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      borderRadius: "0px 8px 8px 0px",
                      color: "#fff",
                      boxShadow: "none",
                    }}
                    onChange={(e) => {
                      compressFile("image", index, e);
                      //   const val = [...value];
                      //   val[index].image = e.target.value;
                      //   onChange(val);
                    }}
                  >
                    Upload
                    <input
                      type="file"
                      name="picture"
                      accept="image/*,.pdf" 
                      capture="user"
                      hidden
                    />
                  </Button>
                </Stack>}
              </Grid>
            </React.Fragment>
          </Grid>
        </Card>
      ))}
      <div className="buttonNext">
        <Button
          variant="contained"
          onClick={() => {
            setStep(step - 1);
          }}
          sx={{ width: "15em", backgroundColor: "#282828 !important","&:hover":{backgroundColor:"#282828 !important"}, fontSize:"12px", padding:"8px 15px" }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          sx={{ width: "15em", marginLeft: 2,"&:hover":{backgroundColor:"#2d256c!important"}, fontSize:"12px",padding:"8px 15px" }}
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default DoctorDetails;
