import { Box, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import "../../styles/NewApoointment.scss";
import { Link, useNavigate } from "react-router-dom";
import AppointmentsSearchbar from "./AppointmentsSearchbar";
import clinicdemo from "../../assets/clinicdemopic.svg";
import kmimage from "../../assets/km'slogo.svg";
import config from "../../config.json";
import {
  getClinicByLatLong,
  getClinicByPincode,
} from "../../services/customerOnboarding";
import Loader from "../Loader";
import { getClinicSpecialities } from "../../services/PlansAndProcedure";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const pincodeSchema = yup.object().shape({
  pincode: yup
    .number()
    .positive()
    .integer()
    .min(100000, "Please Enter Correct Pincode")
    .max(999999, "Pincode is Too Long")
    .required("Required"),
});

const NewAppointment = () => {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const [manullyLocation, setManuallyLocation] = useState(false);
  const [clinicsSorted, setClinicsSorted] = useState([]);
  const [status, setstatus] = useState(null);
  const [entireclinicData, setEntireClinicData] = useState(false);
  const latlongvalues = useRef([]);
  const Pincode = useRef();
  const [pincode, setPincode] = useState("");
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const navigate = useNavigate();
  let locationstatus = useRef(false);
  const currentPage = useRef(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(allData.length / itemsPerPage);
  // const startIndex = (currentPage.current - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const paginatedItems = allData.slice(startIndex, endIndex);
  // console.log(locationstatus);
  console.log("currentItems", currentItems);
  // console.log("startIndex",startIndex)

  const getEntireData = async () => {
    try {
      if (latlongvalues.current) {
        setEntireClinicData(true);
        console.log("All clincs", allData);
        setCurrentItems([
          ...currentItems,
          ...allData.slice(currentItems.length, currentItems.length + 5),
        ]);
      }
      if (Pincode.current) {
        console.log("All clincs--------->", allData);
        setEntireClinicData(true);
        setCurrentItems([
          ...currentItems,
          ...allData.slice(currentItems.length, currentItems.length + 5),
        ]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getSpecialities = async () => {
    try {
      let response = await getClinicSpecialities();
      let data = response.data;
      console.log(data);
      setSpecialities(data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const onSubmit = async (values, actions) => {
    console.log(values, actions);
    locationstatus.current = true;
    console.log("locationstatus", locationstatus);
    if (values) {
      Pincode.current = values.pincode;
      setPincode(values.pincode);
      try {
        const { data } = await getClinicByPincode(values.pincode);
        if (data) {
          console.log("data: ", data);
          setClinicsSorted(data.slice(0, 2));
          setAllData(data);
          setCurrentItems([
            ...currentItems,
            ...allData.slice(currentItems.length, currentItems.length + 5),
          ]);
          setEntireClinicData(false);
          latlongvalues.current = null;
        }
      } catch (err) {
        console.log(err.message);
      }
      handleClose();
    }
  };

  const { values, touched, errors, handleSubmit, handleBlur, handleChange } =
    useFormik({
      initialValues: {
        pincode: "",
      },
      validationSchema: pincodeSchema,
      onSubmit,
    });
  console.log(errors);
  const token = JSON.parse(localStorage.getItem("token"));
  const username = token.username;

  // console.log(username);

  const getLocationDetails = async () => {
    //  ---------------geolocation by javascript---------------------

    if (!navigator.geolocation) {
      setstatus("GeoLocation is not supported by your browser");
      console.log(status);
    } else {
      setstatus("Locating.....");
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // console.log(position)
          locationstatus.current = true;
          setstatus(null);
          const Latitude = position.coords.latitude;
          const Longitude = position.coords.longitude;
          latlongvalues.current[0] = Latitude;
          latlongvalues.current[1] = Longitude;
          console.log("LatLong values", latlongvalues.current);
          try {
            const { data } = await getClinicByLatLong(
              latlongvalues.current[0],
              latlongvalues.current[1]
            );

            if (data) {
              console.log("data: ", data);
              setClinicsSorted(data.slice(0, 2));
              setAllData(data);
              Pincode.current = null;
            }
          } catch (error) {
            console.log(error.message);
          }
          handleClose();
        },
        () => {
          setstatus("Unable to retrive your location");
        }
      );
    }

    // -------------------------apicall for geolocation---------------------

    // await axios
    //   .get("https://geolocation-db.com/json/")
    //   .then((res) => {
    //     console.log("location Data", res.data);
    //     setLocationData(res.data)
    //     handleClose();
    //     if (res.data.IPv4 === "Not found") {
    //       axios
    //         .get("https://api.ipify.org?format=json")
    //         .then((res) => {
    //           console.log("location Data", res.data);
    //           setLocationData(res.data)
    //         })
    //         .catch((err) => {
    //           console.log("error", err.message);
    //         });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err", err.message);
    //   });
  };

  function scrollToEnd(e) {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    // alert(scrollHeight)
    if (scrollTop + clientHeight + 1 >= scrollHeight) {
      console.log("yyyyyyyyyyyyyy");
      if (currentItems.length === allData.length) return;
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        currentPage.current = currentPage.current + 1;
        setCurrentItems([
          ...currentItems,
          ...allData.slice(currentItems.length, currentItems.length + 5),
        ]);
      }, 1000);
      return;
    }
  }

  useEffect(() => {
    getSpecialities();
  }, []);

  //   useEffect(()=>{
  //     setCardData(clinicsSorted?.slice(0, 2));
  //  },[clinicsSorted])

  // console.log(">>>>>>>>", carddata, clinicsSorted);
  return (
    <div className="newappointmentsmainbox">
      {/* --------------------------------searchbarbox----------------------------- */}
      <AppointmentsSearchbar
        entireData={allData}
        setClinicsSorted={setClinicsSorted}
        setCurrentItems={setCurrentItems}
      />

      {/* ---------------------------ServiceCAtegory Box---------------------- */}

      {entireclinicData ? (
        <>
          <Box className="cliniclistmainbox">
            <Box className="cliniccontentbox" onScroll={scrollToEnd}>
              {currentItems?.map((el, id) => {
                return (
                  <Box className="carddata">
                    <Box className="clinicimage">
                      {el.imageUrl === null ? (
                        <img src={clinicdemo} alt="clinicpic" />
                      ) : (
                        <img
                          src={config.imageUrl + el.imageUrl}
                          alt="clinicpic"
                        />
                      )}
                    </Box>
                    <Box>
                      <Typography variant="h5">{el.clinicName}</Typography>
                      <Typography variant="h6">{el.clinicType}</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Box className="kmsbox">
                          {el.distance !== 0 ? (
                            <>
                              <img src={kmimage} alt="kmsimage" />
                              <br />
                              <Typography>
                                {Math.round(parseInt(el.distance))} Kms
                              </Typography>
                            </>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Typography className="address" variant="h6">
                          {el.address}
                        </Typography>
                      </Box>
                      <Box className="specialitybox">
                        {el?.clinicType?.split(",").map((item, index) => (
                          <Typography key={index}>{item}</Typography>
                        ))}
                      </Box>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/user/customerclinicView/${el.uniqueId}`);
                        }}
                      >
                        Book An Appointment
                      </button>
                    </Box>
                  </Box>
                );
              })}
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                ""
              )}
            </Box>

            {/* --------------------------All clinic list Mobile screen------------------------- */}

            <Box className="clinicmobilecontentbox" onScroll={scrollToEnd}>
              {currentItems?.map((el, id) => {
                return (
                  <Box
                    className="carddata"
                    onClick={() => {
                      navigate(`/user/customerclinicView/${el.uniqueId}`);
                    }}
                  >
                    <Box>
                      {el.imageUrl === null ? (
                        <img src={clinicdemo} alt="clinicpic" />
                      ) : (
                        <img
                          style={{ width: "350px", height: "200px" }}
                          src={config.imageUrl + el.imageUrl}
                          alt="clinicpic"
                        />
                      )}
                    </Box>
                    <Box>
                      <Typography variant="h5">{el.clinicName}</Typography>
                      <Typography variant="h6">{el.clinicType}</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Typography className="address" variant="h6">
                          {el.address}
                        </Typography>
                        <Box className="kmsbox">
                          {el.distance !== 0 ? (
                            <>
                              <img src={kmimage} alt="kmsimage" />
                              <br />
                              <Typography>
                                {Math.round(parseInt(el.distance))} Kms
                              </Typography>
                            </>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>
                      <Box className="specialitybox">
                        {el?.clinicType?.split(",").map((item, index) => (
                          <Typography key={index}>{item}</Typography>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box className="serviceCategorybox">
            <Typography variant="h6">Specialization</Typography>
            <Box className="services">
              {specialities.map((el, id) => {
                return (
                  <Box>
                    <Box className="squarebox">
                      <img
                        src={config.imageUrl + el.specialityImage}
                        alt="Eye"
                      />
                    </Box>
                    <Typography>{el.speciality}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>

          {/* --------------------------------Modal------------------------ */}
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            id="mobilemodal"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Hi {username}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 0, fontSize: "14px" }}
              >
                We’ll be needing your location to help you find best clinic near
                you.
              </Typography>
              {manullyLocation ? (
                <Box className="manualpincodeform">
                  <form onSubmit={handleSubmit}>
                    <TextField
                      className={
                        errors.pincode && touched.pincode ? "input-error" : ""
                      }
                      value={values.pincode}
                      onBlur={handleBlur}
                      id="pincode"
                      onChange={handleChange}
                      type="number"
                      size="small"
                      placeholder="Pincode"
                    />
                    <button type="submit">Submit</button>
                  </form>
                  {errors.pincode && touched.pincode && (
                    <span style={{ color: "red" }}>{errors.pincode}</span>
                  )}
                </Box>
              ) : (
                <Box className="modalbuttons">
                  <button onClick={getLocationDetails}>
                    Grant Location Access
                  </button>
                  <button onClick={() => setManuallyLocation(true)}>
                    Add Manually
                  </button>
                </Box>
              )}
            </Box>
          </Modal>

          {/* ----------------------------Sorted clinic List-------------------*/}
          {locationstatus.current ? (
            <Box className="cliniclistmainbox">
              <Box className="clinicheading">
                <Typography variant="h5" gutterBottom>
                  All Clinic
                </Typography>
                <Typography onClick={getEntireData}>See All</Typography>
              </Box>
              <Box className="cliniccontentbox">
                {clinicsSorted?.map((el, id) => {
                  return (
                    <Box className="carddata">
                      <Box>
                        {el.imageUrl === null ? (
                          <img src={clinicdemo} alt="clinicpic" />
                        ) : (
                          <img
                            style={{ width: "350px", height: "200px" }}
                            src={config.imageUrl + el.imageUrl}
                            alt="clinicpic"
                          />
                        )}
                      </Box>
                      <Box>
                        <Typography variant="h5">{el.clinicName}</Typography>
                        <Typography variant="h6">{el.clinicType}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Box className="kmsbox">
                            {el.distance !== 0 ? (
                              <>
                                <img src={kmimage} alt="kmsimage" />
                                <br />
                                <Typography>
                                  {Math.round(parseInt(el.distance))} Kms
                                </Typography>
                              </>
                            ) : (
                              ""
                            )}
                          </Box>
                          <Typography className="address" variant="h6">
                            {el.address}
                          </Typography>
                        </Box>
                        <Box className="specialitybox">
                          {el?.clinicType?.split(",").map((item, index) => (
                            <Typography key={index}>{item}</Typography>
                          ))}
                        </Box>
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/user/customerclinicView/${el.uniqueId}`);
                          }}
                        >
                          Book An Appointment
                        </button>
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              {/* -----------------------------Sorted clinic List Mobile content Box------------------ */}
              <Box className="clinicmobilecontentbox">
                {clinicsSorted?.map((el, id) => {
                  return (
                    <Box
                      className="carddata"
                      onClick={() => {
                        navigate(`/user/customerclinicView/${el.uniqueId}`);
                      }}
                    >
                      <Box>
                        {el.imageUrl === null ? (
                          <img src={clinicdemo} alt="clinicpic" />
                        ) : (
                          <img
                            style={{ width: "350px", height: "200px" }}
                            src={config.imageUrl + el.imageUrl}
                            alt="clinicpic"
                          />
                        )}
                      </Box>
                      <Box>
                        <Typography variant="h5">{el.clinicName}</Typography>
                        <Typography variant="h6">{el.clinicType}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Typography className="address" variant="h6">
                            {el.address}
                          </Typography>
                          <Box className="kmsbox">
                            {el.distance !== 0 ? (
                              <>
                                <img src={kmimage} alt="kmsimage" />
                                <br />
                                <Typography>
                                  {Math.round(parseInt(el.distance))} Kms
                                </Typography>
                              </>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Box>
                        <Box className="specialitybox">
                          {el?.clinicType?.split(",").map((item, index) => (
                            <Typography key={index}>{item}</Typography>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : (
            ""
          )}
        </>
      )}

      <Box className="customerfooter" sx={{ textAlign: "right" }}>
        <Typography>Finding it hard to book an appointment,</Typography>
        <Box>
          <Typography>
            connect with us at
            <a href={`tel:8040091997`}> +91 8040091997</a> |
            <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
            <a href={`mailto:support@tron.health`}> support@tron.health.</a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default NewAppointment;
