import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/UpComing.scss";
import {
  ClinicCancelledTable,
  PastTable,
  TodayTable,
  UpcomingTable,
} from "./UpcomingTable";
import NotFound from "../NotFound";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RefreshIcon from "@mui/icons-material/Refresh";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import {
  getCustomerCancelledReqWithDate,
  getCustomerPastAppointmentWithDate,
  getCustomerUpcomingReqWithDate,
} from "../../services/customerOnboarding";
// import "../../styles/Tables.scss";
import "../../styles/layout.scss";

const UpComing = () => {
  const navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("token"));
  let username = token?.username;
  let uniqueId = token?.uniqueId;
  const { dateRange } = useParams();
  // console.log(dateRange)
  const [value, setValue] = useState(
    dateRange === "upcoming"
      ? "upcoming"
      : dateRange === "today"
      ? "today"
      : dateRange === "past"
      ? "past"
      : dateRange === "cancelled"
      ? "cancelled"
      : dateRange === "requested"
      ? "requested"
      : "notfound"
  );
  console.log(value);
  const [date, setDate] = useState(new Date());
  const [newUpcomingSelectDate, setNewUpcomingSelectDate] = useState(null);
  const [newPastSelectDate, setNewPastSelectDate] = useState(null);
  const [newCancelSelectedDate, setNewCancelSelectedDate] = useState(null);
  const [upcomingData, setUpcomingData] = useState([]);
  const [pastData, setPastData] = useState([]);
  const [cancelledAppointments, setCancelledAppointments] = useState([]);

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  const getUpcomingRequstswithDate = async (date) => {
    let currentdate = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let entiredate = `${year}-${month > 9 ? month : "0" + month}-${
      currentdate > 9 ? currentdate : "0" + currentdate
    }`;
    console.log("entiredate..", entiredate);
    setNewUpcomingSelectDate(entiredate);
    if (entiredate) {
      try {
        let response = await getCustomerUpcomingReqWithDate(
          entiredate,
          username
        );
        let data = response.data;
        console.log("upcomingreq with data", data);
        setUpcomingData(data);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const getPastRequestWithDate = async (date) => {
    let currentdate = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let entiredate = `${year}-${month > 9 ? month : "0" + month}-${
      currentdate > 9 ? currentdate : "0" + currentdate
    }`;
    console.log("entiredate..", entiredate);
    setNewPastSelectDate(entiredate);
    if (entiredate) {
      try {
        let response = await getCustomerPastAppointmentWithDate(
          entiredate,
          uniqueId
        );
        let data = response.data;
        console.log("upcomingreq with data", data);
        setPastData(data);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const getCancelledRequestWithDate = async (date) => {
    let currentdate = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let entiredate = `${year}-${month > 9 ? month : "0" + month}-${
      currentdate > 9 ? currentdate : "0" + currentdate
    }`;
    console.log("entiredate..", entiredate);
    setNewCancelSelectedDate(entiredate);
    if (entiredate) {
      try {
        let response = await getCustomerCancelledReqWithDate(
          entiredate,
          username
        );
        let data = response.data;
        console.log("upcomingreq with data", data);
        setCancelledAppointments(data);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  useEffect(() => {
    navigate(`/user/appointments/${value}`);
  }, []);
  const validparams = ["today", "upcoming", "past", "cancelled", "requested"];
  if (!validparams.some((e) => dateRange === e)) return <NotFound />;

  const handleChange = (event, newValue) => {
    // console.log(newValue)
    navigate(`/user/appointments/${newValue}`);
    setValue(newValue);
  };

  return (
    <div>
      <div id="headingbox">
        <Typography variant="h6" gutterBottom>
          My Appointments{" "}
        </Typography>
        <div>
          <Box
            className="dateandrefreshbox"
            sx={{ display: "flex", gap: "8px" }}
          >
            {dateRange === "upcoming" ||
            dateRange === "past" ||
            dateRange === "cancelled" ? (
              <Button
                variant="outlined"
                id="refreshbtn"
                sx={{
                  backgroundColor: "#D83F87",
                  textAlign: "left",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-around",
                  "&:hover": {
                    backgroundColor: "#D83F87!important",
                    border: "none",
                  },
                }}
                size="small"
                onClick={() => {
                  if (dateRange === "upcoming") {
                    console.log("itemcalled");
                    setNewUpcomingSelectDate(null);
                  }
                  if (dateRange === "past") {
                    setNewPastSelectDate(null);
                  }
                  if (dateRange === "cancelled") {
                    setNewCancelSelectedDate(null);
                  }
                }}
              >
                <RefreshIcon id="refreshicon" style={{ color: "white" }} />{" "}
                Refresh
              </Button>
            ) : (
              ""
            )}
            {dateRange === "upcoming" ||
            dateRange === "past" ||
            dateRange === "cancelled" ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  value={date}
                  minDate={dateRange === "upcoming" ? new Date() : ""}
                  maxDate={dateRange === "past" ? new Date() : ""}
                  onChange={(date) => {
                    setDate(date);
                    if (dateRange === "upcoming") {
                      getUpcomingRequstswithDate(date);
                    } else if (dateRange === "past") {
                      getPastRequestWithDate(date);
                    } else if (dateRange === "cancelled") {
                      getCancelledRequestWithDate(date);
                    }
                  }}
                  renderInput={(params) => (
                    <button
                      {...params}
                      id="selectdate"
                      sx={{ backgroundColor: "#FFFFFF" }}
                      size="small"
                    >
                      {" "}
                      <CalendarMonthIcon
                        id="calendaricon"
                        style={{ color: "white" }}
                      />{" "}
                      Select Date
                    </button>
                  )}
                  onYearChange={onYearChangeHandler}
                  orientation={"portrait"}
                />
              </LocalizationProvider>
            ) : (
              ""
            )}
          </Box>
        </div>
      </div>
      <Box className="customertabsection" sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box>
            <Tabs onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                sx={
                  value === "today"
                    ? {
                        backgroundColor: "#2D256C",
                        width: "130px",
                        borderRadius: "5px 0px 0px 5px",
                        color: "white",
                        minHeight: "40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "white",
                        width: "130px",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "5px 0px 0px 5px",
                        color: "black",
                        minHeight: "40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                }
                label="Today"
                value="today"
              />
              <Tab
                sx={
                  value === "upcoming"
                    ? {
                        backgroundColor: "#2D256C",
                        width: "130px",
                        borderRadius: "0px",
                        color: "white",
                        minHeight: "40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "white",
                        width: "130px",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "0px",
                        color: "black",
                        minHeight: "40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                }
                label="UpComing"
                value="upcoming"
              />
              <Tab
                sx={
                  value === "past"
                    ? {
                        backgroundColor: "#2D256C",
                        width: "130px",
                        borderRadius: "0px 0px 0px 0px",
                        color: "white",
                        minHeight: "40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "white",
                        width: "130px",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "0px 0px 0px 0px",
                        color: "black",
                        minHeight: "40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                }
                label="Past"
                value="past"
              />
              <Tab
                sx={
                  value === "cancelled"
                    ? {
                        backgroundColor: "#2D256C",
                        width: "130px",
                        borderRadius: "0px 5px 5px 0px",
                        color: "white",
                        minHeight: "40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "white",
                        width: "130px",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "0px 5px 5px 0px",
                        color: "black",
                        minHeight: "40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                }
                label="Cancelled"
                value="cancelled"
              />
            </Tabs>
          </Box>
          <TabPanel value="today">
            <TodayTable />
          </TabPanel>
          <TabPanel value="upcoming">
            <UpcomingTable
              newUpcomingSelectDate={newUpcomingSelectDate}
              upcomingData={upcomingData}
              setUpcomingData={setUpcomingData}
            />
          </TabPanel>
          <TabPanel value="past">
            <PastTable
              pastData={pastData}
              setPastData={setPastData}
              newPastSelectDate={newPastSelectDate}
            />
          </TabPanel>
          <TabPanel value="cancelled">
            <ClinicCancelledTable
              newCancelSelectedDate={newCancelSelectedDate}
              cancelledAppointments={cancelledAppointments}
              setCancelledAppointments={setCancelledAppointments}
            />
          </TabPanel>
        </TabContext>
      </Box>

      {/* ---------------------------customer footer-------------------- */}

      <Box className="customerfooter" sx={{ textAlign: "right" }}>
        <Typography>Finding issue on your appointment,</Typography>
        <Box>
          <Typography>
            connect with us at
            <a href={`tel:8040091997`}> +91 8040091997</a> |
            <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
            <a href={`mailto:support@tron.health`}> support@tron.health.</a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default UpComing;
