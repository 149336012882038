import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { getCustomersExistingPLanList } from "../../services/login";

const Plans = ({
  setPlan,
  plan,
  setSelected,
  indivisualPlans,
  familyPlans,
  setPlanType,
  number,
}) => {
  const [validIndivisualPlans, setValidIndivisualPlans] = React.useState([]);
  const [validFamilyPlans, setValidFamilyPlans] = React.useState([]);

  React.useEffect(() => {
    const getCustomerPlans = async () => {
      const { data } = await getCustomersExistingPLanList(number);
      console.log("data: ", data);
      if (data) {
        const validIndivisualPlan = indivisualPlans?.filter(
          (item) => !data.includes(item?.uniqueId)
        );
        setValidIndivisualPlans(validIndivisualPlan);
        // console.log("validIndivisualPlans: ", validIndivisualPlans);
        const validFamilyPlan = familyPlans?.filter(
          (item) => !data.includes(item?.uniqueId)
        );
        setValidFamilyPlans(validFamilyPlan);
        // console.log("validFamilyPlans: ", validFamilyPlans);
      }
    };
    getCustomerPlans();
  }, []);
  console.log("valid individual plans", validIndivisualPlans);
  console.log("all individual plans list", indivisualPlans);

  return (
    <Card>
      <Typography variant="h5" fontWeight="bold" color="#D83F87">
        Individual Plans
      </Typography>

      <Grid container spacing={2}>
        {validIndivisualPlans.map((content, index) => (
          <Grid item xs={12} lg={4} key={index}>
            <div style={{ border: "2px solid #D3D3D3", borderRadius: "8px" }}>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" fontWeight="bold" color="#D83F87">
                  {content?.planName}
                </Typography>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#D83F87" }}
                  onClick={() => {
                    setPlan(content);
                    setPlanType("Indivisual");
                    setSelected("Family Info");
                  }}
                >
                  Buy
                </Button>
              </div>
              <Divider />
              <div style={{ padding: "10px" }}>
                <Typography variant="subtitle2">Price Per Person</Typography>
                {/* <span style={{ fontSize: "12px" }}>
                  <s>₹ {content.planDisplayPrice}</s>
                </span>
                <span
                  style={{
                    borderRadius: "26px",
                    backgroundColor: "#FFBEDD",
                    color: "#D83F87",
                    fontSize: "14px",
                    marginLeft: "10px",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Save {content.planDisplayDiscountPercent}%
                </span> */}

                {/* <br /> */}

                <span style={{ fontSize: "15px", fontWeight: "400" }}>
                  ₹{" "}
                  <span style={{ fontSize: "30px", fontWeight: "600" }}>
                    {content.planDiscountPrice}
                  </span>{" "}
                  /mon
                </span>

                <br />
                <br />
                <span style={{ fontWeight: "400", fontSize: "16px" }}>
                  Discount On Treatments :{" "}
                </span>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>
                  {content.discountPercent} %
                </span>
                <br />
                <span style={{ fontWeight: "400", fontSize: "16px" }}>
                  Treatments Cover :{" "}
                </span>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>
                  Rs. {content.treatmentCover}
                </span>
                <br />
                {/* <span style={{fontWeight:"400",fontSize:"16px"}}>Co Pay :</span><span style={{fontWeight:"600",fontSize:"16px"}}>{content.coPay} %</span><br/> */}
                <span style={{ fontWeight: "400", fontSize: "16px" }}>
                  Min Group Size :
                </span>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>
                  {content?.minimumSize}
                </span>
                <br />
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Typography variant="h5" fontWeight="bold" color="#D83F87" mt={4}>
        Family Plans
      </Typography>

      <Grid container spacing={2}>
        {validFamilyPlans.map((content, index) => (
          <Grid item xs={12} lg={4} key={index}>
            <div style={{ border: "2px solid #D3D3D3", borderRadius: "8px" }}>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" fontWeight="bold" color="#D83F87">
                  {content?.planName}
                </Typography>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#D83F87" }}
                  onClick={() => {
                    setPlan(content);
                    setPlanType("Family");
                    setSelected("Family Info");
                  }}
                >
                  Buy
                </Button>
              </div>
              <Divider />
              <div style={{ padding: "10px" }}>
                <Typography variant="subtitle2">Price Per Person</Typography>
                {/* <span style={{ fontSize: "12px" }}>
                  <s>₹ {content.planDisplayPrice}</s>
                </span>
                <span
                  style={{
                    borderRadius: "26px",
                    backgroundColor: "#FFBEDD",
                    color: "#D83F87",
                    fontSize: "14px",
                    marginLeft: "10px",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Save {content.planDisplayDiscountPercent}%
                </span> */}

                <span style={{ fontSize: "15px", fontWeight: "400" }}>
                  ₹{" "}
                  <span style={{ fontSize: "30px", fontWeight: "600" }}>
                    {content.planDiscountPrice}
                  </span>{" "}
                  /mon
                </span>

                <br />
                <br />
                <span style={{ fontWeight: "400", fontSize: "16px" }}>
                  Discount On Treatments :{" "}
                </span>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>
                  {content.discountPercent} %
                </span>
                <br />
                <span style={{ fontWeight: "400", fontSize: "16px" }}>
                  Treatments Cover :{" "}
                </span>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>
                  Rs. {content.treatmentCover}
                </span>
                <br />
                {/* <span style={{fontWeight:"400",fontSize:"16px"}}>Co Pay :</span><span style={{fontWeight:"600",fontSize:"16px"}}>{content.coPay} %</span><br/> */}
                <span style={{ fontWeight: "400", fontSize: "16px" }}>
                  Min Group Size :
                </span>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>4</span>
                <br />
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default Plans;
