import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import {
  Box,
  Card,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  CheckingExistingCustomer,
  bookAppointment,
  bookAppointmentOtp,
  clinicDetailsByuniqueId,
  getAvailableTimeSlots,
  getCustomerMembersForPLan,
  getSpecialityOptions,
  plansDropdownForAppointmentBooking,
  verifyBookAppointmentOtp,
} from "../../services/customerOnboarding";
import VerifiedIcon from "@mui/icons-material/Verified";
import { toast } from "react-toastify";

const phoneRegExp = /^[6-9]\d{9}$/;
const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    phonenumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    speciality: yup.string().notOneOf([""], "You must select an option!"),
    slot: yup.string().required("You must select an option!"),
    datevalid: yup.string().required("This Field is required"),
    time: yup.string().required("This Field Is Required"),
    plan: yup.string().required("This Field Is Required"),
  })
  .required();
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};
const BookAppointmentModal = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token.uniqueId;
  const designation = token.designation;
  const username = token.username;
  const [date, setDate] = useState(new Date());
  const [formvalues, setFormvalues] = useState(false);
  const [verifyNumber, setVerifyNumber] = useState(
    designation === "Customer" ? true : false
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verifySubmit, setVerifySubmit] = useState(false);
  const [bookAppointmentModalopen, setBookAppointmentModalOpen] =
    useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [existingCustomerId, setExistingCustomerId] = useState("");
  const [entireClinicDetails, setEntireClinicDetails] = useState([]);
  const [isOptionsFetched, setIsOptionsFetched] = useState(false);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [availableTime, setAvailableTime] = useState([]);
  const [formData, setFormData] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [otp, setOtp] = useState("");
  const [dailyBasis, setDailybasis] = useState("");
  const { id } = useParams();

  const navigate = useNavigate();

  let currentdate = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let entiredate = `${year}-${month > 9 ? month : "0" + month}-${
    currentdate > 9 ? currentdate : "0" + currentdate
  }`;

  const handleOpen = () => {
    setBookAppointmentModalOpen(true);
  };
  const handleClose = () => {
    setBookAppointmentModalOpen(false);
    setVerifyNumber(designation === "Customer" ? true : false);
    reset();
  };

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    // mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
    resolver: yupResolver(schema),
  });

  const success = () => {
    toast.success("Booking Appointment Sucessfull", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const successOTP = () => {
    toast.success("OTP sent,please check your registered mobile", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Please Check your server", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const errorOTP = () => {
    toast.error("Some Error Occoured, Please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const onSubmit = async (data) => {
    if (data) {
      console.log(data);

      function convertTimeFormat(time) {
        // Split the time range by "-"
        const [startTime, endTime] = time.split(" - ");

        // Convert start time
        const convertedStartTime = convertTo24HourFormat(startTime);

        // Convert end time
        const convertedEndTime = convertTo24HourFormat(endTime);

        // Return the converted time range
        return `${convertedStartTime} - ${convertedEndTime}`;
      }

      function convertTo24HourFormat(time) {
        const [hour, minute, period] = time.split(/:| /);

        // Convert hour to 24-hour format
        let convertedHour = parseInt(hour, 10);
        if (period === "PM" && convertedHour !== 12) {
          convertedHour += 12;
        } else if (period === "AM" && convertedHour === 12) {
          convertedHour = 0;
        }

        // Return the formatted time
        return `${padZero(convertedHour)}:${padZero(minute)}`;
      }

      function padZero(value) {
        return String(value).padStart(2, "0");
      }

      const formdata = {
        member: data.name,
        // patientName: data.name.memberName,
        patientNumber: designation === "Customer" ? username : data.phonenumber,
        clinicUniqueId: id,
        specialty: data.speciality,
        slot: data.slot,
        customerUniqueId:
          designation === "Customer" ? uniqueId : existingCustomerId,
        time: convertTimeFormat(data.time),
        date: entiredate,
        remark: data.remarks,
        planName: data.plan,
        createdBy: token.username,
      };
      console.log(formdata);
      verifyAndBook(formdata);
      // try {
      //   setIsSubmitting(true)
      //   const { data: response } = await bookAppointmentOtp(data.phonenumber);
      //   // console.log('response: ', response);
      //   if (response.type === "success") {
      //     setFormData(formdata);
      //     setFormvalues(true);
      //     successOTP();
      //   }else{
      //     setIsSubmitting(false)
      //   }
      // } catch (err) {
      //   console.log(err.message);
      //   error();
      //   setIsSubmitting(false)
      // }
      // setFormvalues(true);
    }
  };

  const getEntireclinicdetailsbyId = async () => {
    try {
      const { data } = await clinicDetailsByuniqueId(id);
      setEntireClinicDetails(data);
      // setEntireData([...Data, data]);
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchOptions = async () => {
    try {
      const response = await getSpecialityOptions(id);
      // console.log("response", response);
      let data = response.data;
      setSpecialityOptions(data);
      // console.log(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getTimeslots = async (dailyBasis) => {
    try {
      const response = await getAvailableTimeSlots(
        id,
        watch("speciality"),
        entiredate,
        watch("slot")
      );
      let data = response.data;
      if (data.length === 0) {
        toast.info(
          "Timings not available Please Choose another Date or Check by changing slot",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
      const modifiedTimings = data.map((timing) => {
        const parts = timing.split(" - ");
        const startTime = parts[0];
        const endTime = parts[1];

        // Converting start time to 12-hour format
        const start = new Date(`2000-01-01T${startTime}`);
        const startHours = start.getHours();
        const startMinutes = start.getMinutes();
        const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
          .toString()
          .padStart(2, "0")}`;

        // Converting end time to 12-hour format
        const end = new Date(`2000-01-01T${endTime}`);
        const endHours = end.getHours();
        const endMinutes = end.getMinutes();
        const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
          .toString()
          .padStart(2, "0")}`;

        // Determine AM/PM
        const amPm =
          startHours < 12 && watch("slot") === "morning" ? "AM" : "PM";

        return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
      });
      // console.log("modifiedtimings", modifiedTimings);
      if (data) {
        await setAvailableTime(modifiedTimings);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // console.log('example');
  const handleMobileNumberChange = (e) => {
    // console.log("300 line ", e.target.value);
    if (e.target.value) {
      setVerifyNumber(false);
      setMobileNumber(e.target.value);
    }
    // console.log("watch--2", watch("phonenumber"));
  };
  // console.log("watch--1", watch("phonenumber"));

  const handleExistingCustomer = async () => {
    try {
      const res = await CheckingExistingCustomer(mobileNumber);
      const data = res.data;
      if (data?.uniqueId) {
        // console.log("uniqueid",data.uniqueId);
        setVerifyNumber(true);
        setExistingCustomerId(data.uniqueId);
      } else {
        toast.info("Please Enter Your Registered Number", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.log(error.message);
      toast.error(
        "Please enter number and verify or Something Error in Server",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };

  async function verifyAndBook(formData) {
    setVerifySubmit(true);
    // try {
    //   const { data } = await verifyBookAppointmentOtp(
    //     otp,
    //     formData.patientNumber
    //   );
    //   // console.log('data: ', );

    //   if (data.type === "success") {
    console.log("formdata values", formData);
    try {
      const res = await bookAppointment(formData);
      if (res) {
        success();
        if (token.role === "Customer") {
          navigate("/user/customerappointmentsRequest");
        }
        if (token.designation === "Customer Support Executive") {
          navigate("/user/cseAppointments");
        }
      }
    } catch (err) {
      console.log(err.message);
      error();
    }
    //   } else {
    //     errorOTP();
    //   }
    // } catch (er) {
    //   console.log("er: ", er);
    //   errorOTP();
    //   setVerifySubmit(false)
    // }
  }

  useEffect(() => {
    getEntireclinicdetailsbyId();
  }, []);

  useEffect(() => {
    console.log("speciality", watch("speciality"));
    console.log("speciality", token.uniqueId);
    const getPlans = async () => {
      if (watch("speciality")) {
        const { data } = await plansDropdownForAppointmentBooking(
          watch("speciality"),
          designation === "Customer" ? uniqueId : existingCustomerId
        );
        if (data) {
          setAllPlans(data);
          setValue("plan", data[0]?.planUniqueId);
        }
      }
    };
    getPlans();
  }, [watch("speciality")]);

  useEffect(() => {
    console.log("speciality", watch("plan"));
    console.log("speciality", token.uniqueId);
    const getMembers = async () => {
      const { data } = await getCustomerMembersForPLan(
        watch("plan"),
        designation === "Customer" ? uniqueId : existingCustomerId
      );
      if (data) {
        setAllMembers(data);
        setValue("name", data[0]?.memberId);
      }
    };
    if (watch("plan")) {
      getMembers();
    }
  }, [watch("plan")]);

  useEffect(() => {
    if (watch("slot") && date) {
      getTimeslots(watch("slot"));
    }
  }, [watch("slot"), date]);

  return (
    <div>
      <button onClick={handleOpen}>Book Appointment</button>
      <Modal
        open={bookAppointmentModalopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id="bookappointmentmodalform"
      >
        <Box sx={style}>
          <Box className="clinicformbox">
            {/* {formvalues ? (
              <Box className="otpformbox">
                <Typography variant="h5">Appointment Verification</Typography>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel className="formlabel">
                    Please fill the OTP received on your number.
                  </FormLabel>
                  <TextField
                    className="inputbox"
                    size="small"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="OTP"
                    sx={{ width: "100%", mt: "10px" }}
                  />
                  <button
                  disabled={verifySubmit}
                    className="submitbtn"
                    style={{ width: "100%",backgroundColor:verifySubmit?"#898989":"" }}
                    // type="submit"
                    onClick={() => {
                      verifyAndBook();
                    }}
                  >
                    Verify
                  </button>
                  <button
                    className="cancelbtn"
                    style={{ width: "100%" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </FormControl>
              </Box>
            ) : ( */}
            <Box className="bookappointmentform">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Appointment Request
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* <FormControl sx={{ width: "90%" }}>
                    <FormLabel className="formlabel">Patient Name</FormLabel>
                    <TextField
                      id="inputbox"
                      size="small"
                      placeholder="Name"
                      {...register("name")}
                    />
                    <span className="error">{errors.name?.message}</span>
                  </FormControl> */}
                {designation !== "Customer" && (
                  <FormControl sx={{ width: "90%" }}>
                    <FormLabel className="formlabel">Mobile Number</FormLabel>
                    {/* <TextField
                        id="inputbox"
                        size="small"
                        {...register("phonenumber")}
                        onChange={(e)=>{
                          console.log(e.target.value);
                          handleMobileNumberChange(e)
                        }}
                        placeholder="Mobile Number"
                      /> */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <OutlinedInput
                        // className="inputbox"
                        size="small"
                        {...register("phonenumber")}
                        sx={{ paddingRight: "0px", height: "35px" }}
                        fullwidth
                        placeholder="Mobile Number"
                        onChange={(e) => {
                          console.log("phonenumber", e.target.value);
                          handleMobileNumberChange(e);
                        }}
                        endAdornment={
                          !verifyNumber && (
                            <InputAdornment
                              position="end"
                              onClick={handleExistingCustomer}
                            >
                              <span
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  color: "#2d256c",
                                  backgroundColor: "#f5f3f7",
                                  padding: "5px",
                                }}
                              >
                                Verify
                              </span>
                            </InputAdornment>
                          )
                        }
                      />
                      {verifyNumber && <VerifiedIcon sx={{ color: "green" }} />}
                    </Box>
                    <span className="error">{errors.phonenumber?.message}</span>
                  </FormControl>
                )}

                <FormControl size="small" sx={{ width: "90%" }}>
                  <FormLabel className="formlabel">Specialty</FormLabel>
                  <Select
                    {...register("speciality")}
                    placeholder="Select"
                    size="small"
                    className="inputbox"
                    onFocus={() => {
                      if (!isOptionsFetched) {
                        fetchOptions();
                      }
                    }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  >
                    {specialityOptions.map((el, id) => {
                      return (
                        <MenuItem key={id} value={el}>
                          {el}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <span className="error">{errors.speciality?.message}</span>
                </FormControl>

                <FormControl sx={{ width: "90%" }}>
                  <FormLabel className="formlabel">Plan</FormLabel>
                  <Select
                    // value={age}
                    value={watch("plan") || ""}
                    {...register("plan")}
                    placeholder="Select"
                    size="small"
                    className="inputbox"
                  >
                    {allPlans?.map((el, id) => {
                      return (
                        <MenuItem key={id} value={el.planUniqueId}>
                          {el?.plan}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <span className="error">{errors.plan?.message}</span>
                </FormControl>

                <FormControl sx={{ width: "90%" }}>
                  <FormLabel className="formlabel">Member Name</FormLabel>
                  <Select
                    // value={age}
                    value={watch("name") || ""}
                    {...register("name")}
                    placeholder="Select"
                    size="small"
                    className="inputbox"
                  >
                    {allMembers?.map((el, id) => {
                      return (
                        <MenuItem key={id} value={el.memberId}>
                          {el?.memberName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <span className="error">{errors.name?.message}</span>
                </FormControl>

                <FormControl sx={{ width: "90%" }}>
                  <FormLabel className="formlabel">Select Date</FormLabel>
                  {/* <TextField {...register("datevalid",{valueAsDate: true})} className="inputbox" type="date" size="small" /> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      value={date}
                      onChange={(date) => {
                        setDate(date);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="inputbox"
                          size="small"
                          {...register("datevalid", { valueAsDate: true })}
                          sx={{
                            backgroundColor: "#FFFFFF",
                            fontSize: "12px",
                          }}
                        />
                      )}
                      onYearChange={onYearChangeHandler}
                      orientation={"portrait"}
                    />
                  </LocalizationProvider>
                  <span className="error">{errors.datevalid?.message}</span>
                </FormControl>

                <FormControl sx={{ width: "90%" }}>
                  <FormLabel className="formlabel">Select Shift</FormLabel>
                  <Select
                    placeholder="Select"
                    size="small"
                    {...register("slot")}
                    // onChange={(e) => {
                    //   // e.target.blur()
                    //   getTimeslots(e.target.value);
                    //   setDailybasis(e.target.value);
                    //   if (e.target.value) {
                    //     errors.slot.message = null;
                    //   }
                    // }}
                    className="inputbox"
                    // defaultValue="morning"
                    value={watch("slot") || ""}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  >
                    {[
                      { name: "Morning", value: "morning" },
                      { name: "Evening", value: "evening" },
                    ].map((el, i) => {
                      return (
                        <MenuItem value={`${el.value}`}>{el.name}</MenuItem>
                      );
                    })}
                  </Select>
                  {errors.slot && (
                    <span className="error">{errors.slot?.message}</span>
                  )}
                  {/* {dailyBasis && availableTime.length===0 ? <span style={{color:"red",fontSize:"12px"}}>Timings not available Please Choose another Date or Check by changing slot</span>:""} */}
                </FormControl>
                <FormControl sx={{ width: "90%" }}>
                  <FormLabel className="formlabel">Select Time</FormLabel>
                  <Select
                    {...register("time")}
                    placeholder="Select"
                    size="small"
                    className="inputbox"
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  >
                    {availableTime.map((el, id) => {
                      return (
                        <MenuItem key={id} value={el}>
                          {el}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <span className="error">{errors.time?.message}</span>
                </FormControl>

                <FormControl sx={{ width: "90%" }}>
                  <FormLabel className="formlabel">Remarks</FormLabel>
                  <TextField
                    id="inputbox"
                    {...register("remarks")}
                    size="small"
                    placeholder="Please write the remarks here"
                  />
                </FormControl>
                <button
                  type="submit"
                  disabled={!verifyNumber || isSubmitting}
                  style={{
                    cursor: isSubmitting
                      ? "not-allowed"
                      : verifyNumber
                      ? "pointer"
                      : "default",
                    backgroundColor: verifyNumber ? "" : "#989898",
                  }}
                >
                  Send Appointment Request
                </button>
                <button
                  onClick={() => setVerifyNumber(false)}
                  className="resetbtn"
                  type="reset"
                >
                  Reset
                </button>
              </form>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BookAppointmentModal;
