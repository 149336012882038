import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  activateDeactivateRetailCustomer,
  activateOrDeactivatePlan,
  getRetailPlanAndPricingCustomerList,
} from "../../services/PlansAndProcedure";
import { toast } from "react-toastify";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import XLSX from "xlsx";
import Loader from "../Loader";
import moment from "moment";

const columns = [
  { id: "tronId", label: "Tron ID", minWidth: 50 },
  { id: "customername", label: "Customer Name", minWidth: 100 },
  {
    id: "number",
    label: "Number",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "pincode",
    label: "Pincode",
    minWidth: 80,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "enrolleddate",
    label: "Enrolled Date",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "plan",
    label: "Plan",
    minWidth: 80,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "members",
    label: "Members",
    minWidth: 60,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const RetailPlanCustomerListing = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [retailplansCustomerData, setRetailPlansCustomerData] = useState([]);
  const [acceptReject, setAcceptReject] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [rejectopen, setRejectOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [itemId, setItemid] = useState("");
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  const [loader, setLoader] = useState(true);
  const uniqueId = token?.uniqueId;
  const username = token?.username;
  const { permissionsData, loading, setLoading } = useContext(Permissions);
  const [activeInactiveStatus, setActiveInactiveStatus] = useState();

  let plansPermissionsModule = permissionsData?.filter(
    (item) => item.module === "plans"
  );
  let planPermissions = plansPermissionsModule[0]?.permissions;
  let PlanHistoryPermission = planPermissions?.find(
    (item) => item.action === "Plan History"
  )?.permission;
  let DeactivatePermission = planPermissions?.find(
    (item) => item.action === "Deactivate"
  )?.permission;
  let EditPermission = planPermissions?.find(
    (item) => item.action === "Edit"
  )?.permission;
  let ListPermission = planPermissions?.find(
    (item) => item.action === "List"
  )?.permission;

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRejectdialogOpen = (item) => {
    console.log("deactivate id", item.id);
    setRejectOpen(true);
    setItemid(item.id);
    setActiveInactiveStatus(item.status);
  };
  const handleRejectDialogClose = () => {
    setRejectOpen(false);
  };

  // function formatCreatedAt(dateString) {
  //   // const options = { year: 'numeric', month: 'short', day: 'numeric' };
  //   const date = new Date(dateString);
  //   // const formattedDate = date.toLocaleDateString('en-US', options);
  //   // return formattedDate.replace(/(\d+)(st|nd|rd|th)/, '$1<sup>$2</sup>');
  //   const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
  //   const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  //   // console.log("day",day);
  //   const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  //   let daySuffix = 'th';
  //   if (day.length === 2 && day[0] === '1') {
  //       daySuffix = 'th';
  //   } else {
  //       switch (day % 10) {
  //           case 1:
  //               daySuffix = 'st';
  //               break;
  //           case 2:
  //               daySuffix = 'nd';
  //               break;
  //           case 3:
  //               daySuffix = 'rd';
  //               break;
  //           default:
  //               daySuffix = 'th';
  //               break;
  //       }
  //   }

  //   return `${day}${daySuffix} ${month}, ${year}`;
  //   // return `${day}${['th', 'st', 'nd', 'rd'][day % 10] || 'th'} ${month}, ${year}`;
  // }

  const formatCreatedAt = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("MMMM Do, YYYY");
    return formattedDob;
  };

  const getTheRetailCustomerList = async () => {
    try {
      setLoading(true);
      const res = await getRetailPlanAndPricingCustomerList();
      console.log("original data", res.data);
      const retailplandata = res.data.map((plan) => ({
        ...plan,
        createdAt: formatCreatedAt(plan.createdAt),
      }));
      setRetailPlansCustomerData(retailplandata);
      setLoading(false);
      // console.log("retailplandata",retailplandata)
      // setTimeout(() => {
      //   if (res && retailplandata) {
      //     setRetailPlansCustomerData(retailplandata);
      //     setLoader(false);
      //     setLoading(false);
      //   } else {
      //     setLoader(false);
      //     setLoading(false);
      //     console.log("No data found");
      //   }
      // }, 1000);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDownloadExcel = () => {
    const customHeaders = [
      "Tron ID",
      "Customer Name",
      "Number",
      "Pincode",
      "Enrolled Date",
      "Plan",
      "Members",
    ];
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      customHeaders,
      ...retailplansCustomerData.map((item) => [
        item.uniqueId,
        item.name,
        item.number,
        item.pincode,
        item.createdAt,
        item.planUniqueId,
        item.noOfMembers,
      ]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "RetailCustomers");
    XLSX.writeFile(workbook, "RetailCustomers.xlsx");
  };

  const handleRemoveCustomer = async (id) => {
    try {
      setAcceptReject(true);
      const res = await activateDeactivateRetailCustomer(id);
      // console.log(res.data);
      if (res.data === "Activated.") {
        toast.success("Sucessfully Activated the Customer", {
          position: "top-right",
        });
      } else {
        toast.success("Sucessfully Deactivated the Customer", {
          position: "top-right",
        });
      }
      setAcceptReject(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getTheRetailCustomerList();
  }, [acceptReject]);
  return (
    <div>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" fontSize="17px">
            Retail Plan Customer List
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                value={selectedDate}
                onChange={(date) => {
                  // console.log(date);
                  setSelectedDate(date)
                }}
                renderInput={(params) => (
                  <button
                    {...params}
                    id="selectdate"
                    style={{ backgroundColor: "#FFFFFF", borderRadius: "3px",border:"1px solid #d9d9d9" }}
                    size="small"
                  >
                    {" "}
                    <CalendarMonthIcon
                      id="calendaricon"
                      style={{ color: "#d83f87" }}
                    />{" "}
                  </button>
                )}
                onYearChange={onYearChangeHandler}
                orientation={"portrait"}
              />
            </LocalizationProvider> */}
            <button
              onClick={handleDownloadExcel}
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "3px",
                border: "1px solid #d9d9d9",
              }}
            >
              <SimCardDownloadIcon style={{ color: "#d83f87" }} />
            </button>

            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 250,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search google maps" }}
                value={searchValue}
                onChange={(e) => {
                  // console.log(e.target.value)
                  setSearchValue(e.target.value);
                }}
              />
              <IconButton type="button" sx={{ p: "5px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
        </Box>

        {/* -------------------Table--------------- */}
        {loading ? (
          <Loader />
        ) : (
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              mt: "10px",
              backgroundColor: "#f7f7fc",
            }}
            className="scrollbar"
          >
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#2D256C",
                          fontWeight: 600,
                          color: "#FFFFFF",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* {loader && (
                <>
                  <Loader />
                </>
              )} */}
                {/* {!loader &&
                  Object.keys(retailplansCustomerData).length === 0 && (
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      No data found
                    </Typography>
                  )} */}
                {ListPermission &&
                  Object.keys(retailplansCustomerData).length > 0 && (
                    <TableBody>
                      {retailplansCustomerData
                        .filter((item) => {
                          const searchTerm = searchValue.toLowerCase();
                          return (
                            item.uniqueId.toLowerCase().includes(searchTerm) ||
                            item.name.toLowerCase().includes(searchTerm) ||
                            item.number.includes(searchTerm) ||
                            item.pincode.toString().includes(searchTerm) ||
                            item.planUniqueId.toLowerCase().includes(searchTerm)
                          );
                        })
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, id) => {
                          return (
                            <TableRow
                              sx={{
                                cursor: "pointer",
                                backgroundColor: item.status
                                  ? "#FFFFFF"
                                  : "#e7e7e7",
                              }}
                              key={item.id}
                              tabIndex={-1}
                            >
                              <TableCell
                                align="left"
                                sx={{ color: "#444444", fontSize: "14px" }}
                              >
                                {item.uniqueId}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ color: "#444444", fontSize: "14px" }}
                              >
                                {item.name}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ color: "#444444", fontSize: "14px" }}
                              >
                                {item.number}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ color: "#444444", fontSize: "14px" }}
                              >
                                {item.pincode}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ color: "#444444", fontSize: "14px" }}
                              >
                                {item.createdAt}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ color: "#444444", fontSize: "14px" }}
                              >
                                {item.planUniqueId}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ color: "#444444", fontSize: "14px" }}
                              >
                                {item.noOfMembers}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ color: "#D83F87" }}
                              >
                                <VisibilityIcon
                                  onClick={() =>
                                    navigate(
                                      `/user/viewRetailCustomerdetails/${item.id}`
                                    )
                                  }
                                  sx={{ color: "#2d256c" }}
                                />
                                {/* {EditPermission && (
                                  <EditIcon
                                    sx={{ color: "#2d256c" }}
                                    onClick={() => {
                                      //   navigate(`/user/editplan/${item.id}`);
                                    }}
                                  />
                                )}{" "} */}
                                {DeactivatePermission &&
                                  (item.status ? (
                                    <DoDisturbIcon
                                      sx={{ color: "#2d256c" }}
                                      onClick={() => {
                                        handleRejectdialogOpen(item);
                                      }}
                                    />
                                  ) : (
                                    <CheckCircleIcon
                                      sx={{ color: "green" }}
                                      onClick={() => {
                                        handleRejectdialogOpen(item);
                                      }}
                                    />
                                  ))}{" "}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={retailplansCustomerData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ backgroundColor: "#FFFFFF" }}
            />
          </Paper>
        )}
      </Box>
      {/* -------------------------Dialog box for Dectivate plan---------------------- */}
      <Dialog
        open={rejectopen}
        onClose={handleRejectDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are You Sure To {activeInactiveStatus ? "Deactive" : "Active"} this
          Customer
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              handleRemoveCustomer(itemId);
              handleRejectDialogClose();
            }}
            autoFocus
            sx={{
              backgroundColor: "#D83F87",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#D83F87!important" },
            }}
          >
            Yes
          </Button>
          <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            onClick={handleRejectDialogClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RetailPlanCustomerListing;
