import React, { useState, useRef, useMemo } from "react";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import {
  Button,
  Card,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useFormik } from "formik";
import * as yup from "yup";
import { postB2cCustomerAddresses } from "../../../services/HealthiansApi";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const libraries = ["places"];

const GoogleMapsSearchBar = ({
  locationDetails,
  setAddAddress,
  getCustomerAddresses,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBEPIxaUWpcJwnUbeBkuEV5ct0u11YhyMo",
    libraries: libraries,
  });
  const mapOptions = useMemo(
    () => ({
      disableDefaultUI: false,
      zoomControl: true,
    }),
    []
  );

  const defaultCenter = useMemo(
    () => ({
      lat: 28.7041,
      lng: 77.1025,
    }),
    [locationDetails]
  );
  const token = JSON.parse(localStorage.getItem("token"));
  const autoCompleteRef = useRef(null);
  const mapRef = useRef(null);
  const geocoderRef = useRef(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [markerPosition, setMarkerPosition] = useState(defaultCenter);
  const [showMarker, setShowMarker] = useState({
    latitude: "",
    longitude: "",
    address: "",
    pincode: "",
    state: "",
    sub_locality: "",
    stateId: 0,
    cityId: "",
  });
  const [searchAddress, setSearchAddress] = useState("");
  const [selectedButton, setSelectedButton] = useState("");
  const [showOtherField, setShowOtherField] = useState(false);

  const addressSchema = yup
    .object({
      address1: yup.string(),
      houseNo: yup.string().required("HouseNo is required"),
      landmark: yup.string().required("landmark is required"),
      addressUseAs: yup.string().required("Address use as is required"),
    })
    .required();

  const onSubmit = async (values, actions) => {
    console.log("values", values);
    const body = {
      customerUniqueId: token.uniqueId,
      state: showMarker.state,
      landmark: values.landmark,
      zipcode: showMarker.pincode,
      address:
        showMarker.address + ", " + values.houseNo + ", " + values.address1,
      sub_locality: showMarker.sub_locality,
      latitude: showMarker.latitude.toString(),
      longitude: showMarker.longitude.toString(),
      cityId: showMarker.cityId,
      status: true,
      type: values.addressUseAs,
    };
    console.log("submit  body", body);

    try {
      const res = await postB2cCustomerAddresses(body);
      if (res.data) {
        resetForm();
        setSearchAddress("");
        setMarkerPosition(defaultCenter);

        if (autoCompleteRef.current) {
          autoCompleteRef.current.value = "";
        }
        getCustomerAddresses();
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setAddAddress(false);
    }
  };

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      address1: "",
      landmark: "",
      houseNo: "",
      addressUseAs: "",
    },
    validationSchema: addressSchema,
    onSubmit,
  });

  const handleAddressTypeClick = (type) => {
    setSelectedButton(type);
    if (type === "Other") {
      setShowOtherField(true);
      setFieldValue("addressUseAs", "");
    } else {
      setShowOtherField(false);
      setFieldValue("addressUseAs", type);
    }
  };

  // useEffect(() => {
  //   if (showMarker?.latitude && showMarker?.longitude) {
  //     setMapCenter({
  //       lat: showMarker.latitude,
  //       lng: showMarker.longitude,
  //     });
  //     setMarkerPosition({
  //       lat: showMarker.latitude,
  //       lng: showMarker.longitude,
  //     });
  //     setSearchAddress(showMarker.address || "");
  //   }
  // }, [showMarker]);
  // console.log("isloaded", isLoaded);

  const handleMapClick = (event) => {
    if (!geocoderRef.current) {
      geocoderRef.current = new window.google.maps.Geocoder();
    }
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    geocoderRef.current.geocode(
      { location: { lat, lng } },
      (results, status) => {
        if (status === "OK" && results[0]) {
          const place = results[0];

          // Extract address components
          const postalCodeComponent = place.address_components?.find(
            (component) => component.types.includes("postal_code")
          );
          const administrativeAreaComponent = place.address_components?.find(
            (component) =>
              component.types.includes("administrative_area_level_1")
          );
          const localityComponent = place.address_components?.find(
            (component) => component.types.includes("locality")
          );
          const subLocalityComponent = place.address_components?.find(
            (component) => component.types.includes("sublocality_level_1")
          );
          console.log(
            "onclick map address",
            postalCodeComponent?.long_name,
            administrativeAreaComponent,
            localityComponent,
            subLocalityComponent
          );
          setMarkerPosition({ lat, lng });
          setMapCenter({ lat, lng });

          // Update location details
          setShowMarker({
            latitude: lat,
            longitude: lng,
            address: place.formatted_address,
            pincode: postalCodeComponent?.long_name || "",
            state: administrativeAreaComponent?.long_name || "",
            sub_locality:
              subLocalityComponent?.long_name ||
              localityComponent?.long_name ||
              "",
            cityId: "",
            stateId: 0,
          });

          setSearchAddress(place.formatted_address);
        } else {
          console.error("Reverse geocoding failed:", status);
        }
      }
    );
  };

  const handleOnPlacesChanged = () => {
    try {
      let place = autoCompleteRef.current.getPlaces();
      console.log("address", place);
      if (place[0] && place[0].geometry) {
        const location = place[0].geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        const address = place[0].formatted_address;
        const postalCodeComponent = place[0].address_components?.find(
          (component) => component.types.includes("postal_code")
        );
        const administrativeAreaComponent = place[0].address_components?.find(
          (component) => component.types.includes("administrative_area_level_1")
        );
        const localityComponent = place[0].address_components?.find(
          (component) => component.types.includes("locality")
        );
        const pincode = postalCodeComponent?.long_name;
        const state = administrativeAreaComponent?.long_name;
        const subLocality = localityComponent?.long_name;
        const place_id = place[0].place_id;
        setMapCenter({ lat, lng });
        setMarkerPosition({ lat, lng });
        setSearchAddress(address);
        setShowMarker({
          latitude: lat,
          longitude: lng,
          address: address,
          pincode: pincode,
          state: state,
          sub_locality: subLocality,
          cityId: "",
        });
        console.log(
          "lat long",
          lat,
          lng,
          address,
          pincode,
          subLocality,
          place_id
        );
      }
    } catch (error) {
      console.error("Error processing place:", error);
    }
  };

  // console.log(showMarker);
  // console.log("New Marker Position:", markerPosition);

  // Error handling for map loading
  if (loadError) {
    return (
      <div>
        Error loading Google Maps: {loadError.message}
        <br />
        Please check your API key and internet connection.
      </div>
    );
  }

  // Loading state
  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <div>
      <Card
        sx={{
          mt: "10px",
          boxShadow:
            " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        }}
      >
        <Typography
          sx={{ fontSize: "22px", fontWeight: 700, textAlign: "center" }}
        >
          Add address
        </Typography>
        {isLoaded && (
          <StandaloneSearchBox
            onLoad={(ref) => (autoCompleteRef.current = ref)}
            onPlacesChanged={handleOnPlacesChanged}
          >
            <TextField
              type="text"
              placeholder="Search location or Enter Pincode"
              variant="outlined"
              id="autocomplete"
              size="small"
              value={searchAddress}
              onChange={(e) => setSearchAddress(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                },
              }}
              fullWidth
              InputProps={{
                startAdornment: <MyLocationIcon />,
                endAdornment: <SearchIcon />,
              }}
            />
          </StandaloneSearchBox>
        )}
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={14}
            options={mapOptions}
            onLoad={(map) => (mapRef.current = map)}
            onClick={handleMapClick}
          >
            {showMarker?.latitude && showMarker?.longitude && (
              <Marker
                // position={{
                //   lat: showMarker.latitude,
                //   lng: showMarker.longitude,
                // }}
                position={markerPosition}
                draggable={true}
                // onDragEnd={async (e) => {
                //   console.log(e);

                //   const newLat = e.latLng.lat();
                //   const newLng = e.latLng.lng();

                //   // Update marker and location details
                //   setMarkerPosition({ lat: newLat, lng: newLng });
                //   setShowMarker((prev) => ({
                //     ...prev,
                //     latitude: newLat,
                //     longitude: newLng,
                //   }));
                // }}
                onDragEnd={handleMapClick}
              />
            )}
          </GoogleMap>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel className="formlabel">
                  Address Line (Optional){" "}
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="Address Line"
                  size="small"
                  value={values.address1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="address1"
                  id="address1"
                  error={errors.address1 && touched.address1}
                  helperText={
                    errors.address1 && touched.address1 ? errors.address1 : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className="formlabel">House No/Door No </FormLabel>
                <TextField
                  fullWidth
                  placeholder="House No"
                  size="small"
                  value={values.houseNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="houseNo"
                  id="houseNo"
                  error={errors.houseNo && touched.houseNo}
                  helperText={
                    errors.houseNo && touched.houseNo ? errors.houseNo : ""
                  }
                />
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className="formlabel">Landmark</FormLabel>
                <TextField
                  fullWidth
                  placeholder="landmark"
                  size="small"
                  value={values.landmark}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="landmark"
                  id="landmark"
                  error={errors.landmark && touched.landmark}
                  helperText={
                    errors.landmark && touched.landmark ? errors.landmark : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel className="formlabel">Address Use As</FormLabel>
                <div
                  style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
                >
                  {["Home", "Office", "Other"].map((type) => (
                    <Button
                      key={type}
                      variant={
                        selectedButton === type ? "contained" : "outlined"
                      }
                      onClick={() => handleAddressTypeClick(type)}
                      sx={{
                        backgroundColor:
                          selectedButton === type ? "#d83f87" : "transparent",
                        color: selectedButton === type ? "white" : "#d83f87",
                        "&:hover": {
                          backgroundColor:
                            selectedButton === type
                              ? "#d83f87"
                              : "rgba(216, 63, 135, 0.1)",
                        },
                        borderColor: "#d83f87",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {type === "Home" ? (
                        <HomeIcon />
                      ) : type === "Office" ? (
                        <ApartmentIcon />
                      ) : (
                        <MyLocationIcon />
                      )}
                      {type}
                    </Button>
                  ))}
                </div>
                {showOtherField && (
                  <TextField
                    fullWidth
                    placeholder="Specify address use as"
                    size="small"
                    value={values.addressUseAs}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="addressUseAs"
                    id="addressUseAs"
                    error={errors.addressUseAs && touched.addressUseAs}
                    // helperText={
                    //   errors.addressUseAs && touched.addressUseAs
                    //     ? errors.addressUseAs
                    //     : ""
                    // }
                  />
                )}
                {touched.addressUseAs && errors.addressUseAs && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.addressUseAs}
                  </p>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              mt: "10px",
              background: "rgba(216, 63, 135, 1)",
            }}
          >
            Submit
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default GoogleMapsSearchBar;
