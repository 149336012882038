import {
  Box,
  Button,
  Card,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PhoneIcon from "@mui/icons-material/Phone";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import { CompletedCustomerLoginHealthiansOrder } from "../../../services/HealthiansApi";
import dayjs from "dayjs";

const OrderHistory = () => {
  const [orderHistoryTest, setOrderHistoryTest] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate("");
  const token = JSON.parse(localStorage.getItem("token"));
  const userNumber = token?.username;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value));
    setPage(0);
  };
  const paginatedData = orderHistoryTest.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const sortOrdersByDate = (orders) => {
    return orders.sort((a, b) => {
      const dateA = dayjs(a.test_date);
      const dateB = dayjs(b.test_date);
      return dateB.diff(dateA);
    });
  };

  const getCompletedOrders = async () => {
    try {
      const res = await CompletedCustomerLoginHealthiansOrder(userNumber);
      const sortedOrders = sortOrdersByDate(res.data);
      setOrderHistoryTest(sortedOrders);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCompletedOrders();
  }, []);
  return (
    <div className="testOrderHistory">
      {paginatedData.map((el, id) => {
        return (
          <Card className="list" key={el.id}>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item sm={3} xs={3} md={2} className="firstGridItem">
                <Typography variant="caption" className="idhead">
                  Customer Name
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D2828",
                    margin: "0px",
                    fontSize: "12px",
                  }}
                >
                  {el.billing_cust_name}
                </Typography>
                <Box className="emailBox">
                  <Typography variant="caption" className="idhead">
                    Email
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#2D2828",
                      margin: "0px",
                      fontSize: "12px",
                    }}
                  >
                    {el.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={6} xs={6} md={8} className="secondGridItem">
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item sm={6} xs={6} md={6} className="subItem1">
                    <Typography
                      className="idhead"
                      sx={{
                        fontSize: "10px",
                        color: "#d83f87",
                        fontWeight: "bold",
                      }}
                    >
                      Mobile No
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PhoneIcon style={{ fontSize: "12px" }} />{" "}
                      <Typography
                        variant="caption"
                        sx={{ fontSize: "12px", color: "rgba(68, 68, 68, 1)" }}
                      >
                        {el.mobile == null ? <>No number</> : el.mobile}
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      className="locationBox"
                    >
                      <LocationOnIcon style={{ fontSize: "12px" }} />
                      <Typography
                        variant="caption"
                        sx={{ fontSize: "12px", color: "rgba(68, 68, 68, 1)" }}
                      >
                        {el.address}, {el.landmark}, {el.zipcode}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={6} md={6} className="subItem2">
                    <Box className="datebox">
                      <Typography>Collection Date</Typography>
                      <Typography variant="h6">
                        {dayjs(el.test_date).format("DD-MM-YYYY")}
                      </Typography>
                    </Box>
                    <Box className="bookingId">
                      <Typography>Booking id</Typography>
                      <Typography variant="h6">
                        {el.vendor_booking_id}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={3} xs={3} md={2} className="thridGridItem">
                <Box className="btnsBox">
                  <Button
                    sx={{
                      backgroundColor: "#d83f87",
                      textAlign: "left",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "12px",
                      borderRadius: "3px",
                      // padding: "8px 15px",
                      "&:hover": { backgroundColor: "#d83f87!important" },
                    }}
                    size="small"
                    onClick={() =>
                      navigate(`/user/healthianOrder/${el.vendor_booking_id}`)
                    }
                  >
                    <CalendarMonthIcon sx={{ fontSize: "0.9rem" }} /> Open
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
        );
      })}
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 15, 25]}
        count={orderHistoryTest.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default OrderHistory;
