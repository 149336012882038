import React, { useEffect, useState } from "react";
import { Box, Button, Card, Divider, Typography, Grid } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import "../../styles/Tables.scss";
import { getCustomerAppointments } from "../../services/customerOnboarding";
import { processAppointmentPayment } from "../../services/PlansAndProcedure";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "../Loader";

const PaymentPending = () => {
  const [pendingPayment, setPendingPayment] = useState([]);
  const { permissionsData, loading, setLoading } = useContext(Permissions);
  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      const { data } = await getCustomerAppointments(
        JSON.parse(localStorage.getItem("token"))?.uniqueId
      );
      let newdata = data.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });
      console.log("data: ", newdata);

      setPendingPayment(data);
      setLoading(false);
    };
    getDetails();
  }, []);

  async function proceedToPayment(appointmentId) {
    const { data } = await processAppointmentPayment(appointmentId);
    console.log("appointmentId: ", appointmentId);
    console.log("data: ", data);
    if (data.success === true) {
      window.open(data?.data?.instrumentResponse?.redirectInfo?.url, "_self");
    } else {
      toast.error("Cannot be processed at the moment", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);

  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  return (
    <div>
      <Box id="paymentPendingbox">
        <Typography
          variant="h5"
          sx={{ marginBottom: "10px", fontSize: "17px" }}
        >
          Payment Pending
        </Typography>
        <Card className="thecardbox" variant="outlined">
          {pendingPayment &&
            pendingPayment.map((el, id) => {
              return (
                <Box className="tablemainbox">
                  <Box className="tablecontentbox1">
                    <Typography variant="caption" className="idhead">
                      Appointment ID
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        color: "#2D2828",
                        margin: "0px",
                        fontSize: "12px",
                      }}
                    >
                      {el.appointmentId}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box className="tablecontentbox2">
                    <Box className="subbox1">
                      <Typography variant="h6" className="name">
                        {el.clinicName}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <LocationOnIcon style={{ fontSize: "14px" }} />
                        <Typography variant="caption" sx={{ fontSize: "10px" }}>
                          {el.clinicAddress == null ? (
                            <>No Address</>
                          ) : (
                            el.clinicAddress
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          paddingLeft: "15px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          View Location
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          View Clinic Details
                        </Typography>
                      </Box>
                      <Box className="specialitybox">
                        <Typography
                          sx={{
                            fontSize: "10px",
                            backgroundColor: "#FFEFF9",
                            padding: "5px",
                            color: "gray",
                          }}
                        >
                          {el.specialty}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="subbox2">
                      <Box className="datebox">
                        <Typography>Appointment Date</Typography>
                        <Typography variant="h6">
                          {formattedDate(el.date)}
                        </Typography>
                      </Box>
                      <Box className="timebox">
                        <Typography>Appointment Time</Typography>
                        <Typography variant="h6">{el.time}</Typography>
                      </Box>
                    </Box>
                    <Box className="subbox3">
                      <Typography>Name</Typography>
                      <Typography variant="h6" className="remarks">
                        {el?.patientName}
                      </Typography>
                      <Typography>Remarks</Typography>
                      <Typography variant="h6" className="remarks">
                        {el.remark === "" ? <>No Remarks</> : el.remark}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box className="tablecontentbox3">
                    <Box>
                      {/* {console.log("apppointment last string value",el.appointmentId)} */}
                      <Button
                        // onClick={() => handleOpen(el)}
                        sx={{
                          backgroundColor: "#00984F",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          padding: "8px 15px",
                          fontSize: "12px",

                          "&:hover": { backgroundColor: "#00984F!important" },
                        }}
                        size="small"
                        onClick={() => {
                          proceedToPayment(el.appointmentId);
                        }}
                      >
                        Pay
                      </Button>
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Card>
      </Box>

      {/* ----------------------------------Table for mobile screen--------------------------- */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
        }}
        className="mobilescreencard"
      >
        {pendingPayment.map((el, id) => {
          return (
            <>
              <Card
                sx={{
                  padding: "0px 0%!important",
                  marginBottom: "10px",
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px",
                }}
                className="mobilecontentcard"
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography className="appointmentid" variant="h6">
                      {el.appointmentId}
                    </Typography>
                    <Typography className="dateheading" variant="caption">
                      Date
                    </Typography>
                    <Typography className="date" variant="subtitle1">
                      {formattedDate(el.date)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="speciality">
                      {el.specialty}
                    </Typography>
                    <Typography variant="caption" className="timeheading">
                      Time
                    </Typography>
                    <Typography variant="subtitle1" className="time">
                      {el.time}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{ backgroundColor: "#F5F5F5!important" }}
                    item
                    xs={4}
                  >
                    <Button
                      sx={{
                        backgroundColor: "#00984F",
                        textAlign: "left",
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": { backgroundColor: "#00984F!important" },
                      }}
                      className="paybtn"
                      size="small"
                      onClick={() => {
                        proceedToPayment(el.appointmentId);
                      }}
                    >
                      Pay
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </>
          );
        })}
      </Card>
      <Box className="customerfooter" sx={{ textAlign: "right" }}>
        <Typography fontSize="12px">
          If you find anything difficult to pay,
        </Typography>
        <Box>
          <Typography fontSize="12px">
            connect with us at
            <a href={`tel:8040091997`}> +91 8040091997</a> |
            <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
            <a href={`mailto:support@tron.health`}> support@tron.health.</a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default PaymentPending;
