import {
  Autocomplete,
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { CssTextField } from "../../styles/textFields";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";

const FamilyInfo = ({
  familyInfo,
  setFamilyInfo,
  setSelected,
  width,
  planType,
}) => {
  const validationSchema = yup.object().shape({
    familyInfo: yup.array().of(
      yup.object().shape({
        relation: yup
          .string()
          .test(
            "valid-relation",
            "Invalid Relation",
            (value) =>
              value === "Self" || // Allow "Self" to bypass validation
              [
                "Father",
                "Mother",
                "Sister",
                "Brother",
                "Son",
                "Daughter",
                "Wife",
              ].includes(value)
          )
          .required("Relation is required"),
        name: yup
          .string()
          .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
          .required("Name is required"),
        dob: yup.date().nullable().required("Date of Birth is required"),
        gender: yup.string().required("Gender is required"),
      })
    ),
  });
  console.log(
    "familyInfo check values in familyInfo page for Individual",
    familyInfo
  );

  const error = () => {
    toast.error("Please Fill All The Details", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      familyInfo,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("Submitted Data: ", values);
      setFamilyInfo(values.familyInfo);
      setSelected("Health Info");
    },
  });

  const handleAddNew = () => {
    const updatedFamilyInfo = [
      ...familyInfo,
      { relation: "", name: "", dob: "", gender: "" },
    ];
    setFamilyInfo(updatedFamilyInfo);
    setFieldValue("familyInfo", updatedFamilyInfo);
  };

  const handleDelete = (index) => {
    const updatedFamilyInfo = [...familyInfo];
    updatedFamilyInfo.splice(index, 1);
    setFamilyInfo(updatedFamilyInfo);
    setFieldValue("familyInfo", updatedFamilyInfo);
  };

  const val = familyInfo.filter(
    (item) => !item.relation || !item.name || !item.dob || !item.gender
  );
  console.log("val: ", val);
  console.log("Validation Errors: ", errors.familyInfo);

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {familyInfo.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={1} lg={0.5}>
                <Typography
                  mt={1}
                  mb={1}
                  variant="subtitle2"
                  fontWeight="bold"
                  fontSize="16px"
                >
                  {index + 1}.{" "}
                </Typography>
              </Grid>
              <Grid item xs={5} lg={2.5}>
                <Typography
                  mt={1}
                  mb={1}
                  variant="subtitle2"
                  fontWeight="bold"
                  fontSize="16px"
                >
                  Relation
                </Typography>
                <Autocomplete
                  disableClearable
                  disabled={item.relation === "Self" ? true : false}
                  options={
                    item.relation === "Self"
                      ? ["Self"]
                      : [
                          "Father",
                          "Mother",
                          "Sister",
                          "Brother",
                          "Son",
                          "Daughter",
                          "Wife",
                        ]
                  }
                  onChange={(event, value) => {
                    const data = [...familyInfo];
                    data[index].relation = value;
                    setFamilyInfo(data);
                    setFieldValue(`familyInfo[${index}].relation`, value);
                  }}
                  value={item.relation}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <CssTextField
                      // disabled={id && !edit ? true : false}
                      {...params}
                      fullWidth
                      placeholder="Relation"
                      variant="outlined"
                      type="text"
                      error={Boolean(
                        errors.familyInfo?.[index]?.relation &&
                          touched.familyInfo?.[index]?.relation
                      )}
                      helperText={
                        item.relation === "Self"
                          ? ""
                          : errors.familyInfo?.[index]?.relation
                      }
                    />
                  )}
                />
              </Grid>
              {/* {width < 786 && <Grid item xs={1}></Grid>} */}
              <Grid item xs={6} lg={3}>
                {" "}
                <Typography
                  mt={1}
                  mb={1}
                  variant="subtitle2"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  Name
                </Typography>
                <CssTextField
                  fullWidth
                  size="small"
                  placeholder="Name"
                  variant="outlined"
                  name={`familyInfo[${index}].name`}
                  type="text"
                  onChange={(e) => {
                    const data = [...familyInfo];
                    data[index].name = e.target.value;
                    setFamilyInfo(data);
                    setFieldValue(`familyInfo[${index}].name`, e.target.value);
                  }}
                  value={item?.name}
                  error={Boolean(
                    errors.familyInfo?.[index]?.name &&
                      touched.familyInfo?.[index]?.name
                  )}
                  helperText={errors.familyInfo?.[index]?.name}
                />
              </Grid>
              {width < 786 && <Grid item xs={1}></Grid>}
              <Grid item xs={5} lg={2.5}>
                <Typography
                  mt={1}
                  mb={1}
                  variant="subtitle2"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  Date Of Birth
                </Typography>
                <MobileDatePicker
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    const data = [...familyInfo];
                    data[index].dob = newValue;
                    setFamilyInfo(data);
                    setFieldValue(`familyInfo[${index}].dob`, newValue);
                  }}
                  value={item?.dob}
                  // maxDate={new Date()}
                  minDate={new Date().setFullYear(
                    new Date().getFullYear() - 120
                  )}
                  maxDate={new Date().setFullYear(
                    new Date().getFullYear() - 18
                  )}
                  renderInput={(params) => (
                    <CssTextField
                      focused
                      {...params}
                      placeholder="DateOf Birth"
                      size="small"
                      // name={item.value}
                      fullWidth
                      variant="outlined"
                      error={Boolean(
                        errors.familyInfo?.[index]?.dob &&
                          touched.familyInfo?.[index]?.dob
                      )}
                      helperText={errors.familyInfo?.[index]?.dob}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} lg={1.5}>
                <Typography
                  mt={1}
                  mb={1}
                  variant="subtitle2"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  Gender
                </Typography>
                <Select
                  sx={{
                    backgroundColor: "#FFFFFF",
                  }}
                  name={`familyInfo[${index}].gender`}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 200,
                        overflow: "auto",
                      },
                    },
                  }}
                  onChange={(event) => {
                    const data = [...familyInfo];
                    data[index].gender = event.target.value;
                    setFamilyInfo(data);
                    setFieldValue(
                      `familyInfo[${index}].gender`,
                      event.target.value
                    );
                  }}
                  value={item.gender || ""}
                  placeholder="Select"
                  size="small"
                  className="inputbox"
                  fullWidth
                >
                  {[
                    { option: "Male", value: "M", id: 1 },
                    { option: "Female", value: "F", id: 2 },
                    { option: "Others", value: "O", id: 3 },
                  ].map((gender, id) => (
                    <MenuItem key={gender.id} value={gender.value}>
                      {gender.option}
                    </MenuItem>
                  ))}
                </Select>
                <p style={{ color: "red", fontSize: "12px" }}>
                  {errors.familyInfo?.[index]?.gender}
                </p>
              </Grid>
              {index + 1 > 3 ? (
                <Grid item xs={1} lg={0.5}>
                  <DeleteSweepIcon
                    sx={{
                      height: 30,
                      width: 30,
                      cursor: "pointer",
                      marginTop: "40px",
                    }}
                    onClick={() => handleDelete(index)}
                  />
                </Grid>
              ) : (
                <Grid item xs={1} lg={0.5}></Grid>
              )}
              {planType === "Family" && index + 1 === familyInfo.length ? (
                <Grid item xs={12} lg={1.5}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#2d256c!important",
                      fontSize: "12px",
                      padding: "8px 15px",
                      marginLeft: "10px",
                      marginTop: "40px",
                    }}
                    onClick={handleAddNew}
                  >
                    Add New
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} lg={1.5}></Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
        <br />
        <br />
        <div style={{ display: "flex", alignContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              width: "15em",
              backgroundColor: "#2d256c!important",
              fontSize: "12px",
              padding: "8px 15px",
              marginLeft: "10px",
            }}
            onClick={() => {
              setSelected("Plans");
              planType === "Indivisual"
                ? setFamilyInfo([
                    { relation: "Self", name: "", dob: "", gender: "" },
                  ])
                : setFamilyInfo([
                    { relation: "Self", name: "", dob: "", gender: "" },
                    { relation: "", name: "", dob: "", gender: "" },
                    { relation: "", name: "", dob: "", gender: "" },
                  ]);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "15em",
              backgroundColor: "#2d256c!important",
              fontSize: "12px",
              padding: "8px 15px",
              marginLeft: "10px",
            }}
          >
            Next
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default FamilyInfo;
