import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AddClinicSpeciality } from "../../services/PlansAndProcedure";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const plansProcedureSchema = yup
  .object({
    speciality: yup.string().required("This Field Required"),
    imageurl: yup.string().required("This Field is Required"),
  })
  .required();
const AddSpeciality = ({ specialities, setSpecialities,setPageRender }) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const [isSubmitting,setIsSubmitting]=useState(false);
  const fileInputRef = useRef(null);
  const submittedata = useRef(null);
  console.log("submitteddata in ref ", submittedata);

  const handleOpen = () => {
    setOpen(true);
    setPageRender(false)
  };
  const handleClose = () => {
    reset();
    setOpen(false);
    setImage("");
    setIsSubmitting(false)
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
    resolver: yupResolver(plansProcedureSchema),
  });

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const url = reader.result;
      setImage(url);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data) => {
    if (data) {
      submittedata.current = data;
      const formdata={
        speciality:data.speciality,
        specialityImage:data.imageurl
      }
      console.log("formdata",formdata)
      try {
        setIsSubmitting(true)
        let res=await AddClinicSpeciality(formdata);
        if(res){
          handleClose();
        }
      } catch (error) {
        console.log(error.message)
        setIsSubmitting(false)
      }
      setPageRender(true)
      // handleClose();
      // setSpecialities([...specialities, submittedata.current]);
    }
  };
  return (
    <div>
      <Button
        sx={{
          backgroundColor: "#2d256c!important",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform: "capitalize",
          fontSize: "12px",
          padding: "8px 15px",

          "&:hover": { backgroundColor: "#2d256c!important" },
        }}
        size="small"
        type="submit"
        onClick={handleOpen}
      >
        Add Speciality
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{ textAlign: "center", fontSize:"17px" }}
            variant="h5"
            component="h2"
          >
            Add Speciality
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography sx={{fontWeight:"bold",mt:"10px",fontSize:"14px"}}>Speciality</Typography>
            <TextField
              {...register("speciality")}
              type="text"
              size="small"
              fullWidth
              placeholder="Add Speciality"
            />
            <span style={{ color: "red" }} className="error">
              {errors.speciality?.message}
            </span>
            <Typography sx={{fontWeight:"bold",mt:"10px",fontSize:"14px"}}>Add Icon</Typography>
            <Stack direction="row">
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                placeholder="Upload Image"
                type="text"
                {...register("imageurl")}
                value={image}
                readonly
              />

              <input
                type="file"
                name="picture"
                accept="image/*,.pdf"
                capture="user"
                hidden
                ref={fileInputRef}
                onChange={handleFileSelect}
              />
              <Button
                variant="contained"
                component="label"
                sx={{
                  borderRadius: "0px 8px 8px 0px",
                  color: "#fff",
                  boxShadow: "none",
                }}
                onClick={handleImageUpload}
              >
                Upload
              </Button>
            </Stack>
            <span style={{ color: "red" }} className="error">
              {image === "" ? errors.imageurl?.message : ""}
            </span>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                onClick={handleClose}
                style={{
                  background: "#696969",
                  color: "white",
                  border: "none",
                  padding: "8px 15px",
                  borderRadius: "3px",
                  fontSize:"12px"
                }}
                type="reset"
              >
                Cancel
              </button>
              <button
                disabled={isSubmitting}
                style={{
                  background: isSubmitting?"#898989":"#D83F87",
                  color: "white",
                  border: "none",
                  padding: "8px 15px",
                  borderRadius: "3px",
                  fontSize:"12px",
                  cursor: isSubmitting?"default":"pointer"
                }}
                type="submit"
              >
                Submit
              </button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddSpeciality;
