import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ListIcon from "@mui/icons-material/List";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./BookYourTest.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchIcon from "@mui/icons-material/Search";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import StarIcon from "@mui/icons-material/Star";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Permissions } from "../../Context/PermissionsContext";
import {
  CheckingOfServiceAvilabilityByLatlong,
  DeleteAddress,
  FreezeSlot,
  getB2CAddresses,
  getFamilyMembersForThisCustomer,
  getPartnerHealthianPackages,
  getPaymentLinkForHealthianOrder,
  PostSlotsByLocation,
} from "../../../services/HealthiansApi";
import GoogleMapsSearchBar from "../MapSarchBar/GoogleMapsSearchBar";

const BookYourTest = () => {
  const [value, setValue] = useState("location");
  const [completedTabs, setCompletedTabs] = useState([]);
  const { healthiansToken } = useContext(Permissions);
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token?.uniqueId;
  const userNumber = token?.username;
  // console.log("HealthiansToken", healthiansToken);
  const [packages, setPackages] = useState([]);
  const [formData, setFormData] = useState({
    pincode: "",
    selectedPackages: [],
    selectedMembers: [],
    pickupSlot: {},
    billing: "",
    zoneId: 0,
    cityId: "",
    stateId: 0,
  });
  const [locationDetails, setLocationDetails] = useState({
    latitude: "",
    longitude: "",
    address: "",
    pincode: "",
    state: "",
    stateId: 0,
    sub_locality: "",
    cityId: "",
    zoneId: 0,
    landmark: "",
  });
  const [b2cCustomerAddresses, setB2CCustomerAddresses] = useState([]);
  // console.log("locatONdETAILS", locationDetails);

  const [billingBody, setBillingBody] = useState({
    customer: [],
    slot: {
      slot_id: "",
    },
    package: [],
    customer_calling_number: "",
    billing_cust_name: "",
    gender: "",
    mobile: "",
    email: "",
    state: 0,
    cityId: 0,
    test_date: "",
    test_time: "",
    sub_locality: "",
    latitude: "",
    longitude: "",
    address: "",
    zipcode: "",
    landmark: "",
    altmobile: "",
    altemail: "",
    hard_copy: 0,
    vendor_booking_id: "",
    vendor_billing_user_id: "",
    payment_option: "prepaid",
    discounted_price: 0,
    zone_id: 0,
    created_by: "",
  });

  const completeReset = () => {
    // Reset all state variables to their initial values
    // navigator.geolocation.getCurrentPosition((position) => {
    //   setLocationDetails({
    //     latitude: position.coords.latitude,
    //     longitude: position.coords.longitude,
    //   });
    // });
    // alert("Token is Refreshing");
    setValue("location");
    setCompletedTabs([]);
    setPackages([]);
    setFormData({
      pincode: "",
      selectedPackages: [],
      selectedMembers: [],
      pickupSlot: {},
      billing: "",
      zoneId: 0,
      cityId: "",
      stateId: 0,
    });
    setLocationDetails({
      latitude: "",
      longitude: "",
      address: "",
      pincode: "",
      landmark: "",
      state: "",
      sub_locality: "",
      stateId: 0,
      cityId: "",
      zoneId: 0,
    });
    setBillingBody({
      // Reset to initial billingBody state
      customer: [],
      slot: { slot_id: "" },
      package: [],
      customer_calling_number: "",
      billing_cust_name: "",
      gender: "",
      mobile: "",
      email: "",
      state: 0,
      cityId: 0,
      test_date: "",
      test_time: "",
      sub_locality: "",
      latitude: "",
      longitude: "",
      address: "",
      zipcode: "",
      landmark: "",
      altmobile: "",
      altemail: "",
      hard_copy: 0,
      vendor_booking_id: "",
      vendor_billing_user_id: "",
      payment_option: "prepaid",
      discounted_price: 0,
      zone_id: 0,
      created_by: "",
    });

    // Attempt to get current geolocation again
  };

  const onSubmit = async () => {
    if (locationDetails.pincode !== formData.pincode) {
      completeReset();
      setPackages([]);
    }
    // console.log(values);
    const validatebody = {
      authKey: healthiansToken,
      latitude: locationDetails.latitude.toString(),
      longitude: locationDetails.longitude.toString(),
    };
    try {
      const res = await CheckingOfServiceAvilabilityByLatlong(validatebody);
      // console.log("serviceAvailabilty", res.data.data.zone_id);
      if (res.data.status) {
        const zoneId = Number(res.data.data.zone_id);
        setBillingBody((prevBody) => ({
          ...prevBody,
          address: locationDetails.address,
          zipcode: locationDetails.pincode,
          latitude: locationDetails.latitude.toString(),
          longitude: locationDetails.longitude.toString(),
          state: 0,
          cityId: 0,
          sub_locality: locationDetails.sub_locality,
          landmark: locationDetails.landmark,
          zone_id: zoneId,
          created_by: userNumber.toString(),
        }));
        const body = {
          authKey: healthiansToken,
          zipcode: locationDetails.pincode,
          product_type: "",
          product_type_id: "",
          start: "",
          limit: "",
          test_type: "",
          city_id: "",
        };
        try {
          const res = await getPartnerHealthianPackages(body);
          // console.log("healthians api", res.data);
          if (res.data && res.data.products.length > 0) {
            const transformedPackages = res.data.products.map((product) => ({
              ...product,
              selected: false,
            }));
            setPackages(transformedPackages);
            setFormData((prev) => ({
              ...prev,
              pincode: locationDetails.pincode,
              zoneId: zoneId,
            }));
            setCompletedTabs((prev) => [...prev, "location"]);
            setValue("tests");
          } else {
            toast.info("No tests available for the selected location.");
          }
        } catch (error) {
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          );
          toast.error("Unable to fetch tests. Please try another location.");
        }
      } else {
        toast.info("Service unavailable please choose another location");
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      toast.info("Internal Server error or Enter/select a valid location.");
    }
  };

  // const clearSelectedMembers = () => {
  //   const updatedMembers = membersList.map((member) => ({
  //     ...member,
  //     selected: false,
  //     packages: [],
  //   }));
  //   setMembersList(updatedMembers);
  //   setFormData((prev) => ({
  //     ...prev,
  //     selectedMembers: [],
  //   }));
  // };

  const handleNext = () => {
    const tabsOrder = ["location", "tests", "member", "pickupSlot", "billing"];
    const currentIndex = tabsOrder.indexOf(value);
    switch (value) {
      case "location":
        onSubmit();
        return;
      case "tests":
        if (formData.selectedPackages.length === 0) {
          toast.info("Please select at least one Test");
          return;
        }
        break;
      case "member":
        if (formData.selectedMembers.length === 0) {
          toast.info("Please select at least one member");
          return;
        }
        break;
      case "pickupSlot":
        const { date, slot } = formData.pickupSlot;
        if (!date) {
          toast.info("Please select a pickup date");
          return;
        }

        if (!slot) {
          toast.info("Please select a time slot");
          return;
        }
        FreezSlotBooking();
        break;
    }
    setCompletedTabs((prev) => [...prev, value]);
    setValue(tabsOrder[currentIndex + 1]);
  };

  const handleBack = () => {
    const tabsOrder = ["location", "tests", "member", "pickupSlot", "billing"];
    const currentIndex = tabsOrder.indexOf(value);
    if (currentIndex > 0) {
      if (tabsOrder[currentIndex - 1] === "location") {
        setFormData((prev) => ({
          ...prev,
          selectedPackages: [],
          selectedMembers: [],
          pickupSlot: {},
          pincode: "",
          zoneId: 0,
          cityId: "",
          stateId: 0,
        }));
        setPackages(
          packages.map((pkg) => ({
            ...pkg,
            selected: false,
          }))
        );
        setLocationDetails({
          latitude: "",
          longitude: "",
          pincode: "",
          address: "",
          state: "",
          stateId: 0,
          sub_locality: "",
          cityId: "",
          landmark: "",
          zoneId: 0,
        });
        setBillingBody({
          customer: [],
          slot: { slot_id: "" },
          package: [],
          customer_calling_number: "",
          billing_cust_name: "",
          gender: "",
          mobile: "",
          email: "",
          state: 0,
          cityId: 0,
          test_date: "",
          test_time: "",
          sub_locality: "",
          latitude: "",
          longitude: "",
          address: "",
          zipcode: "",
          landmark: "",
          altmobile: "",
          altemail: "",
          hard_copy: 0,
          vendor_booking_id: "",
          vendor_billing_user_id: "",
          payment_option: "prepaid",
          discounted_price: 0,
          zone_id: 0,
          created_by: "",
        });
      }

      if (tabsOrder[currentIndex - 1] === "tests") {
        setFormData((prev) => ({
          ...prev,
          selectedMembers: [],
        }));
        setBillingBody((prev) => ({
          ...prev,
          customer: [],
          package: [],
        }));
      }
      // Re-sync membersList when navigating back to "Member"
      if (tabsOrder[currentIndex - 1] === "member") {
        setFormData((prev) => ({
          ...prev,
          selectedMembers: [],
          pickupSlot: { ...prev.pickupSlot, date: null, slot: "" },
        }));
        setBillingBody((prev) => ({
          ...prev,
          customer: [],
          package: [],
        }));
      }

      if (tabsOrder[currentIndex - 1] === "pickupSlot") {
        setFormData((prevData) => ({
          ...prevData,
          pickupSlot: { ...prevData.pickupSlot, date: null, slot: "" },
        }));
        setBillingBody((prev) => ({
          ...prev,
          slot: {
            ...prev.slot,
            slot_id: "",
          },
          hard_copy: 0,
        }));
      }
      setValue(tabsOrder[currentIndex - 1]);
      setCompletedTabs((prev) => prev.slice(0, -1));
    }
  };

  const isCompleted = (tab) => completedTabs.includes(tab);

  const handlePackageSelect = (pck) => {
    const updatedPackages = packages.map((pkg) =>
      pkg.id === pck.id ? { ...pkg, selected: !pkg.selected } : pkg
    );
    setPackages(updatedPackages);
    setFormData((prev) => ({
      ...prev,
      selectedPackages: updatedPackages.filter((pkg) => pkg.selected),
      cityId: pck.city_id,
    }));
    setLocationDetails((prev) => ({
      ...prev,
      cityId: pck.city_id,
    }));
    setBillingBody((prev) => ({
      ...prev,
      cityId: Number(pck.city_id),
    }));
  };

  console.log("tab", value);
  console.log("completed tab", completedTabs);
  console.log("formdata", formData);
  console.log("billingBody", billingBody);

  // useEffect(() => {
  //   if (healthiansToken) {
  //     completeReset();
  //   }
  // }, [healthiansToken]);

  const FreezSlotBooking = async () => {
    const body = {
      authKey: healthiansToken,
      slot_id: billingBody.slot.slot_id,
      vendor_billing_user_id: uniqueId,
      customer_list: [
        {
          customer_id: uniqueId,
        },
      ],
    };
    try {
      const res = await FreezeSlot(body);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCustomerAddresses = async () => {
    try {
      const res = await getB2CAddresses();
      // console.log(res.data);
      setB2CCustomerAddresses(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const createOrderBooking = async () => {
    if (!billingBody.customer_calling_number) {
      toast.error("Customer calling number is required");
      return;
    }
    if (billingBody.customer_calling_number.length !== 10) {
      toast.error("Customer calling number must be exactly 10 digits");
      return; // Exit the function if validation fails
    }
    try {
      const res = await getPaymentLinkForHealthianOrder(billingBody);
      console.log("orderbooking response", res.data);

      if (res?.data?.success && res?.data?.code === "PAYMENT_INITIATED") {
        toast.success("Payment link initiated Successfully");
        const redirectUrl = res.data.data.instrumentResponse?.redirectInfo?.url;

        if (redirectUrl) {
          window.open(redirectUrl, "_self");
          return;
        } else {
          toast.error("Payment URL not found");
        }
      } else {
        toast.error(res?.data?.message || "Payment initiation failed");
      }
    } catch (error) {
      console.log(error.message);
      toast.error("An error occurred while creating the order");
    }
    // finally {
    //   if (merchantTransactionId) {
    //     navigate(`/user/testOrderStatus/${merchantTransactionId}`);
    //   } else {
    //     toast.error("Transaction ID not found.");
    //   }
    // }
  };

  useEffect(() => {
    getCustomerAddresses();
  }, []);
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     setLocationDetails((prev) => ({
  //       ...prev,
  //       latitude: position.coords.latitude,
  //       longitude: position.coords.longitude,
  //     }));
  //   });
  // }, []);
  // console.log("location details", locationDetails);

  return (
    <div className="bookYourtestMainDiv">
      <Typography variant="h5" gutterBottom>
        Book Your Test
      </Typography>

      <TabContext value={value}>
        <Card className="stepperCard">
          <Tabs
            sx={{ maxWidth: { xs: 350, sm: 620, md: 780 } }}
            value={value}
            variant="scrollable"
            scrollButtons="auto"
          >
            {["location", "tests", "member", "pickupSlot", "billing"].map(
              (tab) => (
                <Tab
                  key={tab}
                  className="stepperBtns"
                  label={
                    <>
                      {isCompleted(tab) ? (
                        <CheckCircleIcon sx={{ color: "#D83F87" }} />
                      ) : (
                        {
                          location: (
                            <LocationOnIcon
                              sx={{
                                color:
                                  value === tab
                                    ? "white"
                                    : "rgba(217, 217, 217, 1)",
                              }}
                            />
                          ),
                          tests: (
                            <ListIcon
                              sx={{
                                color:
                                  value === tab
                                    ? "white"
                                    : "rgba(217, 217, 217, 1)",
                              }}
                            />
                          ),
                          member: (
                            <PersonIcon
                              sx={{
                                color:
                                  value === tab
                                    ? "white"
                                    : "rgba(217, 217, 217, 1)",
                              }}
                            />
                          ),
                          pickupSlot: (
                            <CalendarMonthIcon
                              sx={{
                                color:
                                  value === tab
                                    ? "white"
                                    : "rgba(217, 217, 217, 1)",
                              }}
                            />
                          ),
                          billing: (
                            <ReceiptLongIcon
                              sx={{
                                color:
                                  value === tab
                                    ? "white"
                                    : "rgba(217, 217, 217, 1)",
                              }}
                            />
                          ),
                        }[tab]
                      )}
                      <Typography
                        sx={{
                          color:
                            value === tab ? "white" : "rgba(45, 37, 108, 1)",
                        }}
                      >
                        {tab}
                      </Typography>
                    </>
                  }
                  value={tab}
                  sx={{
                    backgroundColor: value === tab ? "#D83F87" : "transparent",
                    color: value === tab ? "white" : "rgba(45, 37, 108, 1)",
                  }}
                  disabled={value !== tab}
                />
              )
            )}
          </Tabs>
        </Card>

        {/* ************************Tab Panels********************************** */}
        <Card style={{ marginTop: "10px" }}>
          <TabPanel value="location">
            <LocationTab
              setLocationDetails={setLocationDetails}
              locationDetails={locationDetails}
              b2cCustomerAddresses={b2cCustomerAddresses}
              setFormData={setFormData}
              getCustomerAddresses={getCustomerAddresses}
            />
          </TabPanel>
          <TabPanel value="tests">
            <PackageTabPanel
              packages={packages}
              formData={formData}
              setFormData={setFormData}
              onPackageSelect={handlePackageSelect}
            />
          </TabPanel>
          <TabPanel value="member">
            <MemberTabPanel
              setFormData={setFormData}
              formData={formData}
              packages={packages}
              setBillingBody={setBillingBody}
              billingBody={billingBody}
            />
          </TabPanel>
          <TabPanel value="pickupSlot">
            <PickupTabPanel
              setFormData={setFormData}
              formData={formData}
              locationDetails={locationDetails}
              setBillingBody={setBillingBody}
              billingBody={billingBody}
            />
          </TabPanel>
          <TabPanel value="billing">
            <BillingDetailsTabPanel
              formData={formData}
              setFormData={setFormData}
              setBillingBody={setBillingBody}
              billingBody={billingBody}
            />
          </TabPanel>

          {/* Back and Next Buttons */}

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleBack}
              disabled={value === "location"}
              sx={{
                background: "none",
                color: "#2d256c",
                boxShadow: "none",
                border: "1px solid rgba(217, 217, 217, 1)",
                textTransform: "capitalize",
                padding: "8px 15px",
                width: "111px",
                "&:hover": {
                  backgroundColor: "rgba(217, 217, 217, 1)!important",
                  color: "rgba(45, 37, 108, 1)",
                },
              }}
            >
              Back
            </Button>
            {value === "billing" ? (
              <Button
                variant="contained"
                color="primary"
                onClick={createOrderBooking}
                sx={{
                  textTransform: "capitalize",
                  padding: "8px 15px",
                  width: "111px",
                  backgroundColor: "#d63f85",
                }}
              >
                Pay Now
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={value === "billing"}
                sx={{
                  textTransform: "capitalize",
                  padding: "8px 15px",
                  width: "111px",
                }}
              >
                Next
              </Button>
            )}
          </div>
        </Card>
      </TabContext>
      {/* ---------------------------customer footer-------------------- */}

      <Box className="customerfooter" sx={{ textAlign: "right" }}>
        <Typography>
          Finding issue on your appointment or any queries
        </Typography>
        <Box>
          <Typography>
            connect with us at
            <a href={`tel:8040091997`}> +91 8040091997</a> |
            <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
            <a href={`mailto:support@tron.health`}> support@tron.health.</a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default BookYourTest;

// ****************************************Location Tab*************************************************
const LocationTab = ({
  setLocationDetails,
  locationDetails,
  b2cCustomerAddresses,
  setFormData,
  getCustomerAddresses,
}) => {
  const [addAddress, setAddAddress] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [rejectopen, setRejectOpen] = useState(false);
  const [rejectedAddress, setRejectedAddress] = useState({});

  const handleAddressSelect = (selectedAddress) => {
    // If the selected address is already selected, deselect it
    if (selectedAddressId === selectedAddress.id) {
      setSelectedAddressId(null);
      setLocationDetails((prev) => ({
        ...prev,
        latitude: "",
        longitude: "",
        address: "",
        pincode: "",
        state: "",
        stateId: 0,
        sub_locality: "",
        cityId: "",
        zoneId: 0,
        landmark: "",
      }));

      setFormData((prev) => ({
        ...prev,
        pincode: "",
        selectedPackages: [],
        selectedMembers: [],
        pickupSlot: {},
        billing: "",
        zoneId: 0,
        cityId: "",
        stateId: 0,
      }));
    } else {
      const updatedAddresses = b2cCustomerAddresses.map((address) => ({
        ...address,
        selected: address.id === selectedAddress.id,
      }));
      setLocationDetails((prev) => ({
        ...prev,
        latitude: selectedAddress.latitude,
        longitude: selectedAddress.longitude,
        address: selectedAddress.address,
        pincode: selectedAddress.zipcode,
        state: selectedAddress.state,
        sub_locality: selectedAddress.sub_locality,
        cityId: selectedAddress.cityId,
        landmark: selectedAddress.landmark,
      }));
      setFormData((prev) => ({
        ...prev,
        pincode: selectedAddress.zipcode,
        selectedPackages: [],
        selectedMembers: [],
        pickupSlot: {
          ...prev.pickupSlot,
          address: selectedAddress.address,
          pincode: selectedAddress.zipcode,
          state: selectedAddress.state,
          sub_locality: selectedAddress.sub_locality,
          landmark: selectedAddress.landmark,
        },
        billing: "",
        cityId: "",
        stateId: 0,
      }));
      setSelectedAddressId(selectedAddress.id);
    }
  };
  console.log("one at time", locationDetails);
  const handleRejectdialogOpen = (selectedAddress) => {
    console.log("rejectid", selectedAddress);
    setRejectOpen(true);
    setRejectedAddress(selectedAddress);
  };

  const handleRejectDialogClose = () => {
    setRejectOpen(false);
  };

  const handleDeleteAddress = async () => {
    try {
      const res = await DeleteAddress(rejectedAddress.id);
      if (res) {
        handleRejectDialogClose();
        toast.success("Address Deleted Successfully");
        getCustomerAddresses();
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Internal Server error");
    }
  };

  return (
    <div className="locationTabMainDiv">
      <Typography variant="h5">Select Address</Typography>
      <div className="addressMainDiv">
        {b2cCustomerAddresses.map((item, id) => {
          return (
            <Card key={item.id} className="addressBox">
              <div className="box1">
                <Typography>{item.type}</Typography>
                <DeleteIcon
                  onClick={() => handleRejectdialogOpen(item)}
                  sx={{ cursor: "pointer" }}
                />
              </div>
              <div className="box2">
                <LocationOnIcon />
                <Typography variant="subtitle2">
                  {item.address}, {item.landmark}, {item.zipcode}
                </Typography>
                <Checkbox
                  icon={<CheckCircleIcon />}
                  checkedIcon={
                    <CheckCircleIcon sx={{ color: "rgba(216, 63, 135, 1)" }} />
                  }
                  checked={selectedAddressId === item.id}
                  onChange={() => handleAddressSelect(item)}
                />
              </div>
            </Card>
          );
        })}
        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: "capitalize",
            padding: "8px 15px",
            fontSize: "12px",
            background: "none",
            color: "rgba(181, 181, 181, 1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "&:hover": {
              background: "none!important",
              color: "rgba(181, 181, 181, 1)",
            },
          }}
          className="addAddressbtn"
          onClick={() => {
            setAddAddress(!addAddress);
          }}
        >
          <AddBoxOutlinedIcon />
          Add Address
        </Button>
      </div>
      {addAddress && (
        <GoogleMapsSearchBar
          setLocationDetails={setLocationDetails}
          locationDetails={locationDetails}
          setAddAddress={setAddAddress}
          getCustomerAddresses={getCustomerAddresses}
        />
      )}
      {/* -------------------------Dialog box for Reject---------------------- */}
      <Dialog
        open={rejectopen}
        onClose={handleRejectDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to Delete this address ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              backgroundColor: "#D83F87",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#D83F87!important" },
            }}
            onClick={() => {
              handleDeleteAddress();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            onClick={handleRejectDialogClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const PackageTabPanel = ({ packages, onPackageSelect }) => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  // Filtered packages based on searchValue
  const filteredPackages = packages
    .filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase())
    )
    .sort((a, b) => {
      // First prioritize mostPopular
      if (a.mostPopular === "true" && b.mostPopular !== "true") return -1;
      if (a.mostPopular !== "true" && b.mostPopular === "true") return 1;

      // sort alphabetically by name
      return a.name.localeCompare(b.name);
    });

  // Adjust page if it exceeds the number of pages
  const maxPages = Math.ceil(filteredPackages.length / rowsPerPage);
  if (page >= maxPages && maxPages > 0) {
    setPage(maxPages - 1);
  }

  // Paginated packages
  const paginatedPackages = filteredPackages.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className="packageTabPanelmainDiv">
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12} md={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="outlined-search-packages">
              Search Packages
            </InputLabel>
            <OutlinedInput
              id="outlined-search-packages"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: " rgba(216, 63, 135, 1)" }} />
                </InputAdornment>
              }
              size="small"
              label="Search Packages"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className="packageSection">
        {paginatedPackages.map((el) => {
          const categories = Array.isArray(el?.categories) ? el.categories : [];
          const midIndex = Math.ceil(categories.length / 2);
          const firstHalf = categories.slice(0, midIndex);
          const secondHalf = categories.slice(midIndex);
          return (
            <div
              key={el.id}
              className="packagesMainbox"
              onClick={() => onPackageSelect(el)}
            >
              <div className="testDetails">
                <CheckCircleIcon
                  sx={{
                    color: el.selected ? "#D83F87" : "rgba(217, 217, 217, 1)",
                  }}
                />
                <div>
                  <Typography variant="h5">{el.name}</Typography>
                  <div className="categories-Li">
                    <div>
                      {firstHalf?.map((item, index) => {
                        return <li key={index}>{item}</li>;
                      })}
                    </div>
                    <div>
                      {secondHalf?.map((item, index) => {
                        return <li key={index}>{item}</li>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricebox">
                {el.mostPopular === "true" && (
                  <div className="mostPopular">
                    <Typography>
                      <StarIcon sx={{ fontSize: "10px" }} /> Most Popular
                    </Typography>
                  </div>
                )}
                <div className="priceValueBox">
                  <Typography>Price</Typography>
                  <Typography variant="h6">
                    ₹ {Number(el.price_after_discount).toLocaleString()}
                  </Typography>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* TablePagination */}
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 15, 25]}
        count={filteredPackages.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

// **********************************************Member Tab************************************************

const MemberTabPanel = ({
  setFormData,
  formData,
  packages,
  setBillingBody,
  billingBody,
}) => {
  const [membersList, setMembersList] = useState([]);
  // console.log("memberlist", membersList);
  // console.log("billingBody", billingBody);

  useEffect(() => {
    const GetFamilyMemberList = async () => {
      try {
        const res = await getFamilyMembersForThisCustomer();
        // console.log(res.data);
        // const transformedMembers = res.data.familyMembers.map((member) => ({
        //   ...member,
        //   selected: false,
        // }));
        const uniqueMembers = Object.values(
          res.data.familyMembers.reduce((acc, member) => {
            if (!acc[member.memberName]) {
              acc[member.memberName] = { ...member, selected: false };
            }
            return acc;
          }, {})
        );
        setMembersList(uniqueMembers);
        setBillingBody((prev) => ({
          ...prev,
          billing_cust_name: res.data.customerDetails.name,
          customer_calling_number: res.data.customerDetails.phone,
          email: res.data.customerDetails.email,
          mobile: res.data.customerDetails.phone,
          gender: res.data.familyMembers[0].memberGender || "M",
        }));
      } catch (error) {
        console.log(error.message);
      }
    };
    GetFamilyMemberList();
  }, []);

  const calculateAge = (dob) => {
    return dayjs().diff(dayjs(dob), "year");
  };

  const handleMemberSelect = (mem) => {
    const updatedMembers = membersList.map((member) =>
      member.memberId === mem.memberId
        ? {
            ...member,
            selected: !member.selected,
            packages: member.selected ? [] : [...formData.selectedPackages],
          }
        : member
    );
    setMembersList(updatedMembers);
    setFormData((prev) => ({
      ...prev,
      selectedMembers: updatedMembers.filter((member) => member.selected),
    }));

    setBillingBody((prevBillingBody) => {
      // If member is being selected
      if (!mem.selected) {
        // Prepare customer object for the selected member
        const newCustomerObj = {
          customer_id: mem.memberId,
          customer_name: mem.memberName,
          relation: mem.relationship === "Self" ? mem.relationship : "others",
          age: calculateAge(mem.memberDob),
          dob: dayjs(mem.memberDob).format("DD/MM/YYYY"),
          gender: mem.memberGender,
          contact_number: mem.memberMobile || "",
          email: mem.memberEmail || "",
          application_number: "",
        };

        // Prepare package object with deal IDs for the selected member
        const selectedPackageDealIds = formData.selectedPackages.map(
          (pkg) => pkg.deal_id
        );

        return {
          ...prevBillingBody,
          customer: [...prevBillingBody.customer, newCustomerObj],
          package: [
            ...prevBillingBody.package,
            {
              deal_id: selectedPackageDealIds, // This creates an object with deal_id as an array of package deal IDs
            },
          ],
        };
      } else {
        // If member is being deselected, remove from customer and package arrays
        return {
          ...prevBillingBody,
          customer: prevBillingBody.customer.filter(
            (customer) => customer.customer_id !== mem.memberId
          ),
          package: prevBillingBody.package.filter(
            (_, index) =>
              prevBillingBody.customer[index]?.customer_id !== mem.memberId
          ),
        };
      }
    });
  };

  const handlePackageRemove = (memberId, packageId) => {
    const member = membersList.find((m) => m.memberId === memberId);
    const packageToRemove = member.packages.find((pkg) => pkg.id === packageId);
    if (member.packages.length <= 1) {
      toast.info("At least one package must be selected for each member", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const updatedMembers = membersList.map((member) =>
      member.memberId === memberId
        ? {
            ...member,
            packages: member.packages.filter((pkg) => pkg.id !== packageId),
          }
        : member
    );
    setMembersList(updatedMembers);
    // Update billingBody
    setBillingBody((prevBillingBody) => {
      // Find the index of the member in the customer array
      const memberIndex = prevBillingBody.customer.findIndex(
        (customer) => customer.customer_id === memberId
      );

      // Create a new package array with the specific deal ID removed
      const updatedPackages = prevBillingBody.package.map(
        (packageItem, index) => {
          if (index === memberIndex) {
            return {
              deal_id: packageItem.deal_id.filter(
                (dealId) => dealId !== packageToRemove.deal_id
              ),
            };
          }
          return packageItem;
        }
      );

      return {
        ...prevBillingBody,
        package: updatedPackages,
      };
    });
    updateFormData(updatedMembers);
  };

  const updateFormData = (updatedMembers) => {
    setFormData((prev) => ({
      ...prev,
      selectedMembers: updatedMembers.filter((member) => member.selected),
    }));
  };

  const calculateTotalPrice = (packages) => {
    return packages.reduce(
      (total, pkg) => total + parseInt(pkg.price.replace(",", "")),
      0
    );
  };
  return (
    <div className="memberTabMainDiv">
      <Typography variant="h5" gutterBottom>
        Select Member
      </Typography>
      <div className="membersMainDiv">
        {membersList.map((mem, id) => {
          return (
            <div key={mem.memberId} className="memberBox">
              <div className="memberInfo">
                <div>
                  <Typography className="name">{mem.memberName}</Typography>
                  <Typography className="age">
                    {mem.relationship}, {calculateAge(mem.memberDob)}
                  </Typography>
                </div>
                <Checkbox
                  icon={<CheckCircleIcon />}
                  checkedIcon={
                    <CheckCircleIcon sx={{ color: "rgba(216, 63, 135, 1)" }} />
                  }
                  checked={mem.selected}
                  onChange={() => handleMemberSelect(mem)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <Divider sx={{ mt: "10px" }} />
      <div className="membersWithPackageSec">
        {membersList
          .filter((member) => member.selected)
          .map((member) => (
            <Card key={member.memberId} className="memberContentCard">
              <div className="memberDetails">
                <Typography className="name">{member.memberName}</Typography>
                <Typography className="genderAge">
                  {member.relationship},{calculateAge(member.memberDob)} yrs
                </Typography>
              </div>
              <div className="packageDetails">
                {member.packages.map((pkg) => (
                  <div className="package" key={pkg.id}>
                    <div>
                      <Typography className="testName">{pkg.name}</Typography>
                      <Typography className="price">
                        Price ₹{pkg.price}
                      </Typography>
                    </div>
                    <DeleteIcon
                      onClick={() =>
                        handlePackageRemove(member.memberId, pkg.id)
                      }
                      fontSize="small"
                      sx={{
                        background: "rgba(249, 229, 242, 1)",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ))}
              </div>
            </Card>
          ))}
      </div>
    </div>
  );
};

// ****************************************Pickup Slot Panel*********************************

const PickupTabPanel = ({
  formData,
  setFormData,
  locationDetails,
  setBillingBody,
  billingBody,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const { healthiansToken } = useContext(Permissions);
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token?.uniqueId;

  const totalMemberPrice = formData?.selectedMembers.reduce((total, member) => {
    if (member.selected && member.packages?.length > 0) {
      const memberPackageTotal = member.packages.reduce((packageTotal, pkg) => {
        const priceWithoutComma = pkg.price_after_discount.replace(/,/g, ""); // Remove commas from price
        const packagePrice = parseFloat(priceWithoutComma); // Convert to a number
        return packageTotal + packagePrice;
      }, 0);

      return total + memberPackageTotal;
    }
    return total;
  }, 0);

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const hourInt = parseInt(hours, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const hour12 = hourInt % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setFormData((prevData) => ({
      ...prevData,
      pickupSlot: {
        ...prevData.pickupSlot,
        date: date ? dayjs(date).format("YYYY-MM-DD") : null,
      },
    }));

    const body = {
      authKey: healthiansToken,
      latitude: locationDetails.latitude,
      longitude: locationDetails.longitude,
      slot_date: date ? dayjs(date).format("YYYY-MM-DD") : null,
      zone_id: formData.zoneId.toString(),
    };
    try {
      const res = await PostSlotsByLocation(body);
      // console.log("res.data for slots", res.data.data);

      if (res && res.data) {
        const slots = res.data.data;

        if (!slots || slots.length === 0) {
          // Show toast if no slots are available
          toast.info("No Slots Available");
          setAvailableSlots([]);
          setSelectedSlot("");
          return;
        }

        const transformedSlots = slots.map((slot) => ({
          value: `${slot.slot_time} - ${slot.end_time}`,
          displayTime: `${convertTo12HourFormat(
            slot.slot_time
          )} - ${convertTo12HourFormat(slot.end_time)}`,
          stmId: slot.stm_id,
          originalSlot: slot,
          stateId: slot.state_id,
        }));
        const stateId = slots.length > 0 ? slots[0].state_id : 0;
        console.log("stateId", stateId);

        setAvailableSlots(transformedSlots);
        setSelectedSlot("");
        setBillingBody((prev) => ({
          ...prev,
          state: Number(stateId),
          test_date: date ? dayjs(date).format("YYYY-MM-DD") : "",
        }));
      }
    } catch (error) {
      console.error(error.message);
      toast.info("Please choose another date for Time Slots");
    }
  };
  // console.log("slots", availableSlots);

  const handleSlotChange = (event) => {
    const selectedSlotValue = event.target.value;
    setSelectedSlot(selectedSlotValue);

    // Find the full slot object to store additional details if needed
    const selectedSlotDetails = availableSlots.find(
      (slot) => slot.value === selectedSlotValue
    );
    console.log("selectedSlotDetails", selectedSlotDetails);
    setFormData((prevData) => ({
      ...prevData,
      pickupSlot: {
        ...prevData.pickupSlot,
        slot: selectedSlotValue,
        stmId: selectedSlotDetails?.stmId || null,
        originalSlotData: selectedSlotDetails?.originalSlot || null,
      },
      stateId: Number(selectedSlotDetails.stateId),
    }));
    setBillingBody((prev) => ({
      ...prev,
      slot: {
        ...prev.slot,
        slot_id: selectedSlotDetails?.stmId || "",
      },
      vendor_billing_user_id: uniqueId,
      test_time: selectedSlotDetails?.value,
    }));
  };

  // console.log("billingbodyIn pickup", billingBody);
  // console.log("formdata in pickup", formData);

  useEffect(() => {
    setBillingBody((prev) => ({
      ...prev,
      discounted_price: totalMemberPrice,
    }));
  }, []);

  return (
    <div className="pickupTabMainDiv">
      <Typography variant="h5" gutterBottom>
        Choose Date & Time For Home Sample Collection
      </Typography>

      <div className="dateAndSlot">
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6} md={3}>
            <FormControl fullWidth>
              <FormLabel className="formlabel">Select Date</FormLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  inputFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  maxDate={
                    new Date(new Date().setDate(new Date().getDate() + 7))
                  }
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      sx={{ backgroundColor: "#FFFFFF" }}
                      placeholder="Select Date"
                      slotprops={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarMonthIcon />
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  )}
                  orientation={"portrait"}
                  onYearChange={onYearChangeHandler}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={6} md={3}>
            <FormControl fullWidth>
              <FormLabel className="formlabel">Select Slot</FormLabel>
              <Select
                sx={{
                  backgroundColor: "#FFFFFF",
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      overflow: "auto",
                    },
                  },
                }}
                placeholder="Select"
                size="small"
                className="inputbox"
                value={selectedSlot}
                onChange={handleSlotChange}
                disabled={availableSlots.length === 0}
              >
                {availableSlots.map((slot, id) => (
                  <MenuItem key={id} value={slot.value}>
                    {slot.displayTime}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

// ****************************************Billing Tab Panel****************************************

const BillingDetailsTabPanel = ({
  formData,
  setFormData,
  billingBody,
  setBillingBody,
}) => {
  const gst = billingBody.discounted_price * 0.18; // 18% GST
  const totalWithGst = billingBody.discounted_price + gst;

  console.log(
    "Total Price for selected members packages with gst",
    totalWithGst
  );
  // console.log("billingbody tab", billingBody);

  const handleHardCopyChange = (event) => {
    const isChecked = event.target.checked ? 1 : 0;
    setBillingBody((prev) => ({
      ...prev,
      hard_copy: isChecked,
    }));
  };

  const [error, setError] = useState(false);

  const handleBlur = () => {
    if (!billingBody.customer_calling_number.trim()) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const convertTo12HourFormat = (timeRange) => {
    const times = timeRange.split(" - ");
    return times
      .map((time24) => {
        const [hours, minutes] = time24.split(":");
        const hourInt = parseInt(hours, 10);
        const ampm = hourInt >= 12 ? "PM" : "AM";
        const hour12 = hourInt % 12 || 12;
        return `${hour12}:${minutes} ${ampm}`;
      })
      .join(" - ");
  };
  return (
    <div className="billingTabMainDiv">
      <Typography variant="h5" gutterBottom>
        Billing Details
      </Typography>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={billingBody.hard_copy === 1}
              onChange={handleHardCopyChange}
            />
          }
          label="Hard Copy"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontWeight: 600,
            },
          }}
        />
        <FormControl>
          <FormLabel className="formlabel">Customer Calling Number</FormLabel>
          <TextField
            fullWidth
            placeholder="Calling Number"
            size="small"
            value={billingBody.customer_calling_number}
            onChange={(e) => {
              setBillingBody((prev) => ({
                ...prev,
                customer_calling_number: e.target.value,
              }));
              setError(false);
            }}
            onBlur={handleBlur}
            error={error}
            helperText={error ? "Calling number is required" : ""}
            inputProps={{ maxLength: 10 }}
          />
        </FormControl>
      </Box>
      <Grid container spacing={2} mt="10px">
        <Grid item sm={8} xs={12} md={8}>
          <Card className="memberDetails">
            <Grid container spacing={2}>
              <Grid item sm={2} xs={3.5} md={2}>
                <div className="memberName">
                  <Typography className="heading">Member</Typography>
                  <div
                    style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}
                  >
                    {formData?.selectedMembers.map((el, id) => {
                      return (
                        <div key={el.memberId}>
                          <Typography className="content">
                            {el.memberName}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
              <Grid item sm={0.5} xs={0.5} md={0.5}>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  style={{ margin: 0 }}
                />
              </Grid>
              <Grid className="detailsBox" item sm={9.5} xs={8} md={9.5}>
                <Grid container spacing={2}>
                  <Grid item sm={3} xs={6} md={3}>
                    <Typography className="heading">Collection Date</Typography>
                    <Typography className="content">
                      {dayjs(formData?.pickupSlot.date).format("DD MMM, YYYY")}
                    </Typography>
                    <Typography className="heading">Collection Time</Typography>
                    <Typography className="content">
                      {convertTo12HourFormat(formData?.pickupSlot.slot)}
                    </Typography>
                  </Grid>
                  <Grid item sm={9} xs={12} md={9}>
                    <Typography className="heading">Address</Typography>
                    <Typography className="content">
                      <strong>{formData.pickupSlot.state}:</strong>
                      {formData.pickupSlot.address},{" "}
                      {formData.pickupSlot.landmark}{" "}
                      {formData.pickupSlot.pincode}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Card className="testDetails">
            <div className="TestdataBox">
              {formData?.selectedMembers.map((member) => (
                <div key={member.memberId}>
                  <Typography className="memberName">
                    Selected Package by {member.memberName}
                  </Typography>
                  <div className="packagesMainDiv">
                    {member.packages.map((pkg) => {
                      // const midIndex = Math.ceil(pkg?.categories?.length / 2);
                      // const firstHalf = pkg.categories?.slice(0, midIndex);
                      // const secondHalf = pkg.categories?.slice(midIndex);

                      return (
                        <div key={pkg.id}>
                          <Typography className="testName">
                            {pkg.name}
                          </Typography>
                          {/* <div className="categories">
                            <div className="categoryColumn">
                              {firstHalf?.map((category, index) => (
                                <li key={index}>{category}</li>
                              ))}
                            </div>
                            <div className="categoryColumn">
                              {secondHalf?.map((category, index) => (
                                <li key={index}>{category}</li>
                              ))}
                            </div>
                          </div> */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12} md={4}>
          <Card className="paymentDetails">
            <Typography className="heading">Payable Amount</Typography>

            <Grid container spacing={2}>
              <Grid item sm={6} xs={6} md={6}>
                <Typography className="sideheading">Price</Typography>
                {/* <Typography className="sideheading">Taxes(18% GST)</Typography> */}
              </Grid>
              <Grid item sm={6} xs={6} md={6}>
                <Typography className="value">
                  ₹ {billingBody.discounted_price.toLocaleString()}
                </Typography>
                {/* <Typography className="value">
                  {gst.toLocaleString()}
                </Typography> */}
              </Grid>
            </Grid>
            <Divider style={{ margin: "10px 0px" }} />
            <Grid container spacing={2}>
              <Grid item sm={6} xs={6} md={6}>
                <Typography className="sideheading">Total</Typography>
              </Grid>
              <Grid item sm={6} xs={6} md={6}>
                <Typography className="value">
                  {/* {totalWithGst.toLocaleString()} */}₹{" "}
                  {billingBody.discounted_price.toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
