import { Box, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getHealthianOrderDetails } from "../../../services/HealthiansApi";
import "./HealthianOrder.scss";

const IndividualHealthianOrderDetails = () => {
  const { id } = useParams();
  const [healthianOrder, setHealthianOrder] = useState({});
  const getIndividualHealthiansOrderDetails = async () => {
    try {
      const res = await getHealthianOrderDetails(id);
      setHealthianOrder(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getIndividualHealthiansOrderDetails();
  }, []);
  return (
    <div className="healthianOrder">
      <Typography variant="h5">Healthian Order Details</Typography>
      <Card className="mainCard">
        <Card className="card1">
          <Box className="nameAndEmailBox">
            <Typography className="heading">Name</Typography>
            <Typography className="content">
              {healthianOrder.billing_cust_name}
            </Typography>
            <Typography className="heading">Email</Typography>
            <Typography className="content">{healthianOrder.email}</Typography>
          </Box>
          <Box className="numberAddressBox">
            <Typography className="heading">Address</Typography>
            <Typography className="content">
              {healthianOrder.address}
            </Typography>
            <Typography className="heading">Number</Typography>
            <Typography className="content">{healthianOrder.mobile}</Typography>
          </Box>
          <Box className="landmarkSublocality">
            <Typography className="heading">Landmark</Typography>
            <Typography className="content">
              {healthianOrder.landmark}
            </Typography>
            <Typography className="heading">Sub Locality</Typography>
            <Typography className="content">
              {healthianOrder.sub_locality}
            </Typography>
          </Box>
        </Card>
        <Card className="card2">
          <Box className="customerDetails">
            <Typography className="title">Selected Customers</Typography>
            <Box>
              {healthianOrder?.customer?.map((el, id) => {
                return (
                  <Card className="body">
                    <Box>
                      <Typography className="heading">Customer Name</Typography>
                      <Typography className="content">
                        {el.customer_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className="heading">Age</Typography>
                      <Typography className="content">{el.age}</Typography>
                    </Box>

                    <Box>
                      <Typography className="heading">D.O.B</Typography>
                      <Typography className="content">{el.dob}</Typography>
                    </Box>
                    <Box>
                      <Typography className="heading">Relation</Typography>
                      <Typography className="content">{el.relation}</Typography>
                    </Box>
                  </Card>
                );
              })}
            </Box>
          </Box>

          <Box className="packageDetails">
            <Typography className="title">Selected Packages</Typography>
            <Box>
              {Array.from(
                new Set(
                  healthianOrder?.package?.flatMap((el) => el.package_names)
                )
              ).map((pck, id) => (
                <Box key={id}>
                  <Typography className="pckName">{pck}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Card>
        <Card className="card3">
          <Box>
            <Typography className="heading">Collection Date</Typography>
            <Typography className="content">{healthianOrder.date}</Typography>
          </Box>
          <Box>
            <Typography className="heading">Price</Typography>
            <Typography className="content">
              {healthianOrder.discounted_price} /-
            </Typography>
          </Box>
          <Box>
            <Typography className="heading">Zipcode</Typography>
            <Typography className="content">
              {healthianOrder.zipcode}
            </Typography>
          </Box>
          <Box>
            <Typography className="heading">ZoneId</Typography>
            <Typography className="content">
              {healthianOrder.zone_id}
            </Typography>
          </Box>
        </Card>
      </Card>
      {/* ---------------------------customer footer-------------------- */}

      <Box className="customerfooter" sx={{ textAlign: "right" }}>
        <Typography>
          Finding issue on your appointment or any queries
        </Typography>
        <Box>
          <Typography>
            connect with us at
            <a href={`tel:8040091997`}> +91 8040091997</a> |
            <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
            <a href={`mailto:support@tron.health`}> support@tron.health.</a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default IndividualHealthianOrderDetails;
