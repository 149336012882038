import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsAndConditions from "./components/TermsAndConditions";
import TermnCon from "./components/TermnCon";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Contact from "./components/Contact";
import { PermissionsContext } from "./components/Context/PermissionsContext";
import Landing from "./components/b2c/landing";
import OurOPDnetwork from "./components/OurOPDnetwork";
import B2CPaymentStatus from "./components/b2c/B2CPaymentStatus";
import Feedback from "./components/Feedback";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PermissionsContext>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="tnc" element={<TermnCon />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="enrollInPlan" element={<Landing />} />
          <Route path="login" element={<Login />} />
          <Route path="/ouropdnetwork" element={<OurOPDnetwork />} />
          <Route path="/feedback/:id" element={<Feedback />} />
          <Route path="/b2cpaymentstatus/:id" element={<B2CPaymentStatus />} />
          <Route
            path="termsAndConditions/:id"
            element={<TermsAndConditions />}
          />
          {localStorage.getItem("token") && (
            <Route path="user/*" element={<App />} />
          )}
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </PermissionsContext>
  </React.StrictMode>
);
