import Appbar from "./components/Appbar";
import ClinicOnboarding from "./components/ClinicOnboarding/ClinicOnboarding";
import Leftbar from "./components/Leftbar";
import "./styles/layout.scss";
import "./styles/GlobalStyles.css";
import "./styles/UpcomingTableCustomer.scss";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";
import { useEffect } from "react";
import ClinicList from "./components/ClinicOnboarding/ClinicList";
import StaffOnboardingForm from "./components/StaffOnboarding/StaffOnboardingForm";
import StaffList from "./components/StaffOnboarding/StaffList";
import { Route, Routes } from "react-router-dom";
import NewAppointment from "./components/Customer/NewAppointment";
import AllClinics from "./components/Customer/AllClinics";
import ClinicView from "./components/Customer/ClinicView";
import CustomerClinicView from "./components/Customer/CustomerClinicView";
import UpComing from "./components/Customer/UpComing";
import ClinicAppointments from "./components/Clinic/ClinicAppointments";
import PasswordChange from "./components/Clinic/PasswordChange";
import AppointmentRequests from "./components/Clinic/AppointmentRequests";
import ClinicsClinicView from "./components/Clinic/ClinicsClinicView";
import CustomerAppointmentRequest from "./components/Customer/CustomerAppointmentRequest";
import AppointmentInfo from "./components/Clinic/AppointmentInfo";
import StartAppointment from "./components/Clinic/StartAppointment";
import PlansProcedure from "./components/PlansAndProcedure/PlansProcedure";
import Procedures from "./components/PlansAndProcedure/Procedures";
import Coupons from "./components/PlansAndProcedure/Coupons";
import AddCoupon from "./components/PlansAndProcedure/AddCoupon";
import PlansAndPricing from "./components/PlansAndProcedure/PlansAndPricing";
import AddPlan from "./components/PlansAndProcedure/AddPlan";
import EditCoupon from "./components/PlansAndProcedure/EditCoupon";
import EditPlan from "./components/PlansAndProcedure/EditPlan";
import CustomerPlansAndCoupons from "./components/Customer/CustomerPlansAndCoupons";
import ParticularCoupon from "./components/Customer/ParticularCoupon";
import AppointmentBillingFirst from "./components/Clinic/AppointmentBillingFirst";
import WifiTetheringOffIcon from "@mui/icons-material/WifiTetheringOff";
import AddCustomer from "./components/PlansAndProcedure/AddCustomer";
import CustomerListing from "./components/PlansAndProcedure/CustomerListing";
import Report from "./components/ClinicOnboarding/Report";
import CouponDetails from "./components/Customer/CouponDetails";
import { getUpdates } from "./services/customerOnboarding";
import CouponsBillingHistory from "./components/PlansAndProcedure/CouponsBillingHistory";
import PlanBillingHistory from "./components/PlansAndProcedure/PlanBillingHistory";
import PlanHistory from "./components/Customer/PlanHistory";
import CouponHistory from "./components/Customer/CouponHistory";
import BillingAndSettlementTable from "./components/Clinic/BillingAndSettlementTable";
import SettlementStatus from "./components/Clinic/SettlementStatus";
import PastAppointmentInfo from "./components/Clinic/PastAppointmentInfo";
import RolesList from "./components/RolesAndPermissions/RolesList";
import AddRole from "./components/RolesAndPermissions/AddRoles";
import CustomerPastDetails from "./components/Customer/CustomerPastDetails";
import CseBookingAppointment from "./components/Customer Support Executive/CseBookingAppointment";
import Appointments from "./components/Customer Support Executive/Appointments";
import PotentialCustomerList from "./components/Customer Support Executive/PotentialCustomerList";
import RetailPlans from "./components/PlansAndProcedure/RetailPlans";
import AddandEditRetailplan from "./components/PlansAndProcedure/AddandEditRetailplan";
import RetailPlanCustomerListing from "./components/PlansAndProcedure/RetailPlanCustomerList";
import ViewRetailCustomersDetails from "./components/PlansAndProcedure/ViewRetailCustomersDetails";
import PlansAndCouponsTabs from "./components/PlansAndProcedure/PlansAndCouponsTabs";
import PaymentStatus from "./components/Clinic/PaymentStatus";
import CustomerProfile from "./components/Customer/CustomerProfile";
import PaymentPending from "./components/Customer/PaymentPending";
import NotFound from "./components/NotFound";
import BookYourTest from "./components/Customer/BookingTest/BookYourTest";
import Packages from "./components/TestPackages/Packages";
import TestOrderHistory from "./components/Customer/TestOrderHistory/TestOrderHistory";
import TestOrderStatus from "./components/Customer/BookingTest/TestOrderStatus";
import IndividualHealthianOrderDetails from "./components/Customer/TestOrderHistory/IndividualHealthianOrderDetails";
import RescheduleDateAndTimeSlot from "./components/Customer/TestOrderHistory/RescheduleDateAndTimeSlot";
import TestBookingTab from "./components/TestPackages/TestBookings/TestBookingTab";

function App() {
  const token = JSON.parse(localStorage.getItem("token"));
  const [isOnline, setIsOnline] = useState(true);
  const [updates, setUpdates] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  // to check internet connection
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    const getUpdatesAny = async () => {
      try {
        const res = await getUpdates();
        const data = res.data;
        console.log("updates", data);
        setUpdates(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getUpdatesAny();
  }, []);

  // if (loading)
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Loader />
  //     </div>
  //   );
  return (
    <>
      {isOnline ? (
        ""
      ) : (
        <>
          <div
            style={{
              display: "flex",
              color: "red",
              fontWeight: "600",
              margin: "auto",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WifiTetheringOffIcon /> No internet Connectivity. Please Check your
            Network
          </div>
        </>
      )}
      {updates === "No updates so far" ? (
        ""
      ) : (
        <>
          <div
            style={{
              display: "flex",
              color: "red",
              fontWeight: "600",
              margin: "auto",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {updates}
          </div>
        </>
      )}
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Appbar />
          {width > 900 && <Leftbar />}
          <div className="central-body">
            <Routes>
              <Route
                exact
                path="/billingAndSettlementTable"
                element={<BillingAndSettlementTable />}
              />
              <Route
                exact
                path="/billingAndSettlementTable/:id"
                element={<SettlementStatus />}
              />
              <Route path="/" element={<ClinicList />} />
              <Route
                path="/potentialCustomer"
                element={<PotentialCustomerList />}
              />
              <Route path="/clinicList" element={<ClinicList />} />
              <Route path="/onboardingReport" element={<Report />} />
              <Route path="/roles" element={<RolesList />} />
              <Route path="/createRole" element={<AddRole />} />
              <Route path="/createRole/:id" element={<AddRole />} />
              <Route
                exact
                path="/clinicOnboard"
                element={<ClinicOnboarding />}
              />
              <Route
                exact
                path="/clinicOnboard/:id"
                element={<ClinicOnboarding />}
              />
              <Route exact path="/staffList" element={<StaffList />} />
              <Route exact path="/packages" element={<Packages />} />
              <Route
                exact
                path="/testBookingTab/:tabValue"
                element={<TestBookingTab />}
              />
              <Route
                exact
                path="/staffOnboarding"
                element={<StaffOnboardingForm />}
              />
              <Route
                exact
                path="/staffOnboarding/:id"
                element={<StaffOnboardingForm />}
              />
              <Route
                exact
                path="/plansAndProcedure"
                element={<PlansProcedure />}
              />
              <Route
                exact
                path="/procedures/:speciality"
                element={<Procedures />}
              />
              <Route exact path="/coupons" element={<Coupons />} />
              <Route exact path="/addcoupon" element={<AddCoupon />} />
              <Route exact path="/editcoupon/:id" element={<EditCoupon />} />
              <Route
                exact
                path="/couponbillinghistory/:couponcode"
                element={<CouponsBillingHistory />}
              />
              <Route
                exact
                path="/plansAndPricing"
                element={<PlansAndPricing />}
              />
              <Route
                exact
                path="retailPlansAndPricing"
                element={<RetailPlans />}
              />
              <Route exact path="/addplan" element={<AddPlan />} />
              <Route
                exact
                path="/addretailplan"
                element={<AddandEditRetailplan />}
              />
              <Route exact path="/editplan/:id" element={<EditPlan />} />
              <Route
                exact
                path="/editretailplan/:id"
                element={<AddandEditRetailplan />}
              />
              <Route
                exact
                path="/planbillinghistory/:planname"
                element={<PlanBillingHistory />}
              />
              <Route
                exact
                path="/customerlisting"
                element={<CustomerListing />}
              />
              <Route
                exact
                path="/retailPlanscustomerlisting"
                element={<RetailPlanCustomerListing />}
              />
              <Route
                exact
                path="/viewRetailCustomerdetails/:id"
                element={<ViewRetailCustomersDetails />}
              />
              <Route exact path="/addcustomer" element={<AddCustomer />} />
              <Route exact path="/editcustomer/:id" element={<AddCustomer />} />
              <Route
                exact
                path="/clinicappointments/:dateRange"
                element={<ClinicAppointments />}
              />
              <Route
                exact
                path="/passwordchange"
                element={<PasswordChange />}
              />
              <Route
                exact
                path="/appointmentrequests"
                element={<AppointmentRequests />}
              />
              <Route
                exact
                path="/appointmentBilling"
                element={<AppointmentBillingFirst />}
              />
              <Route
                exact
                path="/billingAndSettlementTable"
                element={<BillingAndSettlementTable />}
              />
              <Route
                exact
                path="/billingAndSettlementTable/:id"
                element={<SettlementStatus />}
              />
              <Route
                exact
                path="/paymentStatus/:id"
                element={<PaymentStatus />}
              />
              <Route
                exact
                path="/clinicsclinicview"
                element={<ClinicsClinicView />}
              />
              <Route
                exact
                path="/appointmentinformation/:userId"
                element={<AppointmentInfo />}
              />
              <Route
                exact
                path="/pastappointmentinformation/:userId"
                element={<PastAppointmentInfo />}
              />
              <Route
                exact
                path="/startappointment/:speciality/:appointmentId/:customeruniqueId/:customerNumber/:selectedPlanName/:member"
                element={<StartAppointment />}
              />
              <Route
                exact
                path="/appointments/:dateRange"
                element={<UpComing />}
              />
              <Route
                exact
                path="/newappointments"
                element={<NewAppointment />}
              />
              <Route exact path="/bookYourTest" element={<BookYourTest />} />
              <Route
                exact
                path="/testOrderHistory/:tabValue"
                element={<TestOrderHistory />}
              />
              <Route
                exact
                path="/RescheduleOrder/:id"
                element={<RescheduleDateAndTimeSlot />}
              />
              <Route
                exact
                path="/healthianOrder/:id"
                element={<IndividualHealthianOrderDetails />}
              />
              <Route
                exact
                path="/testOrderStatus/:id"
                element={<TestOrderStatus />}
              />
              <Route exact path="/allclinicslist" element={<AllClinics />} />
              <Route exact path="/clinicView" element={<ClinicView />} />
              <Route
                exact
                path="/customerclinicView"
                element={<CustomerClinicView />}
              />
              <Route
                exact
                path="/customerclinicView/:id"
                element={<CustomerClinicView />}
              />
              <Route
                exact
                path="/customerappointmentsRequest"
                element={<CustomerAppointmentRequest />}
              />
              <Route
                exact
                path="/customerPlansAndCoupons"
                element={<CustomerPlansAndCoupons />}
              />
              <Route
                exact
                path="/customerPlansAndCoupons"
                element={<CustomerPlansAndCoupons />}
              />
              <Route
                exact
                path="/PlansAndCoupons"
                element={<PlansAndCouponsTabs />}
              />
              <Route
                exact
                path="/particularCoupon"
                element={<ParticularCoupon />}
              />
              <Route
                exact
                path="/coupondetails/:id"
                element={<CouponDetails />}
              />
              <Route
                exact
                path="/planhistory/:planname"
                element={<PlanHistory />}
              />
              <Route
                exact
                path="/couponhistory/:couponcode"
                element={<CouponHistory />}
              />
              <Route
                exact
                path="/customerpastappointmentinformation/:appointmentId"
                element={<CustomerPastDetails />}
              />
              <Route
                exact
                path="/cseAppointmentBooking"
                element={<CseBookingAppointment />}
              />
              <Route exact path="/cseAppointments" element={<Appointments />} />
              {/* <Route exact path="/paymentstatus" element={<PaymentStatus />} /> */}
              <Route
                exact
                path="/profile/:uniqueId"
                element={<CustomerProfile />}
              />
              <Route
                exact
                path="/pendingPayment"
                element={<PaymentPending />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
