import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";
import { useState, useEffect } from "react";
import ClinicDetails from "./ClinicDetails";
import ClinicFinance from "./ClinicFinance";
import ClinicImages from "./ClinicImages";
import DoctorDetails from "./DoctorDetails";
import {
  changeAssignedPerson,
  createClinicDetails,
  getCitiesList,
  getParticularClinicData,
  retriggerMail,
  sendClinicLoginCredsMail,
  uploadClinicDocuments,
} from "../../services/clinicOnboarding";
import { useNavigate, useParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import Loader from "../Loader";
import axios from "axios";
import ClinicWorkingHours from "./ClinicWorkingHours";
import { CssTextField } from "../../styles/textFields";
import { getStaffRoleList } from "../../services/staffOnboarding";
import config from "../../config.json";
import { getClinicSpecialities } from "../../services/PlansAndProcedure";
import { manuallyVerifyTheClinic } from "../../services/customerOnboarding";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";

const ClinicOnboarding = () => {
  const tokens = localStorage.getItem("token");
  const createdBy = JSON.parse(tokens)?.username;
  const role = JSON.parse(tokens)?.role;
  const designation = JSON.parse(tokens)?.designation;
  const { permissionsData, loading, setLoading } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "clinic"
  );
  let clinicPermissions = clinicPermissionsModule[0]?.permissions;
  let ClinicDetailsPermission = clinicPermissions?.find(
    (item) => item.action === "Clinic Details"
  )?.permission;
  let AssignToPermission = clinicPermissions?.find(
    (item) => item.action === "Assign To"
  )?.permission;
  let MailTriggerPermission = clinicPermissions?.find(
    (item) => item.action === "Mail Trigger"
  )?.permission;
  let VerifyPermission = clinicPermissions?.find(
    (item) => item.action === "Verify"
  )?.permission;
  let EditPermission = clinicPermissions?.find(
    (item) => item.action === "Edit"
  )?.permission;
  // let EditPermission=false
  console.log("clinicdetailspermission", MailTriggerPermission);

  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const mailSent = () => {
    toast.success("A mail has been sent successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const { id } = useParams();

  const navigate = useNavigate();

  const initialValue_ClinicDetails = {
    clinicName: "",
    clinicType: [],
    state: "",
    ownerName: "",
    pincode: "",
    email: "",
    category: "",
    number: "",
    frontDeskNumber: "",
    address: "",
    city: "",
    latitude: "",
    longitude: "",
    id: "",
    givingDiscount: "",
    discountAmount: "",
    pocName: "",
    pocNumber: "",
  };

  const initialValue_FinanceDetails = {
    pan: "",
    accountNumber: "",
    ifsc: "",
    accountHolder: "",
    id: "",
  };

  const [newValue_ClinicDetails, setNewValue_ClinicDetails] = useState(
    initialValue_ClinicDetails
  );
  const [newValue_FinanceDetails, setNewValue_FinanceDetails] = useState(
    initialValue_FinanceDetails
  );
  const [open, setOpen] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const [image, setImage] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [edit, setEdit] = useState(false);
  const [city, setCity] = useState([]);
  const [typeOfClinicsOptions, setTypeOfClinicsOptions] = useState([]);

  const getSpecialitiesList = async () => {
    const { data } = await getClinicSpecialities();
    console.log("data: ", data);
    if (data) {
      const opts = data.map((item) => item.speciality);
      setTypeOfClinicsOptions(opts);
    }
  };
  const getCityList = async () => {
    const { data } = await getCitiesList();
    if (data) {
      console.log("data: ", data);
      setCity(data);
    }
  };

  useEffect(() => {
    getSpecialitiesList();
    getCityList();
  }, []);

  const [initialValues_DoctorDetails, setinitialValues_DoctorDetails] =
    useState([
      {
        name: "",
        specialisationAndDegree: [
          { specialisation: "", degree: "", other: "" },
        ],
        accreditation: "",
        experience: "",
        practiceSince: "",
        image: "",
        id: "",
      },
    ]);

  const forms_ClinicDetails = [
    {
      lable: "Clinic Name",
      type: "text",
      placeholder: "Clinic Name",
      value: "clinicName",
    },
    {
      lable: "Type Of Clinic",
      type: "dropdown",
      placeholder: "Select Type Of Clinic",
      value: "clinicType",
      options: [
        "Dental",
        "ENT",
        "Eye",
        "Gynecology",
        "Dietician",
        "Gastroentrology",
      ],
    },
    {
      lable: "State",
      type: "text",
      placeholder: "Enter Your State Name",
      value: "state",
    },
    {
      lable: "Owner's Name",
      type: "text",
      placeholder: "Enter Full Name",
      value: "ownerName",
    },
    {
      lable: "PinCode",
      type: "number",
      placeholder: "Enter PinCode",
      value: "pincode",
    },

    {
      lable: "Latitude",
      type: "number",
      placeholder: "Latitude",
      value: "latitude",
    },
    {
      lable: "Longitude",
      type: "number",
      placeholder: "Longitude",
      value: "longitude",
    },

    {
      lable: "Email Id",
      type: "text",
      placeholder: "Enter Your Email",
      value: "email",
    },
    {
      lable: "Category Of Clinic",
      type: "dropdown",
      placeholder: "Select Category Of Clinic",
      value: "category",
      options: ["Ultra Premium", "Premium", "Preferred", "Affordable", "Value"],
    },
    {
      lable: "Contact Number",
      type: "number",
      placeholder: "Enter Your Number",
      value: "number",
    },
    {
      lable: "Front Desk Contact Number",
      type: "number",
      placeholder: "Enter The Phone Number",
      value: "frontDeskNumber",
    },
    {
      lable: "POC Name",
      type: "text",
      placeholder: "Enter the POC's Name",
      value: "pocName",
    },
    {
      lable: "POC Number",
      type: "number",
      placeholder: "Enter The POC's Number",
      value: "pocNumber",
    },
    {
      lable: "Address",
      type: "text",
      placeholder: "Enter Your Address",
      value: "address",
    },

    {
      lable: "City",
      type: "dropdown",
      placeholder: "City",
      value: "city",
      options: city,
    },
  ];

  const forms_FinanceDetails = [
    {
      lable: "PAN Number",
      type: "text",
      placeholder: "Enter PAN Number",
      value: "pan",
    },
    {
      lable: "Account Holder Name",
      type: "text",
      placeholder: "Enter Account Holder Name",
      value: "accountHolder",
    },
    {
      lable: "Bank Account Number",
      type: "number",
      placeholder: "Enter Account Number",
      value: "accountNumber",
    },
    {
      lable: "IFSC Code",
      type: "text",
      placeholder: "Enter IFSC Code",
      value: "ifsc",
    },
  ];
  // {lable:"", type:"", placeholder:"", value:""},

  const validationSchema_ClinicDetails = yup.object({
    clinicType: yup
      .array()
      .min(1, "Should Have Atleast One Entry")
      .required("This Field Is Required"),
    clinicName: yup.string().required("This Field Is Required"),
    state: yup.string().required("This Field Is Required"),
    ownerName: yup.string().required("This Field Is Required"),
    givingDiscount: yup.string().required("This Field Is Required"),
    pincode: yup.string().required("This Field Is Required"),
    pocName: yup.string().required("This Field Is Required"),
    pocNumber: yup
      .string()
      .matches(/[6-9]{1}[0-9 ]{3}[0-9 ]{3}[0-9]{3}/, {
        message: "Invalid Mobile number",
        excludeEmptyString: false,
      })
      .required("This Field Is Required"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("This Field Is Required"),
    category: yup.string().required("This Field Is Required"),
    number: yup
      .string()
      .matches(/[6-9]{1}[0-9 ]{3}[0-9 ]{3}[0-9]{3}/, {
        message: "Invalid Mobile number",
        excludeEmptyString: false,
      })
      .required("This Field Is Required"),
    frontDeskNumber: yup
      .number()
      .required()
      .positive()
      .integer()
      .required("This Field Is Required"),
    address: yup.string().required("This Field Is Required"),
    city: yup.string().required("This Field Is Required"),
    latitude: yup.string().required("This Field Is Required"),
    longitude: yup.string().required("This Field Is Required"),
  });

  const validationSchema_FinanceDetails = yup.object({
    pan: yup.string().required("This Field Is Required"),
    accountNumber: yup.string().required("This Field Is Required"),
    ifsc: yup.string().required("This Field Is Required"),
    accountHolder: yup.string().required("This Field Is Required"),
  });

  // useEffect(() => {
  //   async function getImageBlob() {
  //     const {data}  = await getImagesS3(image);

  //     console.log("data: ", data);
  //     if (data) {
  //       // const imageBlob = await data.blob();
  //       // const reader = new FileReader();
  //       // reader.readAsDataURL(imageBlob);
  //       // reader.onloadend = () => {
  //       //   const base64data = reader.result;
  //       //   console.log("base64data: ", base64data);
  //       // };
  //       const base64String = btoa(String.fromCharCode(...new Uint8Array([data])));
  //       console.log('base64String: ', base64String);
  //         setImageSrc(data);

  //     }

  //   }

  //   image && getImageBlob();
  // }, [image]);

  const downloadFile = () => {
    console.log("Document", document);

    axios
      .get(config.imageUrl + "/" + document, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = window.document.createElement("a");
        link.href = url;
        link.setAttribute("download", "agreement.pdf");
        window.document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const formik_ClinicDetails = useFormik({
    enableReinitialize: true,
    initialValues: id ? newValue_ClinicDetails : initialValue_ClinicDetails,
    validationSchema: validationSchema_ClinicDetails,
    onSubmit: (values) => {
      setStep(step + 1);
    },
  });

  const [document, setDocument] = useState([]);
  console.log("document: ", document);
  const [submitting, setSubmitting] = useState(false);

  async function submitDocument(val) {
    var form_data = new FormData();
    console.log(document);
    const file = document;
    console.log("file: ", file);

    form_data.append("uniqueId", val);
    form_data.append("createdBy", createdBy);
    form_data.append("clinicDocument", file);

    try {
      const { data } = await uploadClinicDocuments(form_data);
      console.log("value: ", data);

      if (data) {
        if (data === "Mail Sent Successfully") {
          mailSent();
        }
        success();
        setSubmitting(false);
        navigate("/user/clinicList");
      }
    } catch (er) {
      error();
      setSubmitting(false);
    }
  }

  const submitClinicDetails = async (val) => {
    try {
      console.log("val: ", val);
      setSubmitting(true);
      const { data } = await createClinicDetails(val);

      if (data) {
        // success();
        submitDocument(data);
        // setSubmitting(false);
        // navigate("/user/clinicList");
      } // var form_data = new FormData();
      // console.log(document);
      // const file=document;
      // console.log('file: ', file);

      // form_data.append("uniqueId", data);
      // form_data.append("createdBy", createdBy);
      // form_data.append("clinicDocument", file);
      // for (const pair of form_data.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`);
      // }
      // const { values } = await uploadClinicDocuments(form_data);
      // console.log('value: ', values);

      // if (values) {
      //   success();
      //   setSubmitting(false)
      //   navigate("/user/clinicList")
      // }
      console.log("data: ", data);
    } catch (err) {
      error();
      setSubmitting(false);
    }
  };

  const retriggeringMail = async () => {
    try {
      const { data } = await retriggerMail(id);
      console.log("data: ", data);
      if (data === "Mail Sent Successfully") {
        mailSent();
      }
    } catch (err) {
      error();
    }
  };

  const [images, setImages] = useState([
    { title: "Front Desk", value: "frontDesk", image: "", id: "" },
    {
      title: "Chairs/Treatment Room",
      value: "treatmentRoom",
      image: "",
      id: "",
    },
    { title: "Front Facia", value: "frontFacia", image: "", id: "" },
    { title: "Autoclaving Equipment", value: "autoClaving", image: "", id: "" },
    { title: "Consultant Room", value: "consultantRoom", image: "", id: "" },
    { title: "Others", value: "other", image: "", id: "" },
  ]);

  const [workingHours, setWorkingHours] = useState([
    {
      week: "Monday",
      status: true,
      morningStartTime: "",
      morningEndTime: "",
      eveningStartTime: "",
      eveningEndTime: "",
      order: 1,
    },
    {
      week: "Tuesday",
      status: true,
      morningStartTime: "",
      morningEndTime: "",
      eveningStartTime: "",
      eveningEndTime: "",
      order: 2,
    },
    {
      week: "Wednesday",
      status: true,
      morningStartTime: "",
      morningEndTime: "",
      eveningStartTime: "",
      eveningEndTime: "",
      order: 3,
    },
    {
      week: "Thrsday",
      status: true,
      morningStartTime: "",
      morningEndTime: "",
      eveningStartTime: "",
      eveningEndTime: "",
      order: 4,
    },
    {
      week: "Friday",
      status: true,
      morningStartTime: "",
      morningEndTime: "",
      eveningStartTime: "",
      eveningEndTime: "",
      order: 5,
    },
    {
      week: "Saturday",
      status: true,
      morningStartTime: "",
      morningEndTime: "",
      eveningStartTime: "",
      eveningEndTime: "",
      order: 6,
    },
    {
      week: "Sunday",
      status: true,
      morningStartTime: "",
      morningEndTime: "",
      eveningStartTime: "",
      eveningEndTime: "",
      order: 7,
    },
  ]);

  const [onboardingList, setOnboardingList] = useState([]);
  const [assignedPerson, setAssignedPerson] = useState();
  const [mailCaptures, setMailCaptures] = useState();
  const [createdAt, setCreatedAt] = useState();

  useEffect(() => {
    let list;
    const getList = async () => {
      const { data } = await getStaffRoleList("Onboarding Executive");

      if (data) {
        console.log("data: ", data);
        list = data;
        setOnboardingList(data);
        getValues();
      }
    };
    getList();

    async function getValues() {
      if (!id) return;
      setLoading(true);
      const { data } = await getParticularClinicData(id);
      console.log("data: ", data);
      if (data) {
        const assignedPeople = list.filter(
          (item) => item.displayName === data.initialValue_ClinicDetails?.assign
        );
        const mailInfo = {};
        mailInfo.sentAt = data.createdAt;
        mailInfo.clinicAgreement =
          data?.initialValue_ClinicDetails.clinicAgreement;
        mailInfo.clinicAgreementAt =
          data?.initialValue_ClinicDetails.clinicAgreementAt;
        mailInfo.clinicAgreementIP =
          data?.initialValue_ClinicDetails.clinicAgreementIP;
        mailInfo.clinicDocument =
          data?.initialValue_ClinicDetails.clinicDocument;
        console.log("mailInfo: ", mailInfo);
        setMailCaptures(mailInfo);
        setAssignedPerson(assignedPeople[0]);
        setDocument(data.initialValue_ClinicDetails.clinicDocument);
        setNewValue_ClinicDetails(data.initialValue_ClinicDetails);
        setNewValue_FinanceDetails(data.initialValue_FinanceDetails);
        setinitialValues_DoctorDetails(data.initialValues_DoctorDetails);
        setWorkingHours(data?.clinicTimingDetailsRequests);
        setDocument(data?.initialValue_ClinicDetails.clinicDocument);
        setCreatedAt(data?.createdAt);
        console.log(
          "data?.clinicDocument: ",
          data?.initialValue_ClinicDetails.clinicDocument
        );
        const img = [...images];
        data.clinicImageRequest.map((item) => {
          console.log("item: ", item);
          const ind = img.findIndex((items) => items.value === item.type);
          console.log("ind: ", ind);
          img[ind].image = item.image;
          img[ind].id = item.id;
          setImages(img);
          return null;
        });
        setLoading(false);
      }
    }
  }, [id]);

  const setCredMail = async () => {
    const { data } = await sendClinicLoginCredsMail(id);
    console.log("data: ", data);
  };

  const formik_FinanceDetails = useFormik({
    enableReinitialize: true,
    initialValues: id ? newValue_FinanceDetails : initialValue_FinanceDetails,
    validationSchema: validationSchema_FinanceDetails,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log("Doctor", initialValues_DoctorDetails);
      console.log("images: ", images);
      const imageSubmit = images
        .filter((item) => {
          console.log("item: ", item, item.image);

          return item.image;
        })
        .map((item) => ({ type: item.value, image: item.image, id: item.id }));
      console.log("imageSubmit: ", imageSubmit);
      console.log("initialValue_ClinicDetails", formik_ClinicDetails.values);
      const data = {
        initialValue_ClinicDetails: formik_ClinicDetails.values,
        initialValues_DoctorDetails: initialValues_DoctorDetails,
        initialValue_FinanceDetails: values,
        clinicTimingDetailsRequests: workingHours,
        clinicImageRequest: imageSubmit, //!might have to change it
        createdBy: createdBy,
        uniqueId: id ? id : "",
      };

      console.log("Dataaa", data);
      submitClinicDetails(data);
      // setStep(step + 1);
    },
  });

  const [step, setStep] = useState(1);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vh",
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 2,
  };

  const handleAssignedChange = async () => {
    if (!assignedPerson) return;
    const val = {};
    console.log("assignedPerson: ", assignedPerson);
    val.id = newValue_ClinicDetails.id;
    val.assign = assignedPerson.displayName;
    val.updatedBy = createdBy;
    try {
      const { data } = await changeAssignedPerson(val);
      console.log("data: ", data);
      if (data === "Success") {
        setAssigned(false);
        success();
      }
    } catch (er) {
      error();
    }
  };

  const [verify, setVerify] = useState(false);

  const verifyManually = async (val) => {
    const { data } = await manuallyVerifyTheClinic(val);
    if (data === "Clinic Verifies Sucessfully") {
      success();
      setVerify(false);
      navigate("/user/clinicList");
    }
  };

  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  return (
    <React.Fragment className="clinicOnboarding">
      {step === 2 && (
        <div>
          <div className="flexAlagAlag">
            <Typography variant="h5" fontSize="17px">
              Doctor Details
            </Typography>
            <Button
              variant="contained"
              disabled={id && !edit ? true : false}
              onClick={() => {
                const data = [...initialValues_DoctorDetails];
                data.push({
                  name: "",
                  specialisationAndDegree: [
                    { specialisation: "", degree: "", other: "" },
                  ],
                  accreditation: "",
                  experience: "",
                  practiceSince: "",
                  image: "",
                  id: "",
                });

                setinitialValues_DoctorDetails(data);
              }}
              sx={{
                "&:hover": { backgroundColor: "#2d256c!important" },
                fontSize: "12px",
                padding: "8px 15px",
              }}
            >
              Add Another Doctor
            </Button>
          </div>

          <DoctorDetails
            values={initialValues_DoctorDetails}
            changeValues={setinitialValues_DoctorDetails}
            step={step}
            setStep={setStep}
            edit={edit}
            setOpen={setOpen}
            setImage={setImage}
          />
        </div>
      )}
      {step === 1 && ClinicDetailsPermission && (
        <div>
          <div className="flexAlagAlag">
            <Typography variant="h5" fontSize="17px">
              Clinic Details
            </Typography>
            {id && (
              <div>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  {moment(createdAt).format("dddd, Do MMMM YYYY")}
                </Typography>
                {AssignToPermission && (
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      ml: 2,
                      "&:hover": { backgroundColor: "#2d256c!important" },
                      fontSize: "12px",
                      padding: "8px 15px",
                    }}
                    onClick={() => {
                      setAssigned(true);
                    }}
                  >
                    Assigned To
                  </Button>
                )}
                {AssignToPermission && (
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      ml: 2,
                      "&:hover": { backgroundColor: "#2d256c!important" },
                      fontSize: "12px",
                      padding: "8px 15px",
                    }}
                    onClick={() => {
                      setCredMail();
                    }}
                  >
                    Send Creds
                  </Button>
                )}
                {newValue_ClinicDetails.clinicAgreement !== "yes" &&
                  (role === "Admin" ||
                    designation === "Team Lead" ||
                    designation === "Admin" ||
                    designation === "Customer Support Executive") &&
                  VerifyPermission && (
                    <Button
                      variant="contained"
                      sx={{
                        color: "#fff",
                        ml: 2,
                        "&:hover": { backgroundColor: "#2d256c!important" },
                        fontSize: "12px",
                        padding: "8px 15px",
                      }}
                      onClick={() => {
                        setVerify(true);
                      }}
                    >
                      Verify
                    </Button>
                  )}
                {MailTriggerPermission && (
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      mr: 2,
                      ml: 2,
                      "&:hover": { backgroundColor: "#2d256c!important" },
                      fontSize: "12px",
                      padding: "8px 15px",
                    }}
                    onClick={() => {
                      retriggeringMail();
                    }}
                  >
                    Retrigger Mail
                  </Button>
                )}
                {EditPermission && (
                  <Edit
                    onClick={() => {
                      setEdit(true);
                    }}
                  ></Edit>
                )}
              </div>
            )}
          </div>
          <ClinicDetails
            onSubmit={formik_ClinicDetails.handleSubmit}
            fields={forms_ClinicDetails}
            formik={formik_ClinicDetails}
            edit={edit}
          />
          {id && (
            <>
              <Typography variant="h5" sx={{ mt: 4, mb: 2, fontSize: "17px" }}>
                Terms & Conditions Email
              </Typography>
              <Card>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <h4 style={{ fontSize: "14px" }}>
                      Terms & Conditions Email
                    </h4>
                  </Grid>
                  <Grid item xs={4} style={{ fontSize: "14px" }}>
                    {" "}
                    Sent On :{" "}
                    {moment(mailCaptures?.sentAt).format(
                      "dddd, Do MMMM YYYY"
                    )}{" "}
                  </Grid>

                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <h4 style={{ fontSize: "14px" }}>Terms and Conditions</h4>
                  </Grid>
                  <Grid item xs={4} style={{ fontSize: "14px" }}>
                    {mailCaptures?.clinicAgreement === "yes"
                      ? "Approved At"
                      : "Pending"}{" "}
                    {mailCaptures?.clinicAgreement === "yes" &&
                      moment(mailCaptures?.clinicAgreementAt).format(
                        "dddd, Do MMMM YYYY"
                      )}
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <h4 style={{ fontSize: "14px" }}>IP Address</h4>
                  </Grid>
                  <Grid item xs={4}>
                    {mailCaptures?.clinicAgreementIP}
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </Card>
            </>
          )}
        </div>
      )}
      {step === 3 && (
        <div>
          <Typography variant="h5" fontSize="17px">
            Financial Details
          </Typography>
          <form
            id="Financial_Details"
            onSubmit={formik_FinanceDetails.handleSubmit}
          >
            <ClinicFinance
              fields={forms_FinanceDetails}
              formik={formik_FinanceDetails}
              edit={edit}
            />

            {id && !edit ? (
              <>
                {" "}
                <Typography variant="h5" mt={2}>
                  Clinic Pictures
                </Typography>
                <Grid container spacing={2}>
                  {images
                    .filter((val) => val.image)
                    .map((item) => (
                      <>
                        <Grid item xs={12} md={6}>
                          <Typography
                            mt={1}
                            mb={1}
                            variant="subtitle2"
                            fontWeight="bold"
                          >
                            {item.title}
                          </Typography>
                          <Button
                            Button
                            variant="contained"
                            sx={{
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#2d256c!important",
                              },
                              fontSize: "12px",
                              padding: "8px 15px",
                            }}
                            onClick={() => {
                              console.log("item.image: ", item.image);
                              setOpen(true);
                              setImage(item.image);
                            }}
                          >
                            View Image
                          </Button>
                        </Grid>
                      </>
                    ))}
                  {document && (
                    <Grid item xs={12} md={6}>
                      <Typography
                        mt={1}
                        mb={1}
                        variant="subtitle2"
                        fontWeight="bold"
                      >
                        Agreement
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ color: "#fff" }}
                        onClick={() => downloadFile()}
                      >
                        Download
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Typography variant="h5" mt={2}>
                  Clinic Pictures
                </Typography>
                <ClinicImages
                  value={images}
                  onChange={setImages}
                  doc={document}
                  setDoc={setDocument}
                />
              </>
            )}
            <Typography mt={3} variant="h5">
              Clinic Working Hours
            </Typography>
            <ClinicWorkingHours
              fields={workingHours}
              setFields={setWorkingHours}
              edit={edit}
            />
            <div className="buttonNext">
              <Button
                variant="contained"
                onClick={() => {
                  setStep(step - 1);
                }}
                sx={{
                  width: "15em",
                  "&:hover": { backgroundColor: "#282828!important" },
                  fontSize: "12px",
                  padding: "8px 15px",
                }}
              >
                Back
              </Button>
              {id && !edit ? null : (
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "15em",
                    marginLeft: 2,
                    "&:hover": { backgroundColor: "#2d256c!important" },
                    fontSize: "12px",
                    padding: "8px 15px",
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
          </form>
        </div>
      )}

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setImageSrc("");
          setImage("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Image
          </Typography>

          {image ? (
            <img
              src={config.imageUrl + image}
              style={{ width: "40vh" }}
              alt="ImageHere"
            />
          ) : (
            <>
              <Loader />
            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={submitting}
        onClose={() => {
          // setSubmitting(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Loader />
        </Box>
      </Modal>
      <Modal
        open={assigned}
        onClose={() => {
          setAssigned(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Assigned To:
          </Typography>
          <Autocomplete
            disableClearable
            // disabled={id && !edit ? true : false}
            options={onboardingList}
            getOptionLabel={(option) => option?.displayName}
            name="teamLeadAssigned"
            onChange={(event, value) => {
              setAssignedPerson(value);
              // formik_staffRegistration.setFieldValue("teamLeadAssigned", value);
            }}
            value={assignedPerson}
            fullWidth
            size="small"
            renderInput={(params) => (
              <CssTextField
                disabled={id && !edit ? true : false}
                {...params}
                fullWidth
                placeholder="Select The Onboarding Executive"
                variant="outlined"
                type="text"
              />
            )}
          />
          <div style={{ margin: "auto", width: "25%" }}>
            <Button
              sx={{
                mt: 2,
                "&:hover": { backgroundColor: "#2d256c!important" },
                fontSize: "12px",
                padding: "8px 15px",
              }}
              variant="contained"
              onClick={() => {
                handleAssignedChange();
              }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={verify}
        onClose={() => setVerify(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ textAlign: "center", marginBottom: 3 }}>
            <Typography variant="body">Confirm To Verify? </Typography>
          </div>
          <Box sx={{ minWidth: 120 }}></Box>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                mr: 2,
                "&:hover": { backgroundColor: "#2d256c!important" },
                fontSize: "12px",
                padding: "8px 15px",
              }}
              onClick={() => {
                setVerify(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                verifyManually(newValue_ClinicDetails?.id);
              }}
              sx={{
                "&:hover": { backgroundColor: "#2d256c!important" },
                fontSize: "12px",
                padding: "8px 15px",
              }}
            >
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ClinicOnboarding;
