import React, { useState } from "react";
import "./Packages.scss";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  OutlinedInput,
  Pagination,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UpdateTheHealthiansPackage } from "../../services/HealthiansApi";

const PackageList = ({
  currentPage,
  setCurrentPage,
  setSearchQuery,
  searchQuery,
  packageLists,
  refreshPackageList,
}) => {
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [selectEditableValue, setSelectEdiatableValue] = useState({});
  const itemsPerPage = 5;

  const filteredPackages = packageLists.filter((item) => {
    const searchTerm = searchQuery.toLowerCase();
    return item.name.toLowerCase().includes(searchTerm);
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPackages = filteredPackages.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setSearchQuery(searchQuery);
  };

  const handleViewOpenModel = (pack) => {
    setSelectedPackage(pack);
    setViewOpen(true);
  };
  const handleViewCloseModel = () => {
    setViewOpen(false);
  };
  const handleEditOpenModel = (pack) => {
    setEditOpen(true);
    setSelectEdiatableValue(pack);
    handleViewCloseModel();
  };
  const handleEditCloseModel = () => {
    setEditOpen(false);
    refreshPackageList();
  };
  return (
    <div className="packageListMainDiv">
      {currentPackages.map((pack, id) => {
        const isActive = pack.our_status === "Active";
        return (
          <Card className="packageListCard" key={pack.id}>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item sm={8} xs={6} md={8} className="firstGrid">
                <Typography variant="h5">{pack.name}</Typography>
                <div className="genderAndAge">
                  <div className="gender">
                    <Typography variant="h6">Gender</Typography>
                    <Typography>{pack.gender}</Typography>
                  </div>

                  <div className="age">
                    <Typography variant="h6">Age</Typography>
                    <Typography>
                      {pack.age_group && JSON.parse(pack.age_group)[0]}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4} xs={6} md={4} className="secondGrid">
                <Grid
                  container
                  spacing={2}
                  sx={{ alignItems: "center", padding: "10px 0px" }}
                >
                  <Grid item sm={6} xs={6} md={6} className="healthianPrice">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <div>
                        <Typography variant="h6">Healthians Price</Typography>
                        <Typography sx={{ color: "rgba(0, 160, 168, 1)" }}>
                          ₹{Number(pack.price).toLocaleString()}
                        </Typography>
                      </div>
                      {isActive && (
                        <div>
                          <Typography variant="h6">Our Price</Typography>
                          <Typography sx={{ color: "#2D256C" }}>
                            ₹{Number(pack.our_price).toLocaleString()}
                          </Typography>
                        </div>
                      )}
                    </div>
                    {isActive && (
                      <div className="saleprice">
                        <Typography variant="h6">Sale Price</Typography>
                        <Typography sx={{ color: "#2D256C" }}>
                          ₹{Number(pack.price_after_discount).toLocaleString()}
                        </Typography>
                      </div>
                    )}
                  </Grid>

                  <Grid item sm={6} xs={6} md={6} className="action">
                    <div
                      style={{
                        display: isActive ? "flex" : "",
                        flexWrap: "wrap",
                        rowGap: "10px",
                        columnGap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      {isActive && (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isActive}
                              name="Active"
                              sx={{
                                "& .MuiSwitch-switchBase": {
                                  color: "#d83f87",
                                },
                                "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                  backgroundColor: "#d83f87!important",
                                },
                                "& .Mui-checked": {
                                  color: "#d83f87",
                                },
                                "& .Mui-checked + .MuiSwitch-track": {
                                  backgroundColor: "#d83f87",
                                },
                              }}
                            />
                          }
                          label="Active"
                          labelPlacement="start"
                        />
                      )}
                      <Button
                        variant="outlined"
                        sx={{
                          backgroundColor: "#2D256C",
                          textAlign: "left",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "10px",
                          display: "flex",
                          justifyContent: "space-around",
                          "&:hover": {
                            backgroundColor: "#2D256C!important",
                          },
                        }}
                        size="small"
                        onClick={() => handleViewOpenModel(pack)}
                      >
                        <VisibilityIcon sx={{ fontSize: "14px" }} /> View
                      </Button>
                      <Button
                        variant="outlined"
                        id="viewbtn"
                        sx={{
                          backgroundColor: "#D83F87",
                          textAlign: "left",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "10px",
                          display: "flex",
                          justifyContent: "space-around",
                          "&:hover": {
                            backgroundColor: "#D83F87!important",
                          },
                        }}
                        size="small"
                        onClick={() => handleEditOpenModel(pack)}
                      >
                        <BorderColorIcon sx={{ fontSize: "14px" }} /> Edit
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        );
      })}
      <Stack
        spacing={2}
        sx={{
          marginTop: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Pagination
          count={Math.ceil(filteredPackages.length / itemsPerPage)} // Calculate total pages
          page={currentPage}
          onChange={handlePageChange}
          size="large"
        />
      </Stack>

      <ViewModel
        viewOpen={viewOpen}
        handleViewCloseModel={handleViewCloseModel}
        selectedPackage={selectedPackage}
        editOpen={editOpen}
        handleEditOpenModel={handleEditOpenModel}
        handleEditCloseModel={handleEditCloseModel}
      />
      <EditModel
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        handleEditOpenModel={handleEditOpenModel}
        handleEditCloseModel={handleEditCloseModel}
        selectEditableValue={selectEditableValue}
      />
    </div>
  );
};

export default PackageList;

const ViewModel = ({
  viewOpen,
  handleViewCloseModel,
  selectedPackage,
  editOpen,
  selectEditableValue,
  handleEditOpenModel,
  handleEditCloseModel,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px solid grey",
    borderRadius: 2,
  };

  return (
    <div className="viewModelDiv">
      <Modal
        open={viewOpen}
        onClose={handleViewCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="titleDiv">
            <Typography variant="h5">{selectedPackage.name}</Typography>
            <div style={{ display: "flex", gap: "5px" }}>
              <Button
                variant="outlined"
                id="viewbtn"
                sx={{
                  backgroundColor: "#D83F87",
                  textAlign: "left",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-around",
                  "&:hover": {
                    backgroundColor: "#D83F87!important",
                  },
                }}
                size="small"
                onClick={() => handleEditOpenModel(selectedPackage)}
              >
                <BorderColorIcon sx={{ fontSize: "14px" }} /> Edit
              </Button>
              <Button
                variant="outlined"
                id="viewbtn"
                sx={{
                  backgroundColor: "rgba(105, 105, 105, 1)",
                  textAlign: "left",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  "&:hover": {
                    backgroundColor: "rgba(105, 105, 105, 1)!important",
                  },
                }}
                size="small"
                onClick={() => handleViewCloseModel()}
              >
                <CancelPresentationIcon sx={{ fontSize: "14px" }} /> Cancel
              </Button>
            </div>
          </div>
          <div className="viewBody">
            <Typography className="about">
              {selectedPackage.description}
            </Typography>
            <div className="genderDetails">
              <div>
                <Typography variant="h6">Gender</Typography>
                <Typography sx={{ color: "#2D256C" }}>
                  {selectedPackage.gender}
                </Typography>
              </div>

              <div>
                <Typography variant="h6">Age</Typography>
                <Typography sx={{ color: "#2D256C" }}>
                  {selectedPackage.age_group &&
                    JSON.parse(selectedPackage.age_group)[0]}
                </Typography>
              </div>
              <div>
                <Typography variant="h6">Fasting</Typography>
                <Typography sx={{ color: "#2D256C" }}>
                  {selectedPackage.fasting}
                </Typography>
              </div>
              <div>
                <Typography variant="h6">Reporting Time</Typography>
                <Typography sx={{ color: "#2D256C" }}>
                  {selectedPackage.reporting_time}
                </Typography>
              </div>
              <div>
                <Typography variant="h6">Fasting Time</Typography>
                <Typography sx={{ color: "#2D256C" }}>
                  {selectedPackage.fasting_time}
                </Typography>
              </div>
            </div>
            {/* <div className="testDetails">
              <Typography variant="h5">Test Details</Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {selectedPackage.testDetails?.map((el, id) => {
                  return (
                    <div key={id}>
                      <ul>
                        <li>{el}</li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div> */}
            <div className="categories">
              <Typography
                variant="h4"
                sx={{
                  color:
                    selectedPackage.our_status === "Active"
                      ? "rgba(0, 0, 0, 1)!important"
                      : "rgba(181, 181, 181, 1)!important",
                }}
              >
                Speciality
              </Typography>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {selectedPackage.our_status === "Active" ? (
                  selectedPackage.categories?.map((el, id) => {
                    return (
                      <div key={id + 1}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={el}
                              checked={selectedPackage.categories.includes(el)}
                            />
                          }
                          label={el}
                        />
                      </div>
                    );
                  })
                ) : (
                  <span style={{ color: "rgba(181, 181, 181, 1)" }}>N/A</span>
                )}
              </div>
            </div>
            <div className="Prices">
              <Typography
                variant="h4"
                sx={{
                  color:
                    selectedPackage.our_status === "Active"
                      ? "rgba(0, 0, 0, 1)!important"
                      : "rgba(181, 181, 181, 1)!important",
                }}
              >
                Prices
              </Typography>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="healthianPrice">
                  <Typography variant="h6">Healthians Price</Typography>
                  <Typography>
                    {Number(selectedPackage.price).toLocaleString()}
                  </Typography>
                </div>
                <div className="ourPrice">
                  <Typography
                    variant="h6"
                    sx={{
                      color:
                        selectedPackage.our_status === "Active"
                          ? "rgba(106, 106, 106, 1)!important"
                          : "rgba(181, 181, 181, 1)!important",
                    }}
                  >
                    Our Price
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        selectedPackage.our_status === "Active"
                          ? "#2D256C"
                          : "rgba(181, 181, 181, 1)!important",
                    }}
                  >
                    {selectedPackage.our_status === "Active"
                      ? Number(selectedPackage.our_price).toLocaleString()
                      : "N/A"}
                  </Typography>
                </div>
                <div className="salePrice">
                  <Typography
                    variant="h5"
                    sx={{
                      color:
                        selectedPackage.our_status === "Active"
                          ? "#d83f87"
                          : "rgba(181, 181, 181, 1)!important",
                    }}
                  >
                    Sale Price
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        selectedPackage.our_status === "Active"
                          ? "#2D256C"
                          : "rgba(181, 181, 181, 1)!important",
                    }}
                  >
                    {selectedPackage.our_status === "Active"
                      ? Number(
                          selectedPackage.price_after_discount
                        ).toLocaleString()
                      : "N/A"}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <EditModel
        editOpen={editOpen}
        handleEditOpenModel={handleEditOpenModel}
        handleEditCloseModel={handleEditCloseModel}
        selectEditableValue={selectEditableValue}
      />
    </div>
  );
};

const EditModel = React.memo(
  ({ editOpen, handleEditCloseModel, selectEditableValue }) => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "1px solid #000",
      boxShadow: 15,
      borderRadius: 3,
    };
    const token = localStorage.getItem("token");
    const userName = JSON.parse(token)?.username;
    console.log("createdBy", userName);

    const validationSchema = Yup.object({
      price: Yup.number()
        .required("Price is required")
        .test(
          "min-price",
          "Price should not be less than Healthians Price",
          function (value) {
            const { mrp } = this.parent;
            return value >= mrp;
          }
        ),
      price_after_discount: Yup.number()
        .required("Sale Price is required")
        .test(
          "min-sale-price",
          "Sale Price should not be less than Healthians Price",
          function (value) {
            const { mrp } = this.parent;
            return value >= mrp;
          }
        ),
      status: Yup.boolean().required("Status is required"),
      mostPopular: Yup.boolean(),
      categories: Yup.array().of(Yup.string()),
    });
    console.log("selectededitable", selectEditableValue);

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        mrp: selectEditableValue?.price || "",
        price: selectEditableValue?.our_price || "",
        price_after_discount: selectEditableValue?.price_after_discount || "",
        status: selectEditableValue?.our_status === "Active",
        mostPopular: selectEditableValue?.mostPopular === "true",
        categories: selectEditableValue?.categories || [],
      },
      validationSchema,
      onSubmit: async (values) => {
        // Convert the values to the format your API expects
        console.log("submitvalues", values);
        const submissionValues = {
          id: selectEditableValue?.id,
          discount: "",
          price_after_discount: values.price_after_discount.toString(),
          our_price: values.price.toString(),
          display_name: "",
          our_status: values.status ? "Active" : "Inactive",
          mostPopular: values.mostPopular.toString(),
          created_by: userName,
          categories: values.categories,
        };
        console.log("Form Submitted", submissionValues);
        try {
          const res = await UpdateTheHealthiansPackage(submissionValues);
        } catch (error) {
          console.log(error.message);
        } finally {
          handleEditCloseModel(); // Close modal after submission
        }
      },
    });

    return (
      <div>
        <Modal
          open={editOpen}
          onClose={() => {
            handleEditCloseModel();
            formik.resetForm();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit} className="editModalForm">
              <div className="titleDiv">
                <Typography variant="h5">
                  {selectEditableValue?.name}
                </Typography>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Button
                    variant="outlined"
                    id="viewbtn"
                    sx={{
                      backgroundColor: "#D83F87",
                      textAlign: "left",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "10px",
                      display: "flex",
                      justifyContent: "space-around",
                      "&:hover": {
                        backgroundColor: "#D83F87!important",
                      },
                    }}
                    size="small"
                    type="submit" // Changed to type="submit"
                  >
                    <AutorenewIcon sx={{ fontSize: "14px" }} /> Update
                  </Button>
                  <Button
                    variant="outlined"
                    type="button" // Added type="button"
                    onClick={() => {
                      handleEditCloseModel();
                      formik.resetForm();
                    }}
                    sx={{
                      backgroundColor: "rgba(105, 105, 105, 1)",
                      textAlign: "left",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "10px",
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "5px",
                      "&:hover": {
                        backgroundColor: "rgba(105, 105, 105, 1)!important",
                      },
                    }}
                    size="small"
                  >
                    <CancelPresentationIcon sx={{ fontSize: "14px" }} /> Cancel
                  </Button>
                </div>
              </div>

              {/* Rest of your form fields */}
              <Typography
                variant="h4"
                sx={{
                  color: "rgba(0, 0, 0, 1)!important",
                }}
              >
                Speciality
              </Typography>
              <div className="Categories">
                {[
                  "Dental",
                  "ENT",
                  "Gastroenterology",
                  "General",
                  "Gynecology",
                  "Ophthalmology",
                ].map((el, id) => (
                  <div key={id + 1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={el}
                          checked={formik.values.categories.includes(el)}
                          onChange={formik.handleChange}
                          name="categories"
                        />
                      }
                      label={el}
                    />
                  </div>
                ))}
              </div>
              <div>
                <Typography
                  variant="h4"
                  sx={{
                    color: "rgba(0, 0, 0, 1)!important",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  Prices
                </Typography>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="healthianPrice">
                    <Typography variant="h6">Healthians Price</Typography>
                    <FormControl
                      sx={{ m: 1, background: "rgba(245, 243, 247, 1)" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        name="mrp"
                        value={formik.values.mrp}
                        startAdornment={
                          <InputAdornment position="start">₹</InputAdornment>
                        }
                        size="small"
                      />
                    </FormControl>
                  </div>
                  <div className="ourPrice">
                    <Typography variant="h6">Our Price</Typography>
                    <FormControl sx={{ m: 1 }} variant="outlined">
                      <OutlinedInput
                        name="price"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.price && Boolean(formik.errors.price)
                        }
                        startAdornment={
                          <InputAdornment position="start">₹</InputAdornment>
                        }
                        size="small"
                      />
                      {formik.touched.price && formik.errors.price && (
                        <div className="error">{formik.errors.price}</div>
                      )}
                    </FormControl>
                  </div>

                  <div className="salePrice">
                    <Typography variant="h5">Sale Price</Typography>
                    <FormControl sx={{ m: 1 }} variant="outlined">
                      <OutlinedInput
                        name="price_after_discount"
                        value={formik.values.price_after_discount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.price_after_discount &&
                          Boolean(formik.errors.price_after_discount)
                        }
                        startAdornment={
                          <InputAdornment position="start">₹</InputAdornment>
                        }
                        size="small"
                      />
                      {formik.touched.price_after_discount &&
                        formik.errors.price_after_discount && (
                          <div className="error">
                            {formik.errors.price_after_discount}
                          </div>
                        )}
                    </FormControl>
                  </div>
                </div>
              </div>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.status}
                      onChange={(e) =>
                        formik.setFieldValue("status", e.target.checked)
                      }
                      name="status"
                    />
                  }
                  label="Active"
                  labelPlacement="start"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.mostPopular}
                      onChange={(e) =>
                        formik.setFieldValue("mostPopular", e.target.checked)
                      }
                      name="mostPopular"
                    />
                  }
                  label="Most Popular"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontWeight: 600,
                    },
                  }}
                />
              </Box>
            </form>
          </Box>
        </Modal>
      </div>
    );
  }
);
