import {
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FreezeSlot,
  getHealthianOrderDetails,
  PostSlotsByLocation,
  RescheduleBooking,
} from "../../../services/HealthiansApi";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as yup from "yup";
import { Permissions } from "../../Context/PermissionsContext";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const RescheduleDateAndTimeSlot = () => {
  const { id } = useParams();
  const [healthianOrder, setHealthianOrder] = useState({});
  const [availableSlots, setAvailableSlots] = useState([]);
  const [freezeSlot, setFreezeSlot] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token?.uniqueId;
  const { healthiansToken } = useContext(Permissions);

  const schema = yup
    .object({
      selectedDate: yup.string().required("Date is required"),
      selectedSlot: yup.string().required("Slot is required"),
      rescheduleReason: yup.string().required("Reschedule Reason is required"),
    })
    .required();

  const onSubmit = async (values, actions) => {
    console.log(values);
    const customersArray = healthianOrder?.customer.map((cust) => ({
      vendor_customer_id: cust.customer_id,
    }));
    const body = {
      authKey: healthiansToken,
      booking_id: healthianOrder?.booking_id,
      slot: {
        slot_id: values.selectedSlot,
      },
      customers: customersArray,
      reschedule_reason: values.rescheduleReason,
    };
    console.log("body", body);

    try {
      const res = await RescheduleBooking(body);
      if (res.data) {
        resetForm();
        toast.success("Successfully Rescheduled");
        getIndividualHealthiansOrderDetails();
        setFreezeSlot(false);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      selectedDate: null,
      selectedSlot: "",
      rescheduleReason: "",
    },
    validationSchema: schema,
    onSubmit,
  });
  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const hourInt = parseInt(hours, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const hour12 = hourInt % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
  };

  const handleDateChange = (date) => {
    console.log("Selected date:", dayjs(date).format("YYYY-MM-DD"));
    setFieldValue("selectedDate", dayjs(date).format("YYYY-MM-DD"));
  };
  const handleDateAccept = async (date) => {
    const body = {
      authKey: healthiansToken,
      latitude: healthianOrder.latitude,
      longitude: healthianOrder.longitude,
      slot_date: dayjs(date).format("YYYY-MM-DD"),
      zone_id: healthianOrder.zone_id.toString(),
    };
    try {
      const res = await PostSlotsByLocation(body);
      if (res && res.data) {
        const slots = res.data.data;
        if (!slots || slots.length === 0) {
          toast.info("No Slots Available");
          setAvailableSlots([]);
          return;
        }
        const transformedSlots = slots.map((slot) => ({
          ...slot,
          value: `${slot.slot_time} - ${slot.end_time}`,
          displayTime: `${convertTo12HourFormat(
            slot.slot_time
          )} - ${convertTo12HourFormat(slot.end_time)}`,
          stmId: slot.stm_id,
          originalSlot: slot,
          stateId: slot.state_id,
        }));
        setAvailableSlots(transformedSlots);
      }
    } catch (error) {
      console.log(error.message);
      toast.info("Please choose another date for Time Slots");
    }
  };

  const FreezSlotBooking = async () => {
    const body = {
      authKey: healthiansToken,
      slot_id: values.selectedSlot,
      vendor_billing_user_id: uniqueId,
      customer_list: [
        {
          customer_id: uniqueId,
        },
      ],
    };
    try {
      const res = await FreezeSlot(body);
      if (res.data.status) {
        setFreezeSlot(true);
        toast.success("Freeze Slot is Successful");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSlotChange = (event) => {
    const selectedSlotValue = event.target.value;
    setFieldValue("selectedSlot", selectedSlotValue);
  };
  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  const getIndividualHealthiansOrderDetails = async () => {
    try {
      const res = await getHealthianOrderDetails(id);
      setHealthianOrder(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getIndividualHealthiansOrderDetails();
  }, []);
  return (
    <div className="RescheduleDateAndTime">
      <Typography variant="h5">Reschedule Date and Time Slot</Typography>
      <Card className="mainCard">
        <Card className="card1">
          <Box className="nameAndEmailBox">
            <Typography className="heading">Name</Typography>
            <Typography className="content">
              {healthianOrder.billing_cust_name}
            </Typography>
            <Typography className="heading">Email</Typography>
            <Typography className="content">{healthianOrder.email}</Typography>
          </Box>
          <Box className="numberAddressBox">
            <Typography className="heading">Address</Typography>
            <Typography className="content">
              {healthianOrder.address}
            </Typography>
            <Typography className="heading">Number</Typography>
            <Typography className="content">{healthianOrder.mobile}</Typography>
          </Box>
          <Box className="landmarkSublocality">
            <Typography className="heading">Landmark</Typography>
            <Typography className="content">
              {healthianOrder.landmark}
            </Typography>
            <Typography className="heading">Sub Locality</Typography>
            <Typography className="content">
              {healthianOrder.sub_locality}
            </Typography>
          </Box>
        </Card>
        <Card className="card2">
          <Box className="customerDetails">
            <Typography className="title">Selected Customers</Typography>
            <Box>
              {healthianOrder?.customer?.map((el, id) => {
                return (
                  <Card className="body" key={el.customer_id}>
                    <Box>
                      <Typography className="heading">Customer Name</Typography>
                      <Typography className="content">
                        {el.customer_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className="heading">Age</Typography>
                      <Typography className="content">{el.age}</Typography>
                    </Box>

                    <Box>
                      <Typography className="heading">D.O.B</Typography>
                      <Typography className="content">{el.dob}</Typography>
                    </Box>
                    <Box>
                      <Typography className="heading">Relation</Typography>
                      <Typography className="content">{el.relation}</Typography>
                    </Box>
                  </Card>
                );
              })}
            </Box>
          </Box>
          <Box className="packageDetails">
            <Typography className="title">Selected Packages</Typography>
            <Box>
              {/* {healthianOrder?.package?.map((el, id) => {
                return (
                  <Box key={id}>
                    <Box className="pckName">
                      {el.package_names.map((pck, id) => {
                        return <Typography key={id}>{pck}</Typography>;
                      })}
                    </Box>
                    <Box className="dealid">
                      {el.deal_id.map((dealId, id) => {
                        return <Typography key={id}>{dealId}</Typography>;
                      })}
                    </Box>
                  </Box>
                );
              })} */}
              {Array.from(
                new Set(
                  healthianOrder?.package?.flatMap((el) => el.package_names)
                )
              ).map((pck, id) => (
                <Box key={id}>
                  <Typography className="pckName">{pck}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Card>
        <Card className="card3">
          <Box>
            <Typography className="heading">Collection Date</Typography>
            <Typography className="content">{healthianOrder.date}</Typography>
          </Box>
          <Box>
            <Typography className="heading">Price</Typography>
            <Typography className="content">
              {healthianOrder.discounted_price} /-
            </Typography>
          </Box>
          <Box>
            <Typography className="heading">Zipcode</Typography>
            <Typography className="content">
              {healthianOrder.zipcode}
            </Typography>
          </Box>
          <Box>
            <Typography className="heading">ZoneId</Typography>
            <Typography className="content">
              {healthianOrder.zone_id}
            </Typography>
          </Box>
        </Card>
      </Card>
      <Card sx={{ mt: "10px" }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Reschedule the Time and Slot
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={6} md={4}>
              <FormControl fullWidth>
                <FormLabel className="formlabel">Select Date</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    maxDate={
                      new Date(new Date().setDate(new Date().getDate() + 7))
                    }
                    value={values.selectedDate}
                    onChange={handleDateChange}
                    onAccept={handleDateAccept}
                    renderInput={(params) => (
                      <OutlinedInput
                        {...params}
                        size="small"
                        sx={{ backgroundColor: "#FFFFFF" }}
                        placeholder="Select Date"
                        endAdornment={
                          <InputAdornment position="end">
                            <CalendarMonthIcon />
                          </InputAdornment>
                        }
                        error={errors.selectedDate && touched.selectedDate}
                      />
                    )}
                    orientation={"portrait"}
                    onYearChange={onYearChangeHandler}
                    variant="outlined"
                  />
                </LocalizationProvider>
                {touched.selectedDate && errors.selectedDate && (
                  <Typography color="error">{errors.selectedDate}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={6} md={4}>
              <FormControl fullWidth>
                <FormLabel className="formlabel">Select Slot</FormLabel>
                <Select
                  sx={{
                    backgroundColor: "#FFFFFF",
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 200,
                        overflow: "auto",
                      },
                    },
                  }}
                  placeholder="Select"
                  size="small"
                  className="inputbox"
                  value={values.selectedSlot}
                  onChange={handleSlotChange}
                  error={errors.selectedSlot && touched.selectedSlot}
                  disabled={availableSlots.length === 0}
                >
                  {availableSlots.map((slot, id) => (
                    <MenuItem key={id} value={slot.stm_id}>
                      {slot.displayTime}
                    </MenuItem>
                  ))}
                </Select>
                {touched.selectedSlot && errors.selectedSlot && (
                  <Typography color="error">{errors.selectedSlot}</Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item sm={12} xs={12} md={4}>
              <FormControl fullWidth>
                <FormLabel className="formlabel">Reschedule Reason</FormLabel>
                <TextField
                  fullWidth
                  placeholder="Reschedule Reason"
                  size="small"
                  value={values.rescheduleReason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.rescheduleReason && touched.rescheduleReason}
                  helperText={
                    errors.rescheduleReason && touched.rescheduleReason
                      ? errors.rescheduleReason
                      : ""
                  }
                  name="rescheduleReason"
                  id="rescheduleReason"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "10px",
              gap: "10px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#2d256c",
                textAlign: "left",
                color: "#FFFFFF",
                textTransform: "capitalize",
                fontSize: "16px",
                borderRadius: "3px",
                // padding: "5px 10px",
                "&:hover": { backgroundColor: "#2d256c!important" },
              }}
              size="small"
              onClick={FreezSlotBooking}
              fullWidth
            >
              Freeze Slot
            </Button>
            <Button
              sx={{
                backgroundColor: !freezeSlot ? "grey" : "#d83f87",
                textAlign: "left",
                color: "#FFFFFF",
                textTransform: "capitalize",
                fontSize: "16px",
                borderRadius: "3px",
                // padding: "5px 10px",
                "&:hover": { backgroundColor: "#d83f87!important" },
              }}
              size="small"
              type="submit"
              fullWidth
              disabled={!freezeSlot}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Card>
      {/* ---------------------------customer footer-------------------- */}

      <Box className="customerfooter" sx={{ textAlign: "right" }}>
        <Typography>
          Finding issue on your appointment or any queries
        </Typography>
        <Box>
          <Typography>
            connect with us at
            <a href={`tel:8040091997`}> +91 8040091997</a> |
            <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
            <a href={`mailto:support@tron.health`}> support@tron.health.</a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default RescheduleDateAndTimeSlot;
