import { Box, Button, Card, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddSpeciality from "./AddSpeciality";
import "../../styles/PlansProcedure.scss";
import ProcedureModal from "./ProcedureModal";
import ComplaintsModal from "./ComplaintsModal";
import {
  EditClinicComplaint,
  EditClinicProcedure,
  getClinicComplaints,
  getClinicProcedure,
} from "../../services/PlansAndProcedure";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { toast } from "react-toastify";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import Loader from "../Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const Procedures = () => {
  const { speciality } = useParams();
  console.log(speciality);
  const [complaints, setComplaints] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [complaintAdded, setComplaintAdded] = useState(false);
  const [editIcon, setEditIcon] = useState(false);
  const [procedureAdded, setProcedureAdded] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [particularProcedure, setParticularProcedure] = useState({});
  const [particularComplaint, setParticularComplaint] = useState({});
  const [editedComplaint, setEditedComplaint] = useState("");
  const [editedProcedure, setEditedProcedure] = useState("");
  const { permissionsData, loading, setLoading } = useContext(Permissions);
  let procedurePermissionsModule = permissionsData?.filter(
    (item) => item.module === "procedure"
  );
  let procedurePermissions = procedurePermissionsModule[0]?.permissions;
  let AddSpecialitiesPermission = procedurePermissions?.find(
    (item) => item.action === "Add Speciality"
  )?.permission;
  let AddProcedurePermission = procedurePermissions?.find(
    (item) => item.action === "Add Procedure"
  )?.permission;
  let AddComplaintsPermission = procedurePermissions?.find(
    (item) => item.action === "Add Complaint"
  )?.permission;

  let EditPermission = procedurePermissions?.find(
    (item) => item.action === "Edit"
  )?.permission;
  // let EditPermission=false
  console.log("procedurepermissions", procedurePermissions);

  const handleEdit = ({ type, item }) => {
    setEditModalOpen(true);
    setIsSubmitting(false);
    if (type === "procedure") {
      console.log("procedurename", item);
      setParticularComplaint("");
      setParticularProcedure(item);
    }
    if (type === "complaint") {
      setParticularProcedure("");
      console.log("Complaint Name", item);
      setParticularComplaint(item);
    }
  };
  // const handleEditProcedure = (item) => {
  //   setEditModalOpen(true)
  //   console.log("procedurename",item);
  //   setParticularComplaint("")
  //   setParticularProcedure(item)
  // };
  // const handleEditComplaints=(item)=>{
  //   setEditModalOpen(true)
  //   setParticularProcedure("")
  //   console.log("Complaint Name",item)
  //   setParticularComplaint(item)
  // }
  const handleClose = () => {
    setEditModalOpen(false);
  };

  const getComplaintsAndProcedure = async () => {
    try {
      setLoading(true);
      let response1 = await getClinicComplaints(speciality);
      let response2 = await getClinicProcedure(speciality);
      let complaintData = response1.data.sort((a, b) =>
        a.complaint.localeCompare(b.complaint)
      );
      let procedureData = response2.data.sort((a, b) =>
        a.procedureName.localeCompare(b.procedureName)
      );
      setComplaints(complaintData);
      setProcedures(procedureData);
      console.log(complaintData, procedureData);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (particularComplaint) {
      const formdata = {
        id: particularComplaint.id,
        speciality: particularComplaint.speciality,
        complaint: editedComplaint
          ? editedComplaint
          : particularComplaint.complaint,
      };
      try {
        setIsSubmitting(true);
        setComplaintAdded(true);
        let res = await EditClinicComplaint(formdata);
        if (res) {
          toast.success("Complaint Edited Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
          setComplaintAdded(false);
          handleClose();
        }
      } catch (error) {
        console.log(error.message);
        toast.error("Please Check Your Server", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmitting(false);
      }
      console.log(formdata);
    }

    if (particularProcedure) {
      const formdata = {
        id: particularProcedure.id,
        speciality: particularProcedure.speciality,
        procedureName: editedProcedure
          ? editedProcedure
          : particularProcedure.procedureName,
      };
      try {
        setIsSubmitting(true);
        setProcedureAdded(true);
        let res = await EditClinicProcedure(formdata);
        if (res) {
          toast.success("Procedure Edited Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
          setProcedureAdded(false);
          handleClose();
        }
      } catch (error) {
        console.log(error.message);
        toast.error("Please Check Your Server", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmitting(false);
      }
      console.log(formdata);
    }
  };

  useEffect(() => {
    getComplaintsAndProcedure();
  }, [complaintAdded, procedureAdded]);

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" fontSize="17px">
          Procedures & Speciality
        </Typography>
        {AddSpecialitiesPermission && <AddSpeciality />}
      </Box>
      <Card className="contentcard">
        <Card className="bodycard">
          <Box className="heading">
            <Typography variant="h5">{speciality}</Typography>
            <Box sx={{ display: "flex", gap: "10px" }}>
              {EditPermission && (
                <Button
                  sx={{
                    backgroundColor: "#2d256c",
                    textAlign: "center",
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    fontSize: "10px",
                    padding: "5px 10px",
                    borderRadius: "3px",
                    "&:hover": { backgroundColor: "#2d256c!important" },
                  }}
                  size="small"
                  onClick={() => setEditIcon(!editIcon)}
                >
                  Edit
                </Button>
              )}
              {AddComplaintsPermission && (
                <ComplaintsModal
                  speciality={speciality}
                  setComplaintAdded={setComplaintAdded}
                />
              )}
              {AddProcedurePermission && (
                <ProcedureModal
                  speciality={speciality}
                  setProcedureAdded={setProcedureAdded}
                />
              )}
            </Box>
          </Box>
          {loading ? (
            <Loader />
          ) : (
            <Box sx={{ display: "flex", padding: "15px", mt: "10px" }}>
              <Box sx={{ width: "50%" }}>
                <Typography sx={{ fontSize: "14px" }} variant="h5">
                  Procedure
                </Typography>
                {procedures.map((el, id) => {
                  return (
                    <Box sx={{ color: "#444444" }}>
                      <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                        {id + 1}. {el?.procedureName}{" "}
                        {editIcon && (
                          <>
                            <DriveFileRenameOutlineIcon
                              sx={{
                                color: "#2d256c",
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleEdit({ type: "procedure", item: el })
                              }
                            />
                          </>
                        )}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ width: "50%" }}>
                <Typography sx={{ fontSize: "14px" }} variant="h5">
                  Complaints
                </Typography>
                {complaints.map((el, id) => {
                  return (
                    <Box sx={{ color: "#444444" }}>
                      <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                        {id + 1}. {el?.complaint}{" "}
                        {editIcon && (
                          <>
                            <DriveFileRenameOutlineIcon
                              sx={{
                                color: "#2d256c",
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleEdit({ type: "complaint", item: el })
                              }
                            />
                          </>
                        )}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Card>
      </Card>

      {/* ------------------------------------Edit Modal---------------------------------- */}
      <Modal
        open={editModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Typography
              id="modal-modal-title"
              sx={{ color: "#D83F87", textAlign: "center", fontWeight: "bold" }}
              variant="h6"
              component="h2"
            >
              {particularComplaint ? "Edit Complaint" : "Edit Procedure"}
            </Typography>
            <TextField
              size="small"
              fullWidth
              placeholder={
                particularComplaint ? "Edit Complaint" : "Edit Procedure"
              }
              defaultValue={
                particularComplaint
                  ? particularComplaint.complaint
                  : particularProcedure.procedureName
              }
              onChange={(e) => {
                if (particularComplaint) {
                  setEditedComplaint(e.target.value);
                }
                if (particularProcedure) {
                  console.log(e.target.value);
                  setEditedProcedure(e.target.value);
                }
              }}
            />
            <br />
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                mt: "10px",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#696969",
                  textAlign: "center",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  fontSize: "12px",
                  padding: "8px 15px",
                  "&:hover": { backgroundColor: "#696969!important" },
                }}
                size="small"
                fullWidth
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting}
                sx={{
                  backgroundColor: isSubmitting ? "#898989" : "#D83F87",
                  textAlign: "center",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  fontSize: "12px",
                  padding: "8px 15px",
                  "&:hover": { backgroundColor: "#D83F87!important" },
                  cursor: isSubmitting ? "default" : "pointer",
                }}
                size="small"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default Procedures;
