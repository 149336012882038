import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import Compressor from "compressorjs";
import React from "react";
import { CssTextField } from "../../styles/textFields";

const ClinicImages = ({ value, onChange , doc,setDoc}) => {
  console.log('doc: ', doc);
  return (
    <>
      <Card>
        <Grid container spacing={2}>
          {value.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} md={6}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  {item.title}
                </Typography>
                <Stack direction="row">
                  <CssTextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Upload Packet Image"
                    type="text"
                    value={item.image}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      borderRadius: "0px 8px 8px 0px",
                      color: "#fff",
                      boxShadow: "none",
                    }}
                    onChange={(e) => {
                      console.log("e: ", e);
                      // const val = [...value];
                      // val[index].image = e.target.value;
                      // onChange(val);

                      const compressFile = (e) => {
                        const file = e.target.files[0];

                        if (!file) {
                          return;
                        }

                        new Compressor(file, {
                          quality: 0.4,

                          // The compression process is asynchronous,
                          // which means you have to access the `result` in the `success` hook function.
                          success(result) {
                            console.log(">>>>>>>", result);
                            var reader = new FileReader();

                            reader.readAsDataURL(result);
                            reader.onloadend = function () {
                              var base64String = reader.result;
                              console.log(
                                "Base64 String - ",
                                JSON.stringify(
                                  base64String.substr(
                                    base64String.indexOf(", ") + 1
                                  )
                                )
                              );

                              // Simply Print the Base64 Encoded String,
                              // without additional data: Attributes.
                              //   console.log('Base64 String without Tags- ',
                              //  base64String.substr(base64String.indexOf(', ') + 1));
                              const val = [...value];
                              console.log('val: ', val);
                              val[index].image = base64String;
                              onChange(val);
                              // val[index].image = e.target.value;
                              // const data = [...values];
                              // changeValues(data);
                            };
                          },
                          error(err) {
                            console.log(err.message);
                          },
                        });
                      };
                      compressFile(e)
                    }}
                  >
                    Upload
                    <input
                      type="file"
                      name="picture"
                      accept="image/*,.pdf" 
                      capture="user"
                      hidden
                    />
                  </Button>
                </Stack>
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xx={12} md={6}>
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  Upload Document (Please Upload PDF only)
                </Typography>
          <Stack direction="row">
                  <CssTextField
                    
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Upload Document"
                    type="text"
                    value={doc?.name||doc}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      borderRadius: "0px 8px 8px 0px",
                      color: "#fff",
                      boxShadow: "none",
                    }}
                    onChange={(e) => {
                      console.log('e: ', e);
                      setDoc(e.target.files[0])
                      
                      //   const val = [...value];
                      //   val[index].image = e.target.value;
                      //   onChange(val);
                    }}
                  >
                    Upload
                    <input
                     accept=".pdf" type="file"
                      // capture="user"
                      hidden
                    />
                  </Button>
                </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ClinicImages;
