import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { getAvailableTimeSlots } from "../../services/customerOnboarding";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F3F7",
  border: "2px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
};

const RescheduleModal = ({
  open,
  date,
  modalData,
  handleClose,
  clinicuniqueId,
  setDate,
  onYearChangeHandler,
  setDailybasis,
  dailyBasis,
  modifiedTimings,
  onSubmit,
  handleSubmit,
  register,
  errors,
  entiredate,
  reset,
  watch,
}) => {
  const [availableTime, setAvailableTime] = useState([]);

  const getTimeslots = async () => {
    try {
      console.log("dailyBasis in getTimeSlot", watch("slot"));
      const response = await getAvailableTimeSlots(
        clinicuniqueId,
        modalData.specialty,
        entiredate,
        watch("slot")
      );
      console.log("response......", response.data);
      // console.log(data);
      let data = response.data;
      if (data.length === 0) {
        toast.info(
          "Timings not available Please Choose another Date or Check by changing slot",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
      const modifiedTimings = data.map((timing) => {
        const parts = timing.split(" - ");
        const startTime = parts[0];
        const endTime = parts[1];

        // Converting start time to 12-hour format
        const start = new Date(`2000-01-01T${startTime}`);
        const startHours = start.getHours();
        const startMinutes = start.getMinutes();
        const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
          .toString()
          .padStart(2, "0")}`;

        // Converting end time to 12-hour format
        const end = new Date(`2000-01-01T${endTime}`);
        const endHours = end.getHours();
        const endMinutes = end.getMinutes();
        const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
          .toString()
          .padStart(2, "0")}`;

        // Determine AM/PM
        const amPm =
          startHours < 12 && watch("slot") === "morning" ? "AM" : "PM";

        return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
      });
      console.log("modifiedtimings", modifiedTimings);
      if (data) {
        await setAvailableTime(modifiedTimings);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (watch("slot") && date) {
      getTimeslots(watch("slot"));
    }
  }, [watch("slot"), date]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="desktopReschedulemodal"
      >
        <Box sx={style}>
          <Typography
            sx={{
              color: "#D83F87",
              textAlign: "center",
              width: "95%",
              marginBottom: "20px",
              fontWeight: "600",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Reschedule Appointment
          </Typography>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ width: "95%", display: "flex", gap: "10px" }}>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                    Patient Name
                  </FormLabel>
                  <TextField
                    value={modalData.patientName}
                    {...register("name")}
                    className="inputbox"
                    size="small"
                    placeholder="Name"
                    sx={{ backgroundColor: "#FFFFFF" }}
                  />
                </FormControl>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                    Mobile Number
                  </FormLabel>
                  <TextField
                    value={modalData.patientNumber}
                    {...register("phonenumber")}
                    className="inputbox"
                    size="small"
                    sx={{ backgroundColor: "#FFFFFF" }}
                    placeholder="Mobile Number"
                  />
                </FormControl>
              </Box>
              <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                  Specialty
                </FormLabel>
                <Select
                  {...register("speciality")}
                  sx={{ backgroundColor: "#FFFFFF" }}
                  placeholder="Select"
                  size="small"
                  className="inputbox"
                  defaultValue={modalData.specialty}
                >
                  <MenuItem value={modalData.specialty}>
                    {modalData.specialty}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                  Select Date
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    minDate={new Date()}
                    value={date}
                    onChange={(date) => {
                      setDate(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        {...register("datevalid", {
                          valueAsDate: true,
                        })}
                        sx={{ backgroundColor: "#FFFFFF" }}
                      />
                    )}
                    onYearChange={onYearChangeHandler}
                    orientation={"portrait"}
                  />
                </LocalizationProvider>
                <span style={{ color: "red" }} className="error">
                  {errors.datevalid?.message}
                </span>
              </FormControl>

              <Box sx={{ width: "95%", display: "flex", gap: "10px" }}>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel className="formlabel">Morning/Evening</FormLabel>
                  <Select
                    placeholder="Select"
                    size="small"
                    {...register("slot")}
                    // onChange={(e) => {
                    //   getTimeslots(e.target.value);
                    //   setDailybasis(e.target.value);
                    //   if (e.target.value) {
                    //     errors.slot.message = null;
                    //   }
                    // }}
                    value={watch("slot") || ""}
                    sx={{ backgroundColor: "#FFFFFF" }}
                    className="inputbox"
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="morning">Morning</MenuItem>
                    <MenuItem value="evening">Evening</MenuItem>
                  </Select>
                  {console.log("slot", errors.slot)}
                  <span className="error">{errors.slot?.message}</span>
                </FormControl>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                    Select Time
                  </FormLabel>
                  <Select
                    {...register("time")}
                    sx={{ backgroundColor: "#FFFFFF" }}
                    placeholder="Select"
                    size="small"
                    className="inputbox"
                  >
                    <MenuItem value=""></MenuItem>
                    {availableTime.map((el, id) => {
                      return (
                        <MenuItem key={id} value={el}>
                          {el}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <span style={{ color: "red" }} className="error">
                    {errors.time?.message}
                  </span>
                </FormControl>
              </Box>
              {/* {dailyBasis && availableTime.length === 0 ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  Timings not available Please Choose another Date or Check by
                  changing slot
                </span>
              ) : (
                ""
              )} */}
              <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                  Remarks
                </FormLabel>
                <TextField
                  value={modalData.remark}
                  {...register("remark")}
                  className="inputbox"
                  sx={{ backgroundColor: "#FFFFFF" }}
                  size="small"
                  placeholder="Please write the remarks here"
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={reset}
                  style={{
                    background: "#696969",
                    color: "white",
                    border: "none",
                    padding: "10px 30px 10px 30px",
                    borderRadius: "5px",
                  }}
                  type="reset"
                >
                  Reset
                </button>
                <button
                  style={{
                    background: "#D83F87",
                    color: "white",
                    border: "none",
                    padding: "10px 30px 10px 30px",
                    borderRadius: "5px",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>

      {/* --------------------------------Mobile Reschedule Modal--------------------------- */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mobileReschedulemodal"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reschedule Appointment
          </Typography>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ width: "95%" }}>
                <FormLabel className="formlabel">Patient Name</FormLabel>
                <TextField
                  value={modalData.patientName}
                  {...register("name")}
                  id="inputbox"
                  size="small"
                  placeholder="Name"
                />
              </FormControl>
              <FormControl sx={{ width: "95%" }}>
                <FormLabel className="formlabel">Mobile Number</FormLabel>
                <TextField
                  value={modalData.patientNumber}
                  {...register("phonenumber")}
                  id="inputbox"
                  size="small"
                  placeholder="Mobile Number"
                />
              </FormControl>
              <FormControl sx={{ width: "95%" }}>
                <FormLabel className="formlabel">Specialty</FormLabel>
                <Select
                  {...register("speciality")}
                  placeholder="Select"
                  size="small"
                  className="inputbox"
                  defaultValue={modalData.specialty}
                >
                  <MenuItem value={modalData.specialty}>
                    {modalData.specialty}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "95%" }}>
                <FormLabel className="formlabel">Select Date</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    minDate={new Date()}
                    value={date}
                    onChange={(date) => {
                      setDate(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="inputbox"
                        sx={{ backgroundColor: "#FFFFFF", fontSize: "12px" }}
                        size="small"
                        {...register("datevalid", {
                          valueAsDate: true,
                        })}
                      />
                    )}
                    onYearChange={onYearChangeHandler}
                    orientation={"portrait"}
                  />
                </LocalizationProvider>
                <span style={{ color: "red" }} className="error">
                  {errors.datevalid?.message}
                </span>
              </FormControl>
              <FormControl sx={{ width: "95%" }}>
                <FormLabel className="formlabel">Morning/Evening</FormLabel>
                <Select
                  placeholder="Select"
                  size="small"
                  {...register("slot")}
                  // onChange={(e) => {
                  //   getTimeslots(e.target.value);
                  //   setDailybasis(e.target.value);
                  //   if (e.target.value) {
                  //     errors.slot.message = null;
                  //   }
                  // }}
                  value={watch("slot") || ""}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  sx={{ backgroundColor: "#FFFFFF" }}
                  className="inputbox"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="morning">Morning</MenuItem>
                  <MenuItem value="evening">Evening</MenuItem>
                </Select>
                {console.log("slot", errors.slot)}
                <span className="error">{errors.slot?.message}</span>
              </FormControl>
              <FormControl sx={{ width: "95%" }}>
                <FormLabel className="formlabel">Select Time</FormLabel>
                <Select
                  {...register("time")}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  placeholder="Select"
                  size="small"
                  className="inputbox"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {availableTime.map((el, id) => {
                    return (
                      <MenuItem key={id} value={el}>
                        {el}
                      </MenuItem>
                    );
                  })}
                </Select>
                <span style={{ color: "red" }} className="error">
                  {errors.time?.message}
                </span>
              </FormControl>
              <FormControl sx={{ width: "95%" }}>
                <FormLabel className="formlabel">Remarks</FormLabel>
                <TextField
                  value={modalData.remark}
                  {...register("remark")}
                  id="inputbox"
                  sx={{ backgroundColor: "#FFFFFF" }}
                  size="small"
                  placeholder="Please write the remarks here"
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <button onClick={handleClose} className="cancelbtn">
                  Cancel
                </button>
                <button type="submit">Submit</button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default RescheduleModal;
