import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "../../styles/Myplans.scss";
import { useNavigate } from "react-router-dom";
import { getPlansListForCustomer } from "../../services/PlansAndProcedure";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import Loader from "../Loader";

const MyPlans = () => {
  const [myPlans, setMyPlans] = useState({});
  const navigate = useNavigate();
  const { permissionsData, loading, setLoading } = useContext(Permissions);
  let plansPermissionsModule = permissionsData?.filter(
    (item) => item.module === "plans"
  );
  let planPermissions =
    plansPermissionsModule && plansPermissionsModule[0]?.permissions;
  let PlanHistoryPermission = planPermissions?.find(
    (item) => item.action === "Plan History"
  )?.permission;

  const formatRupeesWithCommas = (number) => {
    if (typeof number === "undefined" || number === null) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const getPlansList = async () => {
      try {
        setLoading(true);
        let res = await getPlansListForCustomer();
        let data = res.data;
        setMyPlans(data);
        console.log(data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    getPlansList();
  }, []);

  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  return (
    <>
      <Card
        sx={{
          px: "20px",
          py: "20px",
          boxShadow: "none",
          border: "0.5px solid #D9D9D9",
        }}
      >
        <Grid container spacing={2}>
          {myPlans.b2bplan &&
            myPlans.b2bplan?.map((el, id) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={8}>
                    <Box
                      sx={{
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "8px 8px 0px 0px",
                      }}
                      key={el.id}
                    >
                      <Box className="plannameheading">
                        <Typography
                          sx={{
                            color: "#D83F87",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                          variant="h6"
                        >
                          B2B Plan
                        </Typography>
                        <Typography>{el.planName}</Typography>
                        {PlanHistoryPermission && (
                          <Button
                            variant="outlined"
                            sx={{
                              backgroundColor: "#D83F87",
                              textAlign: "left",
                              color: "#FFFFFF",
                              textTransform: "capitalize",
                              fontSize: "10px",
                              display: "flex",
                              borderRadius: "3px",
                              justifyContent: "space-around",
                              "&:hover": {
                                backgroundColor: "#D83F87!important",
                              },
                            }}
                            size="small"
                            onClick={() =>
                              navigate(`/user/planhistory/${el.planName}`)
                            }
                          >
                            History
                          </Button>
                        )}
                        {/* <VisibilityIcon className="visibilityicon" /> */}
                      </Box>
                      <div className="plandesktopbody">
                        <Typography variant="caption">
                          {el.description}
                        </Typography>

                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "10px",
                            mt: "10px",
                          }}
                        >
                          Speciality Covered
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                            mt: "10px",
                          }}
                        >
                          <>
                            {el.speciality?.map((item, id) => {
                              return (
                                <Box
                                  sx={{
                                    padding: "8px",
                                    background: "#FFEFF9",
                                    borderRadius: "10",
                                    fontSize: "12px",
                                    color: "grey",
                                  }}
                                >
                                  {item}
                                </Box>
                              );
                            })}
                          </>
                        </Box>

                        <Grid container spacing={1} sx={{ mt: "10px" }}>
                          {[
                            { title: "Coverage", value: el.coverage },
                            { title: "Validity", value: el.validtill },
                            {
                              title: "Maximum Discount",
                              value:
                                el.consultationChargeMaxDiscount &&
                                el.restOfTheBillMaxDiscount
                                  ? parseInt(el.consultationChargeMaxDiscount) +
                                    parseInt(el.restOfTheBillMaxDiscount) +
                                    " ₹"
                                  : el.consultationChargeMaxDiscount
                                  ? el.consultationChargeMaxDiscount + " ₹"
                                  : el.restOfTheBillMaxDiscount
                                  ? el.restOfTheBillMaxDiscount + " ₹"
                                  : el.totalBillMaxDiscount
                                  ? el.totalBillMaxDiscount + " ₹"
                                  : "",
                            },
                            { title: "MOQ", value: el.moq + " ₹" },
                            { title: "Plan Price", value: el.planPrice + " ₹" },
                            {
                              title: "Plan Capping Amount",
                              value: el.planCappingAmount + " ₹",
                            },
                            // {
                            //   title:el?.consultationChargePlanAmtPer ? "Consultation Discount Percentage":el?.restOfTheBillPlanAmtPer ?"Rest Of The Bill Discount Percentage":el?.totalBillPlanAmtPer? "Total Bill Discount Percentage":"",
                            //   value:el?.consultationChargePlanAmtPer?el?.consultationChargePlanAmtPer:el?.restOfTheBillPlanAmtPer?el?.restOfTheBillPlanAmtPer:el?.totalBillPlanAmtPer?el?.totalBillPlanAmtPer:""
                            // },
                            {
                              title: el?.restOfTheBillPlanAmtPer
                                ? "Rest Of The Bill Discount Percentage"
                                : "",
                              value: el?.restOfTheBillPlanAmtPer
                                ? el?.restOfTheBillPlanAmtPer + " %"
                                : "",
                            },
                            {
                              title: el?.consultationChargePlanAmtPer
                                ? "Consultation Discount Percentage"
                                : "",
                              value: el?.consultationChargePlanAmtPer
                                ? el?.consultationChargePlanAmtPer + " %"
                                : "",
                            },
                            {
                              title: el?.totalBillPlanAmtPer
                                ? "Total Bill Discount Percentage"
                                : "",
                              value: el?.totalBillPlanAmtPer
                                ? el?.totalBillPlanAmtPer + " %"
                                : "",
                            },
                            {
                              title: "Fixed Amount",
                              value: el?.consultationChargePlanAmounttVal
                                ? el?.consultationChargePlanAmounttVal + " ₹"
                                : el?.restOfTheBillPlanAmounttVal
                                ? el.restOfTheBillPlanAmounttVal + " ₹"
                                : el?.totalBillPlanAmounttVal
                                ? el?.totalBillPlanAmounttVal + " ₹"
                                : "None",
                            },
                          ]
                            .filter((e) => e.title && e.value)
                            .map((item, i) => (
                              <Grid key={i} item md={6} sm={6}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: "#D83F87",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                >
                                  {" "}
                                  {item.title}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.value}
                                </Typography>
                              </Grid>
                            ))}
                        </Grid>
                      </div>
                      {/* --------------------------------plan mobile body-------------------- */}
                      <div className="planmobilebody">
                        <Grid container spacing={1} sx={{ mt: "10px" }}>
                          {[
                            { title: "Coverage", value: el.coverage },
                            { title: "Validity", value: el.validtill },
                            {
                              title: "Maximum Discount",
                              value:
                                el.consultationChargeMaxDiscount &&
                                el.restOfTheBillMaxDiscount
                                  ? parseInt(el.consultationChargeMaxDiscount) +
                                    parseInt(el.restOfTheBillMaxDiscount) +
                                    " ₹"
                                  : el.consultationChargeMaxDiscount
                                  ? el.consultationChargeMaxDiscount + " ₹"
                                  : el.restOfTheBillMaxDiscount
                                  ? el.restOfTheBillMaxDiscount + " ₹"
                                  : el.totalBillMaxDiscount
                                  ? el.totalBillMaxDiscount + " ₹"
                                  : "",
                            },
                            { title: "MOQ", value: el.moq + " ₹" },
                            { title: "Plan Price", value: el.planPrice + " ₹" },
                            {
                              title: "Plan Capping Amount",
                              value: el.planCappingAmount + " ₹",
                            },
                            // {
                            //   title:el?.consultationChargePlanAmtPer ? "Consultation Discount Percentage":el?.restOfTheBillPlanAmtPer ?"Rest Of The Bill Discount Percentage":el?.totalBillPlanAmtPer? "Total Bill Discount Percentage":"",
                            //   value:el?.consultationChargePlanAmtPer?el?.consultationChargePlanAmtPer:el?.restOfTheBillPlanAmtPer?el?.restOfTheBillPlanAmtPer:el?.totalBillPlanAmtPer?el?.totalBillPlanAmtPer:""
                            // },
                            {
                              title: el?.restOfTheBillPlanAmtPer
                                ? "Rest Of The Bill Discount Percentage"
                                : "",
                              value: el?.restOfTheBillPlanAmtPer
                                ? el?.restOfTheBillPlanAmtPer + " %"
                                : "",
                            },
                            {
                              title: el?.consultationChargePlanAmtPer
                                ? "Consultation Discount Percentage"
                                : "",
                              value: el?.consultationChargePlanAmtPer
                                ? el?.consultationChargePlanAmtPer + " %"
                                : "",
                            },
                            {
                              title: el?.totalBillPlanAmtPer
                                ? "Total Bill Discount Percentage"
                                : "",
                              value: el?.totalBillPlanAmtPer
                                ? el?.totalBillPlanAmtPer + " %"
                                : "",
                            },
                            {
                              title: "Fixed Amount",
                              value: el?.consultationChargePlanAmounttVal
                                ? el?.consultationChargePlanAmounttVal + " ₹"
                                : el?.restOfTheBillPlanAmounttVal
                                ? el.restOfTheBillPlanAmounttVal + " ₹"
                                : el?.totalBillPlanAmounttVal
                                ? el?.totalBillPlanAmounttVal + " ₹"
                                : "None",
                            },
                          ]
                            .filter((e) => e.title && e.value)
                            .map((item, i) => (
                              <Grid key={i} item xs={6} sm={6}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: "#D83F87",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                  className="title"
                                >
                                  {" "}
                                  {item.title}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                  className="value"
                                >
                                  {item.value}
                                </Typography>
                              </Grid>
                            ))}
                        </Grid>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "14px",
                              mt: "10px",
                            }}
                            className="specialitycovered"
                          >
                            Speciality Covered
                          </Typography>

                          <br />
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              flexWrap: "wrap",
                            }}
                          >
                            <>
                              {el.speciality?.map((item, id) => {
                                return (
                                  <Box
                                    sx={{
                                      padding: "8px",
                                      background: "#FFEFF9",
                                      borderRadius: "10",
                                      fontSize: "12px",
                                      color: "grey",
                                    }}
                                  >
                                    {item}
                                  </Box>
                                );
                              })}
                            </>
                          </Box>
                        </Box>
                      </div>
                    </Box>
                  </Grid>
                  {/* ----------------------right grid---------------- */}
                  <Grid item xs={12} sm={12} md={4}>
                    <Box
                      sx={{
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "8px 8px 0px 0px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#D9D9D9",
                          color: "#2D256C",
                          borderRadius: "8px 8px 0px 0px",
                          boxShadow: "none",
                          fontSize: "12px",
                          padding: "1.5%",
                          fontWeight: "bold",
                        }}
                        className="benifits"
                      >
                        Benefits Of Consultation Charges
                      </div>

                      <div style={{ padding: 5 }}>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                            className="discount"
                          >
                            {" "}
                            Discount Value
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                            className="discountvalue"
                          >
                            2000 Or 10% of total consultation charges.
                          </Typography>
                        </Box>
                        <Box sx={{ mt: "10px" }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                            className="maxdis"
                          >
                            Maximum Discount
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                            className="maxdisvalue"
                          >
                            2000{" "}
                          </Typography>
                        </Box>
                      </div>
                    </Box>

                    <Box
                      sx={{
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "8px 8px 0px 0px",
                        mt: "10px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#D9D9D9",
                          color: "#2D256C",
                          borderRadius: "8px 8px 0px 0px",
                          boxShadow: "none",
                          fontSize: "12px",
                          padding: "1.5%",
                          fontWeight: "bold",
                        }}
                        className="benifits"
                      >
                        Benefits On Rest Of The Bill
                      </div>

                      <div style={{ padding: 5 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                          className="discount"
                        >
                          {" "}
                          Discount Value
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                          className="discountvalue"
                        >
                          2000 Or 10% of total consultation charges.
                        </Typography>
                        <Box sx={{ mt: "10px" }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                            className="maxdis"
                          >
                            Maximum Discount
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                            className="maxdisvalue"
                          >
                            2000{" "}
                          </Typography>
                        </Box>
                      </div>
                    </Box>
                  </Grid>
                </>
              );
            })}
        </Grid>

        {/* -------------------------------B2C Plans--------------------------- */}
        <Grid container spacing={2} mt="10px">
          {myPlans.b2cplan &&
            myPlans.b2cplan?.map((el, id) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "8px 8px 0px 0px",
                      }}
                      key={el.id}
                    >
                      <Box className="plannameheading">
                        <Typography
                          sx={{
                            color: "#D83F87",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                          variant="h6"
                        >
                          B2C Plan
                        </Typography>
                        <Typography>{el.planName}</Typography>
                        {/* {PlanHistoryPermission && <Button
                        variant="outlined"
                        sx={{
                          backgroundColor: "#D83F87",
                          textAlign: "left",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "10px",
                          display: "flex",
                          borderRadius:"3px",
                          justifyContent: "space-around",
                          "&:hover": { backgroundColor: "#D83F87!important" },
                        }}
                        size="small"
                        onClick={() =>
                          navigate(`/user/planhistory/${el.planName}`)
                        }
                      >
                        History
                      </Button>} */}
                        {/* <VisibilityIcon className="visibilityicon" /> */}
                      </Box>
                      <div className="plandesktopbody">
                        <Typography variant="caption">
                          {el.description}
                        </Typography>

                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "10px",
                            mt: "10px",
                          }}
                        >
                          Speciality Covered
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                            mt: "10px",
                          }}
                        >
                          <>
                            {el.specialities?.map((item, id) => {
                              return (
                                <Box
                                  sx={{
                                    padding: "8px",
                                    background: "#FFEFF9",
                                    borderRadius: "10",
                                    fontSize: "12px",
                                    color: "grey",
                                  }}
                                >
                                  {item}
                                </Box>
                              );
                            })}
                          </>
                        </Box>

                        <Grid container spacing={1} sx={{ mt: "10px" }}>
                          {[
                            {
                              title: "Treatment Cover",
                              value:
                                formatRupeesWithCommas(el.treatmentCover) +
                                " ₹",
                            },
                            {
                              title: "Plan Display Price",
                              value: el.planDisplayPrice,
                            },
                            // {
                            //   title: "Maximum Discount",
                            //   value:
                            //     el.consultationChargeMaxDiscount &&
                            //     el.restOfTheBillMaxDiscount
                            //       ? (parseInt(el.consultationChargeMaxDiscount) +
                            //         parseInt(el.restOfTheBillMaxDiscount))+" ₹"
                            //       : el.consultationChargeMaxDiscount
                            //       ? el.consultationChargeMaxDiscount+" ₹"
                            //       : el.restOfTheBillMaxDiscount
                            //       ? el.restOfTheBillMaxDiscount+" ₹"
                            //       : el.totalBillMaxDiscount
                            //       ? el.totalBillMaxDiscount+" ₹"
                            //       : "",
                            // },
                            {
                              title: "Plan Discount Price",
                              value:
                                formatRupeesWithCommas(el.planDiscountPrice) +
                                " ₹",
                            },
                            {
                              title: "Plan Display Discount Percent",
                              value: el.discountPercent + " %",
                            },
                            // {
                            //   title: "Plan Capping Amount",
                            //   value: el.planCappingAmount+" ₹",
                            // },
                            // {
                            //   title:el?.consultationChargePlanAmtPer ? "Consultation Discount Percentage":el?.restOfTheBillPlanAmtPer ?"Rest Of The Bill Discount Percentage":el?.totalBillPlanAmtPer? "Total Bill Discount Percentage":"",
                            //   value:el?.consultationChargePlanAmtPer?el?.consultationChargePlanAmtPer:el?.restOfTheBillPlanAmtPer?el?.restOfTheBillPlanAmtPer:el?.totalBillPlanAmtPer?el?.totalBillPlanAmtPer:""
                            // },
                            // {
                            //   title:el?.restOfTheBillPlanAmtPer?"Rest Of The Bill Discount Percentage":"",
                            //   value:el?.restOfTheBillPlanAmtPer?el?.restOfTheBillPlanAmtPer+" %":""
                            // },
                            // {
                            //   title:el?.consultationChargePlanAmtPer ? "Consultation Discount Percentage":"",
                            //   value:el?.consultationChargePlanAmtPer?el?.consultationChargePlanAmtPer+" %":""
                            // },
                            // {
                            //   title:el?.totalBillPlanAmtPer? "Total Bill Discount Percentage":"",
                            //   value:el?.totalBillPlanAmtPer? el?.totalBillPlanAmtPer+" %":""
                            // },
                            // {
                            //   title:"Fixed Amount",
                            //   value:el?.consultationChargePlanAmounttVal?el?.consultationChargePlanAmounttVal+" ₹":el?.restOfTheBillPlanAmounttVal?el.restOfTheBillPlanAmounttVal+" ₹":el?.totalBillPlanAmounttVal?el?.totalBillPlanAmounttVal+" ₹":"None"
                            // }
                          ]
                            .filter((e) => e.title && e.value)
                            .map((item, i) => (
                              <Grid key={i} item md={6} sm={6}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: "#D83F87",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                >
                                  {" "}
                                  {item.title}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.value}
                                </Typography>
                              </Grid>
                            ))}
                        </Grid>
                      </div>
                      {/* --------------------------------plan mobile body-------------------- */}
                      <div className="planmobilebody">
                        <Grid container spacing={1} sx={{ mt: "10px" }}>
                          {[
                            {
                              title: "Treatment Cover",
                              value:
                                formatRupeesWithCommas(el.treatmentCover) +
                                " ₹",
                            },
                            {
                              title: "Plan Display Price",
                              value: formatRupeesWithCommas(
                                el.planDisplayPrice
                              ),
                            },
                            // {
                            //   title: "Maximum Discount",
                            //   value:
                            //     el.consultationChargeMaxDiscount &&
                            //     el.restOfTheBillMaxDiscount
                            //       ? (parseInt(el.consultationChargeMaxDiscount) +
                            //         parseInt(el.restOfTheBillMaxDiscount))+" ₹"
                            //       : el.consultationChargeMaxDiscount
                            //       ? el.consultationChargeMaxDiscount+" ₹"
                            //       : el.restOfTheBillMaxDiscount
                            //       ? el.restOfTheBillMaxDiscount+" ₹"
                            //       : el.totalBillMaxDiscount
                            //       ? el.totalBillMaxDiscount+" ₹"
                            //       : "",
                            // },
                            {
                              title: "Plan Discount Price",
                              value: el.planDiscountPrice + " ₹",
                            },
                            {
                              title: "Plan Display Discount Percent",
                              value: el.discountPercent + " %",
                            },
                            // {
                            //   title: "Plan Capping Amount",
                            //   value: el.planCappingAmount+" ₹",
                            // },
                            // {
                            //   title:el?.consultationChargePlanAmtPer ? "Consultation Discount Percentage":el?.restOfTheBillPlanAmtPer ?"Rest Of The Bill Discount Percentage":el?.totalBillPlanAmtPer? "Total Bill Discount Percentage":"",
                            //   value:el?.consultationChargePlanAmtPer?el?.consultationChargePlanAmtPer:el?.restOfTheBillPlanAmtPer?el?.restOfTheBillPlanAmtPer:el?.totalBillPlanAmtPer?el?.totalBillPlanAmtPer:""
                            // },
                            // {
                            //   title:el?.restOfTheBillPlanAmtPer?"Rest Of The Bill Discount Percentage":"",
                            //   value:el?.restOfTheBillPlanAmtPer?el?.restOfTheBillPlanAmtPer+" %":""
                            // },
                            // {
                            //   title:el?.consultationChargePlanAmtPer ? "Consultation Discount Percentage":"",
                            //   value:el?.consultationChargePlanAmtPer?el?.consultationChargePlanAmtPer+" %":""
                            // },
                            // {
                            //   title:el?.totalBillPlanAmtPer? "Total Bill Discount Percentage":"",
                            //   value:el?.totalBillPlanAmtPer? el?.totalBillPlanAmtPer+" %":""
                            // },
                            // {
                            //   title:"Fixed Amount",
                            //   value:el?.consultationChargePlanAmounttVal?el?.consultationChargePlanAmounttVal+" ₹":el?.restOfTheBillPlanAmounttVal?el.restOfTheBillPlanAmounttVal+" ₹":el?.totalBillPlanAmounttVal?el?.totalBillPlanAmounttVal+" ₹":"None"
                            // }
                          ]
                            .filter((e) => e.title && e.value)
                            .map((item, i) => (
                              <Grid key={i} item xs={6} sm={6}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: "#D83F87",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                  className="title"
                                >
                                  {" "}
                                  {item.title}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                  className="value"
                                >
                                  {item.value}
                                </Typography>
                              </Grid>
                            ))}
                        </Grid>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "14px",
                              mt: "10px",
                            }}
                            className="specialitycovered"
                          >
                            Speciality Covered
                          </Typography>

                          <br />
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              flexWrap: "wrap",
                            }}
                          >
                            <>
                              {el.specialities?.map((item, id) => {
                                return (
                                  <Box
                                    sx={{
                                      padding: "8px",
                                      background: "#FFEFF9",
                                      borderRadius: "10",
                                      fontSize: "12px",
                                      color: "grey",
                                    }}
                                  >
                                    {item}
                                  </Box>
                                );
                              })}
                            </>
                          </Box>
                        </Box>
                      </div>
                    </Box>
                  </Grid>
                </>
              );
            })}
        </Grid>
      </Card>
    </>
  );
};

export default MyPlans;
